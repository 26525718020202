/* eslint-disable camelcase */
import { api, messengerApi } from "@app/api/api";

import { SettingsEndpointsEnum } from "../constants/settings.endpoints";
import {
  PredefinedMessageDef,
  FilterDef,
  PredefinedMessageRequestDef,
} from "../types/predefined-message.types";
import { VisibleGroupDef } from "../types/vehicle-group.types";

const getPredefinedMessages = (filter: FilterDef) => {
  return messengerApi.get(SettingsEndpointsEnum.PREDEFINED_MESSAGES, {
    params: filter,
  });
};

const getPredefinedMessageById = (id: string, directionType: number) => {
  return messengerApi.get(
    `${SettingsEndpointsEnum.PREDEFINED_MESSAGES}/${id}`,
    { params: { directionType } }
  );
};

const addPredefinedMessage = (fields: PredefinedMessageRequestDef) => {
  return messengerApi.post(SettingsEndpointsEnum.PREDEFINED_MESSAGES, fields);
};

const updatePredefinedMessage = (
  id: PredefinedMessageDef["id"],
  fields: PredefinedMessageRequestDef
) => {
  return messengerApi.put(
    `${SettingsEndpointsEnum.PREDEFINED_MESSAGES}/${id}`,
    fields
  );
};

const deletePredefinedMessage = (
  id: PredefinedMessageDef["id"],
  directionType: number
) => {
  return messengerApi.delete(
    `${SettingsEndpointsEnum.PREDEFINED_MESSAGES}/${id}`,
    { params: { directionType } }
  );
};

const getDispatchersVisibleGroups = (id: number | undefined) => {
  return api.get(
    `${SettingsEndpointsEnum.DISPATCHER_VEHICLE_GROUPS}${id}/vehicle-groups`
  );
};

const updateDispatchersVisibleGroups = (
  fields: VisibleGroupDef[],
  userId: number | undefined
) => {
  return api.put(
    `${SettingsEndpointsEnum.DISPATCHER_VEHICLE_GROUPS}${userId}/vehicle-groups?groupIds=${fields}`,
    { params: { groupIds: fields } }
  );
};

export const adminSettingsApi = {
  getPredefinedMessages,
  getPredefinedMessageById,
  addPredefinedMessage,
  updatePredefinedMessage,
  deletePredefinedMessage,
  getDispatchersVisibleGroups,
  updateDispatchersVisibleGroups,
};
