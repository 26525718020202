/* eslint-disable camelcase */
import { memo, useEffect, useState, useRef } from "react";

import { Form, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";

import FormModal from "@app/components/atoms/FormModal/FormModal";
import { ItemModalEnum } from "@app/constants/route.constants";
import useShowModal from "@app/hooks/useShowModal";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { VehicleGroupType } from "../../../../constants/vehicle-group.constants";
import {
  addNewVehicleGroup,
  getVehicleGroupById,
  updateVehicleGroup,
} from "../../../../redux/vehicle-groups-list.slice";
import {
  VehicleGroupDef,
  VehicleGroupRequestDef,
} from "../../../../types/vehicle-group.types";
import { VehicleDef } from "../../../../types/vehicle.types";
import DynamicGroup from "../DynamicGroup/DynamicGroup";
import GroupTypeForm from "../GroupTypeForm/GroupTypeForm";
import StaticGroup from "../StaticGroup/StaticGroup";

interface VehicleGroupModalProps {
  allVehicles: VehicleDef["vehicle_no"][];
  onClose: (action?: ModalCloseActionDef) => void;
}

interface FormValuesDef {
  name: string;
  type: VehicleGroupType;
  departments: string[];
  plan_departments: string[];
  plan_subdepartments: string[];
  responsible: string[];
  vehicles: string[];
}

const VehicleGroupModal = memo(
  ({ allVehicles, onClose }: VehicleGroupModalProps) => {
    const [loading, setLoading] = useState(false);
    const [showGroupTypeForm, setShowGroupTypeForm] = useState(true);
    const [groupName, setGroupName] = useState("");
    const [groupType, setGroupType] = useState("");
    const [vehiclesToAdd, setVehiclesToAdd] = useState<
      VehicleDef["vehicle_no"][]
    >([]);

    const groupTypeRef = useRef<string>();

    const { showModal, action, actionId } = useShowModal();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const editMode = action === ItemModalEnum.EDIT;

    const setFormValue = (group: VehicleGroupDef) => {
      form.setFieldsValue({
        name: group.name,
        type: group.type,
        departments: group.departments.map(dept => dept.code),
        plan_departments: group.plan_departments.map(dept => dept.code),
        plan_subdepartments: group.plan_subdepartments.map(
          subDept => subDept.code
        ),
        responsible: group.responsible.map(res => res.code),
        vehicles: group.vehicles.map(vehicle => vehicle.vehicle_no),
      });
    };

    useEffect(() => {
      !showModal && form.resetFields();
    }, [showModal, form]);

    useEffect(() => {
      if (editMode && actionId) {
        setShowGroupTypeForm(false);
        const getVehicleGroup = async () => {
          setLoading(true);
          const response = await dispatch(getVehicleGroupById(actionId));
          if (getVehicleGroupById.fulfilled.match(response)) {
            const { name, vehicles }: VehicleGroupDef = response.payload;
            const type =
              response.payload.type === 1
                ? VehicleGroupType.DYNAMIC
                : VehicleGroupType.STATIC;
            setLoading(false);
            setGroupName(name);
            setGroupType(type);
            setVehiclesToAdd(vehicles.map(vehicle => vehicle.vehicle_no));
            groupTypeRef.current = type;
            if (type === VehicleGroupType.DYNAMIC) {
              setFormValue(response.payload);
            }
          }
          if (getVehicleGroupById.rejected.match(response)) {
            setLoading(false);
            AlertMessage.error(t("default.errorMessage"));
          }
        };
        getVehicleGroup();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionId, editMode]);

    const resetState = () => {
      setGroupName("");
      setGroupType("");
      setVehiclesToAdd([]);
      setShowGroupTypeForm(true);
      groupTypeRef.current = "";
    };

    const showAlertMessage = (isSuccess: boolean, message: string) => {
      setLoading(false);
      if (isSuccess) {
        AlertMessage.success(message);
        resetState();
        onClose({ triggerRefetch: true });
      } else {
        AlertMessage.error(message ?? t("default.errorMessage"));
      }
    };

    const addVehicleGroup = async (fields: VehicleGroupRequestDef) => {
      setLoading(true);
      const response = await dispatch(addNewVehicleGroup(fields));
      if (addNewVehicleGroup.fulfilled.match(response)) {
        showAlertMessage(true, t("settingsVehicleGroups.addSuccessMessage"));
      }
      if (addNewVehicleGroup.rejected.match(response)) {
        showAlertMessage(false, response.payload as string);
      }
    };

    const editVehicleGroup = async (
      id: VehicleGroupDef["id"],
      fields: VehicleGroupRequestDef
    ) => {
      setLoading(true);
      const response = await dispatch(updateVehicleGroup({ id, fields }));
      if (updateVehicleGroup.fulfilled.match(response)) {
        showAlertMessage(true, t("settingsVehicleGroups.editSuccessMessage"));
      }
      if (updateVehicleGroup.rejected.match(response)) {
        showAlertMessage(false, response.payload as string);
      }
    };

    const handleVehicleSelection = (
      selectedVehicles: VehicleDef["vehicle_no"][]
    ) => {
      setVehiclesToAdd(selectedVehicles);
    };

    const handleSubmit = (values: FormValuesDef) => {
      setShowGroupTypeForm(false);
      const { name, type } = values;
      if (name && type) {
        setGroupName(name);
        setGroupType(type);
        groupTypeRef.current = type;
      }
      if (
        (groupTypeRef.current === VehicleGroupType.DYNAMIC &&
          !("vehicles" in values)) ||
        (groupTypeRef.current === VehicleGroupType.STATIC &&
          vehiclesToAdd.length === 0)
      ) {
        return;
      }
      const fields: VehicleGroupRequestDef = {
        ...(editMode && actionId && { id: parseInt(actionId, 10) }),
        name: groupName,
        type: groupType === VehicleGroupType.STATIC ? 0 : 1,
        ...((groupType === VehicleGroupType.STATIC && {
          vehicles: vehiclesToAdd.map((vehicle_no: string) => {
            return { vehicle_no };
          }),
        }) || {
          ...values,
          type: values.type === VehicleGroupType.STATIC ? 0 : 1,
          vehicles: values.vehicles?.map((vehicle_no: string) => {
            return { vehicle_no };
          }),
          departments: values.departments?.map((department: string) => {
            return { code: department };
          }),
          responsible: values.responsible?.map((responsible: string) => {
            return { code: responsible };
          }),
          plan_subdepartments: values.plan_subdepartments?.map(
            (subDepartment: string) => {
              return { code: subDepartment };
            }
          ),
          plan_departments: values.plan_departments?.map(
            (department: string) => {
              return { code: department };
            }
          ),
        }),
      };
      if (editMode && actionId) editVehicleGroup(actionId, fields);
      else addVehicleGroup(fields);
    };

    const handleClose = () => {
      resetState();
      if (editMode) {
        onClose();
      } else if (showGroupTypeForm) {
        onClose();
      }
    };

    const validateMessages = {
      required: t("default.requiredMessage"),
      string: {
        max: t("default.maxLengthMessage", {
          max: 50,
        }),
      },
    };

    return (
      <FormModal
        form={form}
        visible={showModal}
        width={680}
        destroyOnClose
        loadingSubmit={loading}
        loadingContent={loading}
        title={
          !editMode && showGroupTypeForm
            ? t("settingsVehicleGroups.addGroupTitle")
            : t("settingsVehicleGroups.vehicleGroupModalTitle", {
                name: groupName,
              })
        }
        submitButtonText={
          (editMode && t("default.editTitle")) ||
          (showGroupTypeForm && t("default.continueTitle")) ||
          t("default.addTitle")
        }
        disableSubmit={
          (editMode &&
            ((groupType === VehicleGroupType.STATIC &&
              vehiclesToAdd.length === 0) ||
              false)) ||
          (groupType === VehicleGroupType.STATIC &&
            !showGroupTypeForm &&
            vehiclesToAdd.length === 0) ||
          false
        }
        validateMessages={validateMessages}
        onClose={handleClose}
        onFinish={handleSubmit}
      >
        {showGroupTypeForm && <GroupTypeForm />}
        {groupType === VehicleGroupType.STATIC && (
          <StaticGroup
            vehicles={allVehicles}
            editMode={editMode}
            groupName={groupName}
            groupVehicles={vehiclesToAdd}
            onSelection={handleVehicleSelection}
          />
        )}
        {groupType === VehicleGroupType.DYNAMIC && (
          <DynamicGroup vehicles={allVehicles} />
        )}
      </FormModal>
    );
  }
);

export default VehicleGroupModal;
