import { combineReducers } from "@reduxjs/toolkit";

import { authReducer } from "@app/features/auth/auth";
import {
  messagesReducer,
  messageThreadReducer,
  vehicleGroupsReducer,
  vehiclesPositionsReducer,
  selectedVehiclesReducer,
  mapSettingsOptionsReducer,
  messageStoreReducer,
} from "@app/features/fleet-manager/fleet-manager";
import {
  AuditLogReportReducer,
  sessionReportReducer,
  messageReportReducer,
  chassisCheckReportReducer,
  positionReportReducer,
  positionMapSettingsReducer,
} from "@app/features/reporting/reporting";
import {
  vehiclesReducer,
  vehicleGroupsListReducer,
  dispatcherConfigReducer,
  predefinedMessagesReducer,
  chassisReducer,
  driversReducer,
  usersReducer,
} from "@app/features/settings/settings";

const rootReducer = combineReducers({
  auth: authReducer,
  messages: messagesReducer,
  conversations: messageThreadReducer,
  messageStore: messageStoreReducer,
  vehicleGroups: vehicleGroupsReducer,
  vehiclesPositions: vehiclesPositionsReducer,
  vehicles: vehiclesReducer,
  selectedVehicles: selectedVehiclesReducer,
  vehicleGroupsList: vehicleGroupsListReducer,
  mapSettingsOptions: mapSettingsOptionsReducer,
  dispatcherConfig: dispatcherConfigReducer,
  predefinedMessages: predefinedMessagesReducer,
  chassis: chassisReducer,
  sessionReport: sessionReportReducer,
  auditLogReport: AuditLogReportReducer,
  drivers: driversReducer,
  messageReport: messageReportReducer,
  users: usersReducer,
  chassisCheckReport: chassisCheckReportReducer,
  positionReport: positionReportReducer,
  positionMapSettingsOptions: positionMapSettingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
