/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo } from "react";

import { GeoLocationDef } from "../../../../types/vehicle-position.types";
import styles from "./Cluster.module.scss";

// The types are unknown because of the third party library
interface ClusterProps {
  points: unknown[];
  pointCount: number;
  clusterId: unknown;
  geoLocations: GeoLocationDef;
  handleClusterClick: (
    clusterId: unknown,
    geoLocations: GeoLocationDef
  ) => void;
}

const exponent = 20;
const clusterBaseSize = 35;

const Cluster = memo(
  ({
    points,
    clusterId,
    pointCount,
    geoLocations,
    handleClusterClick,
  }: ClusterProps) => {
    const clusterSize =
      clusterBaseSize + (pointCount / points.length) * exponent;

    const internalHandleClusterClick = () => {
      handleClusterClick(clusterId, geoLocations);
    };

    return (
      <div
        style={{
          width: `${clusterSize}px`,
          height: `${clusterSize}px`,
          transform: `translate(-50%, -${clusterSize / 2}px)`,
        }}
        onClick={internalHandleClusterClick}
        className={styles.cluster}
      >
        <legend>{pointCount}</legend>
      </div>
    );
  }
);

export default Cluster;
