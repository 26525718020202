/* eslint-disable @typescript-eslint/ban-types */
import { ReactNode } from "react";

import { DownOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Table, Space, Popconfirm, Divider, Menu, Dropdown } from "antd";
import { TablePaginationConfig, TableProps } from "antd/lib/table";
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";
import { getOrderBy } from "@app/helpers/table.helper";
import { scrollToTop } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";

import styles from "./TableView.module.scss";

const { Column } = Table;

export type ActionMenuDef = { key: string; label: string }[];

export interface TableViewProps<T = {}> extends Omit<TableProps<T>, "columns"> {
  displayActionColumn?: boolean;
  actionTitle?: string;
  onEdit?: (record: T) => void;
  onView?: (record: T) => void;
  onDelete?: (record: T) => void;
  onPdf?: (record: T) => void;
  actionWidth?: number | string;
  extraActions?: ReactNode;
  actionMenu?: ActionMenuDef;
  loadingPdf?: boolean;
  onActionMenu?: (key: string, record: T) => void;
}

const TableView = <T extends {}>({
  displayActionColumn = true,
  actionTitle,
  onPdf,
  onEdit,
  onView,
  onDelete,
  children,
  onChange,
  actionWidth = 150,
  actionMenu,
  onActionMenu,
  extraActions,
  ...tableProps
}: TableViewProps<T>) => {
  const { t } = useTranslation();
  const { updateSearchParams } = useSearchParams();

  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => {
    const orderBy = Array.isArray(sorter)
      ? undefined
      : (sorter.order &&
          sorter.columnKey &&
          getOrderBy(sorter.columnKey.toString(), sorter.order)) ||
        undefined;

    const currentPagination = {
      page: pagination.current,
      pageSize: pagination.pageSize,
    };

    updateSearchParams({
      orderBy,
      ...currentPagination,
    });

    // Scroll to top when there are changes to pagination, sorting, or filters
    scrollToTop();

    onChange?.(pagination, filters, sorter, extra);
  };

  const getMenu = (record: T) => (
    <Menu onClick={e => onActionMenu?.(e.key.toString(), record)}>
      {actionMenu?.map(({ key, label }) => (
        <Menu.Item key={key}>{label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Table
      rowKey="id"
      onChange={handleOnChange}
      {...tableProps}
      className={cx(styles.tableViewContainer, tableProps?.className)}
    >
      {children}
      {displayActionColumn && (
        <Column<T>
          key="action"
          title={actionTitle}
          fixed="right"
          width={actionWidth}
          className={styles.actions}
          render={(_, record) => (
            <Space size="small" split={<Divider type="vertical" />}>
              {!!onEdit && (
                <Button
                  onClick={() => onEdit(record)}
                  type="link"
                  noPadding
                  size="small"
                >
                  {t("default.editTitle")}
                </Button>
              )}
              {!!onView && (
                <Button
                  onClick={() => onView(record)}
                  type="link"
                  noPadding
                  size="small"
                >
                  {t("default.viewTitle")}
                </Button>
              )}
              {!!onPdf && <FilePdfOutlined onClick={() => onPdf(record)} />}
              {!!onDelete && (
                <Popconfirm
                  title={t("default.confirmDeleteTitle")}
                  okText={t("default.confirmDeleteYes")}
                  cancelText={t("default.confirmDeleteNo")}
                  onConfirm={() => onDelete(record)}
                  placement="left"
                >
                  <Button type="link" noPadding size="small">
                    {t("default.deleteTitle")}
                  </Button>
                </Popconfirm>
              )}
              {!!extraActions && extraActions}
              {actionMenu && (
                <Dropdown
                  key="more"
                  overlay={getMenu(record)}
                  trigger={["click"]}
                >
                  <Button type="link" noPadding size="small">
                    {t("default.statusTitle")} <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </Space>
          )}
        />
      )}
    </Table>
  );
};

export default TableView;
