import { Switch, Route } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";

import { FLEET_MANAGER_ROUTES } from "./fleet-manager.routes";

const FleetManagerRoutes = () => {
  const routesWithComponents = flatten(FLEET_MANAGER_ROUTES);

  return (
    <Switch>
      {routesWithComponents.map(route => (
        <Route
          exact
          key={route.id}
          path={route.path}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default FleetManagerRoutes;
