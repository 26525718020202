import { memo, useEffect, useState } from "react";

import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount } from "react-use";

import { RootState } from "@app/redux/root-reducer";

import { filterVehicleGroupsByVehicleName } from "../../../../helpers/fleet-manager.helpers";
import { getVehicleGroupsWithVehicles } from "../../../../redux/vehicle-groups.slice";
import { SimplifiedVehicleGroupDef } from "../../../../types/vehicle-group.types";
import VehicleList from "../VehicleList/VehicleList";
import styles from "./OutgoingMessages.module.scss";

const { Search } = Input;

const OutgoingMessages = memo(() => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<
    SimplifiedVehicleGroupDef[]
  >([]);

  const vehicleGroups = useSelector(
    (state: RootState) => state.vehicleGroups.vehicleGroups
  );
  const loadingVehicleGroups = useSelector(
    (state: RootState) => state.vehicleGroups.loadingVehicleGroups
  );
  const userId = useSelector((state: RootState) => state.auth.user?.id);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useMount(() => {
    const filters: { page?: number; pageSize?: number } = {
      page: 1,
      pageSize: 1000,
    };
    dispatch(getVehicleGroupsWithVehicles({ userId, filters }));
  });

  useEffect(() => {
    setFilteredVehicleGroups(vehicleGroups.data);
  }, [vehicleGroups.data]);

  const handleSearch = (value: string) => {
    const filteredGroups: SimplifiedVehicleGroupDef[] = filterVehicleGroupsByVehicleName(
      vehicleGroups.data,
      value.trim()
    );
    setFilteredVehicleGroups(filteredGroups);
  };

  return (
    <>
      <div className={styles.searchVehicle}>
        <Search
          placeholder={t("fleetManagerMessages.searchVehiclePlaceholder")}
          size="large"
          allowClear
          value={searchValue}
          style={{ width: 320 }}
          onChange={e => setSearchValue(e.target.value.trim())}
          onSearch={handleSearch}
        />
      </div>
      <VehicleList
        vehicleGroups={filteredVehicleGroups}
        isLoading={loadingVehicleGroups}
      />
    </>
  );
});

export default OutgoingMessages;
