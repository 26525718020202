import { memo, useState, useRef, useEffect } from "react";

import { SendOutlined } from "@ant-design/icons";
import { Form, Input, Row, Col, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDebounce, useLocation, useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import { FleetManagerPathsEnum } from "@app/features/fleet-manager/fleet-manager";
import { getFileGrantPermissions } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import {
  requestUploadGrant,
  uploadFile,
} from "../../../../../file-upload/redux/file-upload.slice";
import { FileTypeDef } from "../../../../../file-upload/types/file-upload.types";
import {
  AUTH_MIDDLEWARE_PATH,
  MESSAGE_STORE_WAITING_TIME,
  PredefinedMessagePagination,
} from "../../../../constants/fleet-manager.constants";
import {
  clearTypedMessage,
  setTypedMessage,
} from "../../../../redux/message-store.slice";
import { setConversationsEmpty } from "../../../../redux/message-thread.slice";
import {
  sendMessageToDriver,
  getPredefinedMessages,
} from "../../../../redux/messages.slice";
import {
  SendMessageDef,
  PredefinedMessageDef,
} from "../../../../types/messages.types";
import ActionButtons from "../ActionButtons/ActionButtons";
import PredefinedMessages from "../PredefinedMessages/PredefinedMessages";
import styles from "./MessageInput.module.scss";

interface MessageInputProps {
  disabled: boolean;
  vehicleNo: string;
  getConversations: (page: number) => void;
}

const MessageInput = memo(
  ({ disabled, vehicleNo, getConversations }: MessageInputProps) => {
    const inputRef = useRef<Input>(null);
    const [showPredefinedMessages, setShowPredefinedMessages] = useState(false);

    const [isUploading, setIsUploading] = useState(false);

    const [inputValue, setInputValue] = useState("");

    const predefinedMessages = useSelector(
      (state: RootState) => state.messages.predefinedMessages
    );
    const loadingPredefinedMessages = useSelector(
      (state: RootState) => state.messages.loadingPredefinedMessages
    );

    const typedMessage = useSelector(
      (state: RootState) => state.messageStore.typedMessage
    );

    const dispatch = useAppDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [,] = useDebounce(
      () => {
        if (inputValue?.length) {
          dispatch(setTypedMessage({ typedMessage: inputValue }));
        }
      },
      MESSAGE_STORE_WAITING_TIME,
      [inputValue]
    );

    const sendMessage = async (message: SendMessageDef) => {
      const response = await dispatch(sendMessageToDriver(message));
      if (sendMessageToDriver.fulfilled.match(response)) {
        dispatch(setConversationsEmpty());
        getConversations(1);
        dispatch(clearTypedMessage());
        message.predefined_message_id
          ? setShowPredefinedMessages(false)
          : form.resetFields();
      }
      if (sendMessageToDriver.rejected.match(response)) {
        AlertMessage.error({
          content: t("fleetManagerMessages.sentMessageFailed"),
        });
      }
    };

    const sendAttachment = (fileUri: string, file: any) => {
      sendMessage({
        vehicle_no: vehicleNo,
        attachments: [
          {
            name: file.name,
            mime_type: file.type,
            size_bytes: file.size,
            file_uri: fileUri,
          },
        ],
      });
    };

    const uploadSelectedFile = async (
      fileUploadUri: string,
      fileUri: string,
      file: FileTypeDef
    ) => {
      const response = await dispatch(
        uploadFile({
          fileUploadUri,
          file,
        })
      );
      if (uploadFile.fulfilled.match(response)) {
        setIsUploading(false);
        sendAttachment(fileUri, file);
        AlertMessage.success(
          t("fleetManagerMessages.fileUploadSuccessMessage")
        );
      }
      if (uploadFile.rejected.match(response)) {
        setIsUploading(false);
        AlertMessage.error(
          (response.payload as string) ??
            t("fleetManagerMessages.fileUploadErrorMessage")
        );
      }
    };

    const getUploadGrant = async (file: FileTypeDef) => {
      setIsUploading(true);

      const response = await dispatch(
        requestUploadGrant({
          name: file.name,
          permissions: getFileGrantPermissions([vehicleNo]),
        })
      );
      if (requestUploadGrant.fulfilled.match(response)) {
        uploadSelectedFile(
          response.payload?.upload_parts[0].uri,
          response.payload?.file_uri,
          file
        );
      }
      if (requestUploadGrant.rejected.match(response)) {
        setIsUploading(false);
        AlertMessage.error(
          (response.payload as string) ??
            t("fleetManagerMessages.fileUploadErrorMessage")
        );
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const beforeUpload = (file: any) => {
      getUploadGrant(file[0]);
      return false; // For manual upload
    };

    useMount(() => {
      dispatch(
        getPredefinedMessages({
          page: 1,
          pageSize: PredefinedMessagePagination.PAGE_SIZE,
          directionType: 0,
        })
      );
    });

    useEffect(() => {
      inputRef.current?.focus();
    });

    /**
     * Clear saved typed message conditionally code will go here
     */
    useEffect(() => {
      return () => {
        const currentRoute = [
          ...window.location.pathname.split(/(?=\/)/g, 2),
        ].join("");
        if (
          currentRoute !== FleetManagerPathsEnum.MESSAGES &&
          currentRoute !== AUTH_MIDDLEWARE_PATH
        ) {
          dispatch(clearTypedMessage());
          form.resetFields();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location]);

    const onSubmit = (formValue: SendMessageDef) => {
      const newMessage = formValue.text_body?.trim();
      if (newMessage) {
        sendMessage({
          vehicle_no: vehicleNo,
          text_body: newMessage,
        });
      }
    };

    const sendPredefinedMessage = (id: PredefinedMessageDef["id"]) => {
      sendMessage({
        vehicle_no: vehicleNo,
        predefined_message_id: id,
      });
    };

    return (
      <>
        {showPredefinedMessages && !disabled && (
          <PredefinedMessages
            messages={predefinedMessages?.data}
            loading={loadingPredefinedMessages}
            onSelectPredefinedMessage={sendPredefinedMessage}
          />
        )}
        <Row className={styles.messageInputContainer}>
          <Col xs={19} md={24} lg={18} xl={19}>
            <Form
              name="message_form"
              form={form}
              layout="inline"
              className={styles.messageForm}
              size="large"
              onFinish={onSubmit}
            >
              <Form.Item
                name="text_body"
                className={styles.messageBody}
                initialValue={typedMessage}
              >
                <Input
                  disabled={disabled}
                  autoComplete="off"
                  ref={inputRef}
                  onChange={e => {
                    setInputValue(e.target.value);
                  }}
                  placeholder={t("fleetManagerMessages.messagePlaceholder")}
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    disabled={disabled}
                    htmlType="submit"
                    className={styles.sendButton}
                    icon={<SendOutlined />}
                  />
                )}
              </Form.Item>
            </Form>
          </Col>
          <Col xs={5} md={24} lg={6} xl={5}>
            <ActionButtons
              page="Message"
              disabled={disabled}
              showBtnTitle={false}
              activatePredefined={showPredefinedMessages && !disabled}
              className={styles.actionSingleMessage}
              vehiclesNo={[vehicleNo]}
              isUploading={isUploading}
              togglePredefined={() =>
                setShowPredefinedMessages(!showPredefinedMessages)
              }
              attachmentUploaded={(fileUri: any, file: any) =>
                sendAttachment(fileUri, file)
              }
              beforeUpload={beforeUpload}
            />
          </Col>
        </Row>
      </>
    );
  }
);

export default MessageInput;
