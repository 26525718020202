/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { Table, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import TableView from "@app/components/molecules/TableView/TableView";
import { DateFormats, DateTimeFormats } from "@app/constants/date.constants";
import { EXPORTED_CONTENT_TYPE } from "@app/constants/file.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import {
  formattedDateTime,
  processBlobToFile,
} from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { settingsApi } from "../../api/settings.api";
import { deleteDriver } from "../../redux/drivers.slice";
import { getUsers } from "../../redux/users.slice";
import { getDepartments } from "../../redux/vehicles.slice";
import { UserDef } from "../../types/user.types";
import DriverModal from "./components/DriverModal/DriverModal";

const DriversScreen = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const users = useSelector((state: RootState) => state.users);
  // const loadingDrivers = useSelector(
  //   (state: RootState) => state.drivers.loadingDrivers
  // );

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { search, updateSearchParams } = useSearchParams();

  useMount(() => {
    dispatch(getDepartments({ pageSize: MAX_PAGE_SIZE }));
  });

  useEffect(() => {
    if (search.searchBy && search.page) {
      dispatch(
        getUsers({
          page:
            search.searchBy && search.page > 1 ? search?.page : DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
          role: "Driver",
        })
      );
    }
    if (search.searchBy && !search.page) {
      dispatch(
        getUsers({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
          role: "Driver",
        })
      );
    }
    if (!search.searchBy && search.page) {
      dispatch(
        getUsers({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
          role: "Driver",
        })
      );
    }

    if (!search.searchBy && !search.page) {
      dispatch(
        getUsers({
          page: DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
          role: "Driver",
        })
      );
    }
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.searchBy,
    triggerRefetch,
  ]);

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (driver: UserDef) => {
    updateSearchParams(modalAction.edit(driver.id.toString()));
  };

  const handleDelete = async (driver: UserDef) => {
    const response = await dispatch(deleteDriver(driver.id));
    if (deleteDriver.fulfilled.match(response)) {
      AlertMessage.success(t("settingsDrivers.deleteSuccessMessage"));
      setTriggerRefetch(!triggerRefetch);
    }
    if (deleteDriver.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  /**
   * Handle export report
   */
  const handleExportDrivers = async () => {
    try {
      setLoadingExport(previousLoading => !previousLoading);
      const fileName = `${t(
        "settingsDrivers.exportDriversFileName"
      )}_${formattedDateTime(
        new Date().toString(),
        DateTimeFormats.FILE_NAME_TIME
      )}`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const blobData: any = await settingsApi.getDriverExportData();
      processBlobToFile(blobData, `${fileName}.csv`, EXPORTED_CONTENT_TYPE.CSV);
    } finally {
      setLoadingExport(previousLoading => !previousLoading);
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef) => {
    updateSearchParams(modalAction.close());
    if (action.triggerRefetch) {
      setTriggerRefetch(!triggerRefetch);
    }
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsDrivers.title")}
        filters={
          <SearchFilters
            placeholder={t("settingsDrivers.searchDriverPlaceholder")}
          />
        }
        actions={
          <>
            <Button
              type="default"
              size="large"
              loading={loadingExport}
              onClick={handleExportDrivers}
            >
              {t("settingsDrivers.buttonExportDrivers")}
            </Button>
            <Button type="primary" size="large" onClick={handleAdd}>
              {t("settingsDrivers.buttonAddDriver")}
            </Button>
          </>
        }
      />
      <TableView
        dataSource={users.drivers.data}
        rowKey="id"
        actionTitle={t("default.columnAction")}
        onEdit={handleEdit}
        onDelete={handleDelete}
        pagination={{ ...users.drivers.pagination }}
      >
        <Table.Column
          key="first_name"
          dataIndex="first_name"
          ellipsis
          title={t("settingsDrivers.columnFirstName")}
        />
        <Table.Column
          key="last_name"
          dataIndex="last_name"
          ellipsis
          title={t("settingsDrivers.columnLastName")}
        />
        <Table.Column
          key="username"
          dataIndex="username"
          title={t("settingsDrivers.columnDriverNo")}
        />
        <Table.Column
          key="department"
          dataIndex={["department", "name"]}
          title={t("settingsDrivers.columnDepartment")}
        />
        <Table.Column
          key="company"
          dataIndex="company"
          ellipsis
          title={t("settingsDrivers.columnHaulier")}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsDrivers.columnLastModified")}
          render={(last_modified: UserDef["last_modified"]) => {
            return (
              last_modified &&
              formattedDateTime(last_modified.time, DateFormats.DAY_MONTH_YEAR)
            );
          }}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsDrivers.columnModifiedBy")}
          render={(last_modified: UserDef["last_modified"]) => {
            return last_modified?.user_name.split("@")[0];
          }}
        />
      </TableView>

      <DriverModal onClose={action => handleCloseModal(action)} />
    </ContentLayout>
  );
};

export default DriversScreen;
