import { useState, useEffect } from "react";

import { Row, Col, Switch, Space, Tabs } from "antd";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import { MAX_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";

import {
  MessageLimitDays,
  MessageTabEnum,
} from "../../constants/fleet-manager.constants";
import { FleetManagerPathsEnum } from "../../constants/fleet-manager.paths";
import { getIncomingMessages } from "../../redux/messages.slice";
import { resetSelectedVehicles } from "../../redux/vehicle-groups.slice";
import styles from "./MessageScreen.module.scss";
import ComposeMessageModal from "./components/ComposeMessageModal/ComposeMessageModal";
import MessageContainer from "./components/MessageContainer/MessageContainer";
import MessageList from "./components/MessageList/MessageList";
import NewMessageModal from "./components/NewMessageModal/NewMessageModal";
import OutgoingMessages from "./components/OutgoingMessages/OutgoingMessages";

const { TabPane } = Tabs;

const MessagesScreen = () => {
  const [showNewMessageModal, setShowNewMessageModal] = useState(false);
  const [unreadMessageOnly, setUnreadMessageOnly] = useState(false);
  const [showComposeMessageModal, setShowComposeMessageModal] = useState(false);
  const [loadingOnUnreadChange, setLoadingOnUnreadChange] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(MessageTabEnum.INCOMING);
  const [tempUnreadMessageCount, setTempUnreadMessageCount] = useState<number>(
    0
  );

  const { messages, loadingMessages } = useSelector(
    (state: RootState) => state.messages
  );
  const unreadMessageCount = useSelector(
    (state: RootState) => state.messages.unreadMessagesCount
  );

  const isServerUnavailable = useSelector(
    (state: RootState) => state.messages.isServerUnavailable
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ id: string; type: MessageTabEnum }>();
  const { updateSearchParams } = useSearchParams();

  useEffect(() => {
    setShowNewMessageModal(
      location.pathname === FleetManagerPathsEnum.NEW_MESSAGE && !params.id
    );
    setShowComposeMessageModal(
      location.pathname === FleetManagerPathsEnum.NEW_MESSAGE_COMPOSE
    );
  }, [location.pathname, params]);

  useEffect(() => {
    const { vehicleNo, readType } = qs.parse(location.search);

    setUnreadMessageOnly(!!readType);
    // Prevent message fetch if server is unavailable
    if (!isServerUnavailable) {
      dispatch(
        getIncomingMessages({
          vehicleNo: vehicleNo?.toString(),
          readType: readType ? "unread" : undefined,
          limitDays: MessageLimitDays.LIMIT_DAYS,
          pageSize: MAX_PAGE_SIZE,
        })
      );
    }

    if (
      tempUnreadMessageCount !== 0 &&
      tempUnreadMessageCount !== unreadMessageCount
    ) {
      setLoadingOnUnreadChange(true);
    } else {
      setLoadingOnUnreadChange(false);
    }

    setTempUnreadMessageCount(unreadMessageCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.search, unreadMessageCount]);

  // TODO: Need to improve this logic
  useEffect(() => {
    if (
      location.pathname === FleetManagerPathsEnum.MESSAGES_INCOMING ||
      params.type === MessageTabEnum.INCOMING
    ) {
      setActiveTab(MessageTabEnum.INCOMING);
    } else if (
      location.pathname === FleetManagerPathsEnum.MESSAGES_OUTGOING ||
      params.type === MessageTabEnum.OUTGOING
    ) {
      setActiveTab(MessageTabEnum.OUTGOING);
    }
  }, [location, params.type]);

  const handleCloseModal = () => {
    dispatch(resetSelectedVehicles());
    history.push(FleetManagerPathsEnum.MESSAGES);
  };

  const filterByUnreadStatus = (checked: boolean) => {
    setUnreadMessageOnly(!!checked);
    updateSearchParams({
      readType: checked || undefined,
    });
  };

  const onChangeTab = (key: string) => {
    if (key === MessageTabEnum.INCOMING) {
      setActiveTab(MessageTabEnum.INCOMING);
      history.push(FleetManagerPathsEnum.MESSAGES_INCOMING);
    } else {
      setActiveTab(MessageTabEnum.OUTGOING);
      history.push(FleetManagerPathsEnum.MESSAGES_OUTGOING);
    }
  };

  return (
    <>
      <Row className={styles.messageRow}>
        <Col xs={24} md={14}>
          <ContentLayout className={styles.messageListContent}>
            <Tabs activeKey={activeTab} onChange={onChangeTab}>
              <TabPane
                tab={t("fleetManagerMessages.incomingMessageTab")}
                key={MessageTabEnum.INCOMING}
              >
                <ScreenTitle
                  title={t("fleetManagerMessages.incomingTitle")}
                  subTitle={t("fleetManagerMessages.incomingSubTitle")}
                  filters={
                    <div className={styles.filterContainer}>
                      <SearchFilters
                        placeholder={t(
                          "fleetManagerMessages.searchVehiclePlaceholder"
                        )}
                        searchBy="vehicleNo"
                        maxWidth={320}
                        className={styles.searchFilter}
                      />
                      <Space align="center">
                        {t("fleetManagerMessages.labelUnread")}
                        <Switch
                          checked={unreadMessageOnly}
                          onChange={filterByUnreadStatus}
                        />
                      </Space>
                    </div>
                  }
                  actions={
                    <Button
                      type="primary"
                      to={FleetManagerPathsEnum.NEW_MESSAGE}
                    >
                      {t("fleetManagerMessages.newMessage")}
                    </Button>
                  }
                />
                {/* Only show loader initially and on search */}
                <MessageList
                  isLoading={loadingMessages && !loadingOnUnreadChange}
                  items={messages}
                />
              </TabPane>
              <TabPane
                tab={t("fleetManagerMessages.outgoingMessageTab")}
                key={MessageTabEnum.OUTGOING}
              >
                <ScreenTitle
                  title={t("fleetManagerMessages.outgoingTitle")}
                  subTitle="Search and select vehicle to send message"
                  className={styles.outGoingScreenTitle}
                />
                <OutgoingMessages />
              </TabPane>
            </Tabs>
          </ContentLayout>
        </Col>
        <Col xs={24} md={10}>
          <ContentLayout>
            <MessageContainer />
          </ContentLayout>
        </Col>
      </Row>
      <NewMessageModal
        visible={showNewMessageModal}
        onCloseModal={handleCloseModal}
      />
      <ComposeMessageModal
        visible={showComposeMessageModal}
        onCloseModal={handleCloseModal}
      />
    </>
  );
};

export default MessagesScreen;
