import { SettingsPathsEnum } from "./constants/settings.paths";

export { SETTINGS_ROUTES } from "./routes/settings.routes";

export { default as vehiclesReducer } from "./redux/vehicles.slice";

export { default as vehicleGroupsListReducer } from "./redux/vehicle-groups-list.slice";

export { default as dispatcherConfigReducer } from "./redux/dispatcher-config.slice";

export { default as predefinedMessagesReducer } from "./redux/predefined-messages.slice";

export { default as chassisReducer } from "./redux/chassis.slice";

export * from "./redux/drivers.slice";
export { default as driversReducer } from "./redux/drivers.slice";

export { default as usersReducer } from "./redux/users.slice";

export { SettingsPathsEnum };

export * from "./types/driver.types";
