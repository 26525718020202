import { memo } from "react";

import { Input, Col, Radio, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { Item } from "@app/components/atoms/FormModal/FormModal";

import { VehicleGroupType } from "../../../../constants/vehicle-group.constants";
import styles from "./GroupTypeForm.module.scss";

const { Text } = Typography;

const GroupTypeForm = memo(() => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={24}>
        <Item
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input
            placeholder={t("settingsVehicleGroups.newGroupNamePlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          name="type"
          className={styles.radioGroup}
          label={t("settingsVehicleGroups.labelRadio")}
          initialValue={VehicleGroupType.STATIC}
        >
          <Radio.Group>
            <Radio className={styles.radio} value={VehicleGroupType.STATIC}>
              {t("settingsVehicleGroups.radioStatic")}
              <Text type="secondary" className={styles.radioDescription}>
                {t("settingsVehicleGroups.staticGroupDescription")}
              </Text>
            </Radio>
            <Radio className={styles.radio} value={VehicleGroupType.DYNAMIC}>
              {t("settingsVehicleGroups.radioDynamic")}
              <Text type="secondary" className={styles.radioDescription}>
                {t("settingsVehicleGroups.dynamicGroupDescription")}
              </Text>
            </Radio>
          </Radio.Group>
        </Item>
      </Col>
    </>
  );
});

export default GroupTypeForm;
