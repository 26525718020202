import { memo } from "react";

import cx from "classnames";
import { format } from "date-fns";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import { DateTimeFormats, TimeFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";

import { MessageTargetEnum } from "../../../../constants/fleet-manager.constants";
import {
  MessageDef,
  PredefinedMessageResDef,
} from "../../../../types/messages.types";
import PreviewAttachment from "../PreviewAttachment/PreviewAttachment";
import styles from "./MessageBubble.module.scss";

interface MessageBubbleProps {
  message: MessageDef;
}
const MessageBubble = memo(({ message }: MessageBubbleProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx({
        [styles.messageFromDriver]:
          message.direction_type === MessageTargetEnum.DRIVER,
        [styles.messageFromDispatcher]:
          message.direction_type === MessageTargetEnum.DISPATCHER,
        [styles.groupMessageFromDispatcher]: message.group_msg,
      })}
    >
      <div className={styles.messageBubble}>
        <div className={styles.info}>
          <div className={styles.time}>
            {formattedDateTime(message.sender_user?.time, TimeFormats.SHORT)}
          </div>
          <div className={styles.status}>
            <span className={styles.sentFrom}>
              From: {message.sender_user ? message.sender_user?.user_name : ""}
            </span>
            {message.read_user
              ? `${t("fleetManagerMessages.readBy")} 
                     ${message.read_user?.user_name}
                     ${
                       !isEqual(
                         format(
                           new Date(message?.sender_user?.time),
                           "yyyy-MM-dd"
                         ),
                         format(
                           new Date(message?.read_user?.time),
                           "yyyy-MM-dd"
                         )
                       )
                         ? formattedDateTime(
                             message.read_user?.time,
                             DateTimeFormats.TIME_MONTH_DAY_YEAR
                           )
                         : formattedDateTime(
                             message.read_user?.time,
                             TimeFormats.SHORT
                           )
                     }`
              : t("fleetManagerMessages.notRead")}
          </div>
        </div>
        <div>
          {message.text_body && (
            <div className={styles.messageBody}> {message.text_body}</div>
          )}
          {!message.text_body &&
            message?.predefined_message_localizations.length > 0 && (
              <div className={styles.messageBody}>
                {
                  message?.predefined_message_localizations?.find(
                    (predefinedMessage: PredefinedMessageResDef) =>
                      predefinedMessage.language_code === "en"
                  )?.localized_text
                }
              </div>
            )}
        </div>

        {message.attachments[0] &&
          message.attachments.map((attachment: any) => (
            <PreviewAttachment
              target={message.direction_type}
              fileUri={attachment.file_uri}
              group={message.group_msg}
            />
          ))}
      </div>
    </div>
  );
});

export default MessageBubble;
