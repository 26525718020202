/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, memo, useEffect, useRef, useState } from "react";

import {
  CloseCircleOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";
import useOutsideClick from "@app/hooks/useOutsideClick";

import styles from "./PlaceSearchBox.module.scss";

interface PlaceSearchBoxProps {
  map: any;
}

const PlaceSearchBox = memo(({ map }: PlaceSearchBoxProps) => {
  const { t } = useTranslation();
  const { google } = window as any;
  const inputRef = useRef<HTMLInputElement>(null);
  const searchPanelRef = useRef<HTMLDivElement>(null);

  const { outsideClicked } = useOutsideClick(searchPanelRef);

  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string>("");

  /**
   * Effects on google maps api loaded
   */
  useEffect(() => {
    if (!google) {
      return;
    }

    const { maps } = google as any;
    const searchBox = new maps.places.SearchBox(inputRef.current);

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      const bounds = new maps.LatLngBounds();
      if (places.length) {
        const place = places[0];
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      }
      map.fitBounds(bounds);
    });
  }, [google, map]);

  /**
   * Handle search box display
   */
  const handleSearchBoxDisplay = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
    setShowSearchBox(prevShowSearchBox => !prevShowSearchBox);
  };

  /**
   * Effects on outside clicked
   */
  useEffect(() => {
    if (outsideClicked) {
      setShowSearchBox(false);
    }
  }, [outsideClicked]);

  /**
   * Handle input clear
   */
  const handleInputClear = () => {
    if (inputRef.current?.value) {
      inputRef.current.value = "";
      inputRef.current.focus();
      setSearchedValue("");
    }
  };

  /**
   * Handle input change
   * @param e
   */
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchedValue(value);
  };

  return (
    <div ref={searchPanelRef} className={styles.placeSearchBoxContainer}>
      <span
        className={cx(styles.inputAffixWrapper, {
          [styles.showSearchBox]: showSearchBox,
          [styles.hideSearchBox]: !showSearchBox,
        })}
      >
        <input
          onChange={handleInputChange}
          placeholder={t("settingsMap.placeSearchPlaceHolder")}
          ref={inputRef}
          type="text"
          className={styles.searchInput}
        />
        <span
          onClick={handleInputClear}
          className={cx(styles.inputSuffix, {
            [styles.visibleClear]: searchedValue,
            [styles.hideClear]: !searchedValue,
          })}
        >
          <CloseCircleOutlined />
        </span>
      </span>
      <Button
        onClick={handleSearchBoxDisplay}
        icon={showSearchBox ? <CloseOutlined /> : <SearchOutlined />}
      />
    </div>
  );
});

export default PlaceSearchBox;
