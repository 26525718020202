/**
 * All permission scopes.
 * The scopes should be aligned with backend
 */
export enum PermissionEnum {
  ENTITY_MANAGEMENT = "entityManagement",
  VEHICLES = "vehicles",
  VEHICLE_GROUPS = "vehicleGroups",
  CHASSIS = "chassis",
  DRIVERS = "drivers",
  USERS = "users",
  PREDEFINED_MESSAGES = "predefinedMessages",
  REPORT_MANAGEMENT = "reportManagement",
  REPORT_SESSION = "reportSession",
  REPORT_MESSAGES = "reportMessages",
}
