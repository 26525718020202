import { useEffect, useState } from "react";

import { FilterOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Table, Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import TableView from "@app/components/molecules/TableView/TableView";
import { DateFormats, TimeFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getPositionReport } from "../../redux/position-report.slice";
import {
  AddressDef,
  StreakDef,
  VehiclePositionDef,
} from "../../types/reporting-position.types";
import { PositionReportFilterDef } from "../../types/reporting.types";
import styles from "./PositionReportScreen.module.scss";
import Duration from "./components/Duration/Duration";
import PositionAddress from "./components/PositionAddress/PositionAddress";
import PositionFilters from "./components/PositionFilters/PositionFilters";
import PositionMap from "./components/PositionMap/PositionMap";

const PositionReportScreen = () => {
  const [showMap, setShowMap] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [
    selectedPosition,
    setSelectedPosition,
  ] = useState<VehiclePositionDef>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    search,
  }: {
    search: PositionReportFilterDef;
  } = useSearchParams();
  const reports = useSelector(
    (state: RootState) => state.positionReport.report
  );
  const loadingReport = useSelector(
    (state: RootState) => state.positionReport.loadingReport
  );

  useEffect(() => {
    if (search?.vehicles || search?.drivers || search?.to || search?.from) {
      setShowMap(true);
      dispatch(
        getPositionReport({
          vehicleNo: search?.vehicles ?? undefined,
          userId: search?.drivers?.toString() ?? undefined,
          from: search?.from ?? undefined,
          to: search?.to ?? undefined,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(search)]);

  /**
   * Handle streak selection of the table
   * @param record
   */
  const handleSelectRow = (record: StreakDef) => {
    setSelectedRowKeys([record.id]);
    if (record.positions?.length) {
      const position = { ...record.positions[0] };
      position.streakId = record.id;
      setSelectedPosition(position);
    }
  };

  /**
   * Scroll to the selected streak of the table
   * @param streakId
   */
  const scrollToSelectedStreak = (streakId: VehiclePositionDef["streakId"]) => {
    const targetElm = document.querySelector(`.streak-${streakId}`);
    targetElm?.scrollIntoView({ behavior: "smooth" });
  };

  /**
   * Select specific streak in the table if any marker is selected in the map
   * @param position
   */
  const handleMarkerSelection = (position: VehiclePositionDef) => {
    setSelectedRowKeys([position.streakId]);
    scrollToSelectedStreak(position.streakId);
  };

  const toggleMap = () => {
    setShowMap(!showMap);
  };

  return (
    <Row gutter={12}>
      <Col xs={24} md={showMap ? 12 : 21}>
        <ContentLayout>
          <ScreenTitle
            title={t("reportingPosition.title")}
            subTitle={t("reportingPosition.subTitle")}
            filters={<PositionFilters />}
          />
          {((search?.vehicles ||
            search?.drivers ||
            search?.to ||
            search?.from) && (
            <TableView
              displayActionColumn={false}
              rowKey="id"
              dataSource={reports.data?.streaks}
              loading={loadingReport}
              pagination={false}
              scroll={
                showMap
                  ? { y: "calc(100vh - 440px)" }
                  : { y: "calc(100vh - 330px)" }
              }
              className={styles.streakTable}
              rowClassName={record => `streak-${record.id} ${styles.streakRow}`}
              rowSelection={{
                type: "radio",
                selectedRowKeys,
                columnWidth: 0,
              }}
              onRow={record => ({
                onClick: () => {
                  handleSelectRow(record);
                },
              })}
            >
              <Table.Column
                title={t("reportingPosition.columnDuration")}
                render={(row: StreakDef) => (
                  <Duration startDate={row.end} endDate={row.start} />
                )}
              />
              <Table.Column
                title={t("reportingPosition.columnTotalDistance")}
                render={(row: StreakDef) => (
                  <>{row.total_distance?.toFixed(2)}</>
                )}
              />
              <Table.Column
                dataIndex="positions"
                title={t("reportingPosition.columnDate")}
                render={(positions: VehiclePositionDef[]) => {
                  const positionCreated =
                    positions?.length && positions[0].created;
                  return (
                    <>
                      {formattedDateTime(
                        positionCreated?.toString(),
                        DateFormats.MONTH_DAY_YEAR
                      )}
                    </>
                  );
                }}
              />
              <Table.Column
                dataIndex="positions"
                title={t("reportingPosition.columnAddress")}
                render={(positions: VehiclePositionDef[]) => {
                  let address: AddressDef | undefined;
                  if (positions?.length) {
                    address = positions[0].activity?.address;
                  }
                  return <PositionAddress address={address} />;
                }}
              />
              <Table.Column
                dataIndex="start"
                title={t("reportingPosition.columnTimeArrival")}
                render={(dateTime: string) => (
                  <>{formattedDateTime(dateTime, TimeFormats.SHORT)}</>
                )}
              />
              <Table.Column
                dataIndex="end"
                title={t("reportingPosition.columnTimeDeparture")}
                render={(dateTime: string) => (
                  <>{formattedDateTime(dateTime, TimeFormats.SHORT)}</>
                )}
              />
            </TableView>
          )) || (
            <div className={styles.emptyResult}>
              <FilterOutlined className={styles.filterIcon} />
              <p className={styles.filterText}>
                {t("reportingPosition.subTitle")}
              </p>
            </div>
          )}
        </ContentLayout>
      </Col>
      <Col xs={24} md={showMap ? 12 : 3}>
        <Button className={styles.btnMapToggle} onClick={toggleMap}>
          {showMap ? (
            <>
              <RightOutlined /> {t("reportingPosition.buttonHide")}
            </>
          ) : (
            <>
              <LeftOutlined /> {t("reportingPosition.buttonShow")}
            </>
          )}
        </Button>
        <ContentLayout>
          <PositionMap
            streaks={reports.data?.streaks ?? []}
            selectedMarkerPosition={selectedPosition}
            onChangeMarkerSelection={handleMarkerSelection}
          />
        </ContentLayout>
      </Col>
    </Row>
  );
};

export default PositionReportScreen;
