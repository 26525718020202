import { memo, useState, useEffect } from "react";

import { Input } from "antd";
import cx from "classnames";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import useSearchParams from "@app/hooks/useSearchParams";

import styles from "./SearchFilter.module.scss";

const { Search } = Input;

type SearchFiltersProps = {
  placeholder?: string;
  searchBy?: string;
  className?: string;
  maxWidth?: number;
};

const SearchFilters = memo(
  ({
    placeholder,
    searchBy = "searchBy",
    className,
    maxWidth,
  }: SearchFiltersProps) => {
    const [searchValue, setSearchValue] = useState("");
    const { updateSearchParams } = useSearchParams();
    const location = useLocation();

    useEffect(() => {
      const queryParams = qs.parse(location.search);
      if (queryParams && Object.keys(queryParams).length !== 0) {
        const value = queryParams[searchBy]?.toString() ?? "";
        setSearchValue(value);
      }
    }, [location, searchBy]);

    const onSearch = (value: string) => {
      const trimmedValue = value.trim();
      !trimmedValue && setSearchValue("");
      updateSearchParams({
        [searchBy]: trimmedValue,
      });
    };

    return (
      <Search
        placeholder={placeholder}
        size="large"
        style={{ maxWidth }}
        allowClear
        className={cx(styles.searchInput, className)}
        value={searchValue}
        onSearch={onSearch}
        onChange={e => setSearchValue(e.target.value)}
      />
    );
  }
);

export default SearchFilters;
