import { UserManagementRoles } from "@app/constants/user.constants";

import { NumberOfAxles } from "../constants/setting.constants";
import { VehicleGroupSelectionDef } from "../types/vehicle-group.types";

export const getAxlesOptions = () => {
  return NumberOfAxles.map(axle => {
    return { id: axle, value: axle };
  });
};

export const getUserTypes = () => {
  return Object.values(UserManagementRoles).map(value => {
    return {
      id: value,
      value,
    };
  });
};

/**
 * Filter list by name
 */
export const filterListByName = (items: string[], value: string) => {
  return items.filter(item => {
    return item.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  });
};

/**
 * Filter group list by name
 */
export const filterGroupListByName = (
  items: VehicleGroupSelectionDef[],
  value: string
) => {
  return items.filter(item => {
    return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  });
};
