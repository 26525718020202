/* eslint-disable camelcase */
import { memo, useState, useEffect } from "react";

import { Row, Col, Spin, Empty } from "antd";
import cx from "classnames";
import { isToday } from "date-fns";
import ResizeObserver from "rc-resize-observer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  useHistory,
  generatePath,
  useParams,
  useLocation,
} from "react-router-dom";
import { VariableSizeList as VirtualizedList } from "react-window";

import { DateTimeFormats, TimeFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";

import { MessageTabEnum } from "../../../../constants/fleet-manager.constants";
import { FleetManagerPathsEnum } from "../../../../constants/fleet-manager.paths";
import { markMessageAsRead } from "../../../../redux/messages.slice";
import {
  MessageDef,
  PredefinedMessageResDef,
} from "../../../../types/messages.types";
import styles from "./MessageList.module.scss";

interface MessageListProps {
  items: MessageDef[];
  isLoading: boolean;
}

type RenderItemDef = {
  data: MessageDef[];
  index: number;
  style: React.CSSProperties;
};

const MessageList = memo(({ items, isLoading }: MessageListProps) => {
  const [selectedRowKey, setSelectedRowKey] = useState<string>();
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(500);
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setTableHeight(window.innerHeight - 375);
  }, []);

  useEffect(() => {
    if (params.id) {
      setSelectedRowKey(params.id);
    } else {
      setSelectedRowKey("");
    }
  }, [params]);

  const selectRow = (record: MessageDef) => {
    if (!record.read_user) {
      dispatch(markMessageAsRead(record.id));
    }
    history.push({
      pathname: generatePath(FleetManagerPathsEnum.MESSAGES_THREAD, {
        type: MessageTabEnum.INCOMING,
        vehicleNo: record.vehicle_no,
        id: record.id,
      }),
      search: `${location.search}`,
    });
  };

  const renderedItem = ({ data, index, style }: RenderItemDef) => {
    const record = data[index];
    if (!record) return null;
    return (
      <Row
        style={style}
        key={record.id}
        onClick={() => selectRow(record)}
        className={cx(styles.tableRow, {
          [styles.unreadMessage]: !record.read_user,
          [styles.selectedRow]: record.id === selectedRowKey,
        })}
      >
        <Col className={styles.tableCol} sm={5} xl={4}>
          <div className={styles.ellipsis}>{record.vehicle_no}</div>
        </Col>
        <Col className={styles.tableCol} sm={13} xl={15}>
          <div className={styles.ellipsis}>
            {record.attachments?.length > 0 && record.attachments[0].file_uri}
            {record.text_body}
            {record.predefined_message_localizations?.length > 0 &&
              record.predefined_message_localizations?.find(
                (predefinedMessage: PredefinedMessageResDef) =>
                  predefinedMessage.language_code === "en"
              )?.localized_text}
          </div>
        </Col>
        <Col className={styles.tableCol} sm={6} xl={5}>
          <div className={cx(styles.received, styles.ellipsis)}>
            {isToday(new Date(record.sender_user?.time))
              ? formattedDateTime(record.sender_user?.time, TimeFormats.SHORT)
              : formattedDateTime(
                  record.sender_user?.time,
                  DateTimeFormats.TIME_DAY_MONTH_YEAR
                )}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <div className={styles.messageTable}>
        <Row>
          <Col className={styles.tableHeaderCol} sm={5} xl={4}>
            {t("fleetManagerMessages.tableHeaderVehicle")}
          </Col>
          <Col className={styles.tableHeaderCol} sm={13} xl={15}>
            {t("fleetManagerMessages.tableHeaderMessage")}
          </Col>
          <Col className={styles.tableHeaderCol} sm={6} xl={5}>
            {t("fleetManagerMessages.tableHeaderReceived")}
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          {items.length > 0 ? (
            <VirtualizedList
              itemCount={items.length}
              itemSize={() => 31}
              itemData={items}
              width={tableWidth}
              height={tableHeight}
            >
              {renderedItem}
            </VirtualizedList>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Spin>
      </div>
    </ResizeObserver>
  );
});

export default MessageList;
