import packageJson from "../../package.json";

export const ENV = {
  VERSION: packageJson.version || "",
  NODE_ENV: process.env.NODE_ENV,
  API_HOST: process.env.REACT_APP_API_HOST ?? "",
  MESSENGER_API_HOST: process.env.REACT_APP_MESSENGER_API_HOST ?? "",
  AUTH_BASE_URL: process.env.REACT_APP_LOGIN_BASE_URL,
  AUTH_CREDENTIALS: {
    RESPONSE_TYPE: process.env.REACT_APP_LOGIN_RESPONSE_TYPE,
    CLIENT_ID: process.env.REACT_APP_LOGIN_CLIENT_ID,
    REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI
      ? process.env.REACT_APP_REDIRECT_URI
      : `${window.location.protocol}//${window.location.host}`,
    SCOPE: process.env.REACT_APP_LOGIN_SCOPE,
    STATE: process.env.REACT_APP_LOGIN_STATE,
  },
  AUTH_LOGOUT_URL: process.env.REACT_APP_LOGOUT_URL,
  DEV_API_HOST: process.env.REACT_APP_DEV_API_HOST ?? "",
  GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  DOCUMENT_API_HOST: process.env.REACT_APP_DOCUMENT_API_HOST ?? "",
  ...(process.env.NODE_ENV === "development" && {
    LOCAL_AUTH_CREDENTIALS: {
      GRANT_TYPE: process.env.REACT_APP_LOCAL_GRANT_TYPE,
      USERNAME: process.env.REACT_APP_LOCAL_USERNAME,
      PASSWORD: process.env.REACT_APP_LOCAL_PASSWORD,
      SCOPE: process.env.REACT_APP_LOCAL_SCOPE,
      REQUESTED_TOKEN_TYPE: process.env.REACT_APP_LOCAL_TOKEN_TYPE,
    },
    LOCAL_AUTH_URL: process.env.REACT_APP_LOCAL_AUTH_URL ?? "",
    LOCAL_BASIC_AUTH: process.env.REACT_APP_LOCAL_BASIC_AUTH,
  }),
};
