import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { settingsApi } from "../api/settings.api";
import {
  ChassisDef,
  ChassisRequestDef,
  ChassisFilterDef,
} from "../types/chassis.types";

type VehiclesState = {
  chassisList: {
    data: ChassisDef[];
    pagination: PaginationDef;
  };
  loadingChassis: boolean;
};

const initialState: VehiclesState = {
  chassisList: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingChassis: false,
};

export const getChassisList = createAsyncThunk(
  "chassis/getChassisList",
  async (filter: ChassisFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getChassisList(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChassisByNo = createAsyncThunk(
  "chassis/getChassisByNo",
  async (chassisNo: ChassisDef["chassis_no"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.getChassisByNo(chassisNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewChassis = createAsyncThunk(
  "chassis/addNewChassis",
  async (fields: ChassisRequestDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.addNewChassis(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateChassis = createAsyncThunk(
  "chassis/updateChassis",
  async (
    data: { chassisNo: ChassisDef["chassis_no"]; fields: ChassisRequestDef },
    { rejectWithValue }
  ) => {
    const { chassisNo, fields } = data;
    try {
      const response = await settingsApi.updateChassis(chassisNo, fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteChassis = createAsyncThunk(
  "chassis/deleteChassis",
  async (chassisNo: ChassisDef["chassis_no"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deleteChassis(chassisNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const chassisSlice = createSlice({
  name: "chassis",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getChassisList.pending, state => {
      state.loadingChassis = true;
    });
    builder.addCase(getChassisList.fulfilled, (state, action) => {
      state.loadingChassis = false;
      state.chassisList.data = action.payload.data || [];
      state.chassisList.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getChassisList.rejected, state => {
      state.loadingChassis = false;
    });
  },
});

export default chassisSlice.reducer;
