import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import {
  PaginationDef,
  PaginationFilterDef,
} from "@app/types/pagination.types";

import { settingsApi } from "../../settings/api/settings.api";
import {
  VehicleGroupDef,
  VehicleGroupsFilterDef,
} from "../../settings/types/vehicle-group.types";
import { reportingApi } from "../api/reporting.api";
import {
  ChassisCheckCategoriesDef,
  ChassisCheckReportDef,
  ChassisCheckReportRequestDef,
  ChassisCheckReportFilterDef,
} from "../types/reporting.types";

type ChassisCheckReportState = {
  report: {
    data: ChassisCheckReportDef[];
    pagination: PaginationDef;
  };
  chassisCheckCategories: ChassisCheckCategoriesDef[];
  vehicleGroupsList: VehicleGroupDef[];
  loadingReport: boolean;
};

const initialState: ChassisCheckReportState = {
  report: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  chassisCheckCategories: [],
  vehicleGroupsList: [],
  loadingReport: false,
};

export const getChassisCheckReport = createAsyncThunk(
  "chassisCheckReport/getChassisCheckReport",
  async (filter: ChassisCheckReportFilterDef, { rejectWithValue }) => {
    try {
      const response = await reportingApi.getChassisCheckReport(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChassisCheckCategories = createAsyncThunk(
  "chassisCheckReport/getChassisCheckCategories",
  async (filter: PaginationFilterDef, { rejectWithValue }) => {
    try {
      const response = await reportingApi.getChassisCheckCategories(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChassisCheckReportById = createAsyncThunk(
  "chassisCheckReport/getChassisCheckReportById",
  async (id: ChassisCheckReportDef["id"], { rejectWithValue }) => {
    try {
      const response = await reportingApi.getChassisCheckReportById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getChassisCheckPdfById = createAsyncThunk(
  "chassisCheckReport/getChassisCheckReportById",
  async (id: ChassisCheckReportDef["id"], { rejectWithValue }) => {
    try {
      const response = await reportingApi.getChassisCheckPdfById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateChassisCheckReport = createAsyncThunk(
  "chassisCheckReport/updateChassisCheckReport",
  async (
    data: {
      id: ChassisCheckReportDef["id"];
      fields: ChassisCheckReportRequestDef;
    },
    { rejectWithValue }
  ) => {
    const { id, fields } = data;
    try {
      const response = await reportingApi.updateChassisCheckReport(id, fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehicleGroups = createAsyncThunk(
  "chassisCheckReport/getVehicleGroups",
  async (filter: VehicleGroupsFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicleGroups(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const chassisCheckReportSlice = createSlice({
  name: "chassisCheckReport",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getChassisCheckReport.pending, state => {
      state.loadingReport = true;
    });
    builder.addCase(getChassisCheckReport.fulfilled, (state, action) => {
      state.loadingReport = false;
      state.report.data = action.payload.data || [];
      state.report.pagination = mapPagination(action.payload?.meta?.pagination);
    });
    builder.addCase(getChassisCheckReport.rejected, state => {
      state.loadingReport = false;
    });
    builder.addCase(getChassisCheckCategories.fulfilled, (state, action) => {
      state.chassisCheckCategories = action.payload.data || [];
    });
    builder.addCase(getVehicleGroups.fulfilled, (state, action) => {
      state.vehicleGroupsList = action.payload.data || [];
    });
  },
});

export default chassisCheckReportSlice.reducer;
