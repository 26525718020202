import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { reportingApi } from "../api/reporting.api";
import {
  MessageReportDef,
  // MessageReportFilterDef,
} from "../types/reporting.types";

type MessageReportState = {
  report: {
    data: MessageReportDef[];
    pagination: PaginationDef;
  };
  loadingReport: boolean;
};

const initialState: MessageReportState = {
  report: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingReport: false,
};

export const getMessageReport = createAsyncThunk(
  "messageReport/getMessageReport",
  async (filter: any, { rejectWithValue }) => {
    try {
      const response = await reportingApi.getMessageReport(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const messageReportSlice = createSlice({
  name: "messageReport",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getMessageReport.pending, state => {
      state.loadingReport = true;
    });
    builder.addCase(getMessageReport.fulfilled, (state, action) => {
      state.loadingReport = false;
      state.report.data = action.payload.data || [];
      state.report.pagination = mapPagination(action.payload?.meta?.pagination);
    });
    builder.addCase(getMessageReport.rejected, state => {
      state.loadingReport = false;
    });
  },
});

export default messageReportSlice.reducer;
