import { memo, useState } from "react";

import { Modal, Input, Form, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
// import { MessageType } from "@app/constants/message.constants";
import { AlertMessageConfig } from "@app/constants/ui.constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { PredefinedMessagePagination } from "../../../../constants/fleet-manager.constants";
import { FleetManagerPathsEnum } from "../../../../fleet-manager";
import {
  getPredefinedMessages,
  sendGroupMessageToDriver,
} from "../../../../redux/messages.slice";
import {
  PredefinedMessageDef,
  SendGroupMessageDef,
} from "../../../../types/messages.types";
import ActionButtons from "../ActionButtons/ActionButtons";
import PredefinedMessages from "../PredefinedMessages/PredefinedMessages";
import SelectedVehicles from "../SelectedVehicles/SelectedVehicles";
import styles from "./ComposeMessageModal.module.scss";

interface ComposeMessageModalProps {
  visible: boolean;
  onCloseModal: () => void;
}

const ComposeMessageModal = memo(
  ({ visible, onCloseModal }: ComposeMessageModalProps) => {
    const [showPredefinedMessages, setShowPredefinedMessages] = useState(false);

    const selectedVehicles = useSelector(
      (state: RootState) => state.vehicleGroups.selectedVehicles
    );
    const predefinedMessages = useSelector(
      (state: RootState) => state.messages.predefinedMessages
    );
    const loadingPredefinedMessages = useSelector(
      (state: RootState) => state.messages.loadingPredefinedMessages
    );

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    useMount(() => {
      dispatch(
        getPredefinedMessages({
          page: 1,
          pageSize: PredefinedMessagePagination.PAGE_SIZE,
          directionType: 0,
        })
      );
    });

    const handleBack = () => {
      history.push(FleetManagerPathsEnum.NEW_MESSAGE);
    };

    const sendGroupMessage = async (message: SendGroupMessageDef) => {
      AlertMessage.loading({
        content: t("fleetManagerMessages.sendingMessage"),
        key: AlertMessageConfig.GROUP_MESSAGE_KEY,
      });

      const response = await dispatch(sendGroupMessageToDriver(message));

      if (sendGroupMessageToDriver.fulfilled.match(response)) {
        AlertMessage.success({
          content: t("fleetManagerMessages.sentMessageSuccess"),
          key: AlertMessageConfig.GROUP_MESSAGE_KEY,
          duration: AlertMessageConfig.DURATION,
        });
        message.predefined_message_id
          ? setShowPredefinedMessages(false)
          : form.resetFields();
        onCloseModal();
      }
      if (sendGroupMessageToDriver.rejected.match(response)) {
        AlertMessage.error({
          content: t("fleetManagerMessages.sentMessageFailed"),
          key: AlertMessageConfig.GROUP_MESSAGE_KEY,
          duration: AlertMessageConfig.DURATION,
        });
      }
    };

    const onSubmit = () => {
      const formValue = form.getFieldsValue();
      const newMessage = formValue.body?.trim();
      if (newMessage && selectedVehicles.length) {
        sendGroupMessage({
          vehicle_nos: selectedVehicles?.map(vehicle => vehicle.id),
          text_body: newMessage,
          attachments: [],
        });
      }
    };

    const sendPredefinedMessage = (id: PredefinedMessageDef["id"]) => {
      if (selectedVehicles.length) {
        sendGroupMessage({
          vehicle_nos: selectedVehicles?.map(vehicle => vehicle.id),
          predefined_message_id: id,
          attachments: [],
        });
      }
    };

    const sendAttachment = (fileUri: string, file: any) => {
      sendGroupMessage({
        vehicle_nos: selectedVehicles?.map(vehicle => vehicle.id),

        attachments: [
          {
            name: file.name,
            mime_type: file.type,
            size_bytes: file.size,
            file_uri: fileUri,
          },
        ],
      });
    };

    return (
      <Modal
        title={t("fleetManagerMessages.messageModalTitle")}
        visible={visible}
        onCancel={onCloseModal}
        destroyOnClose
        centered
        footer={[
          <Button onClick={onCloseModal}>{t("default.cancelTitle")}</Button>,
          <Button
            className={styles.composeMessageBtn}
            type="primary"
            disabled={selectedVehicles.length === 0}
            onClick={onSubmit}
          >
            {t("fleetManagerMessages.buttonSend")}
          </Button>,
        ]}
      >
        <SelectedVehicles
          selectedVehicles={selectedVehicles}
          isRemovable={false}
          resetBtnText={t("default.resetTitle")}
          backBtnText={t("default.editTitle")}
          onBack={handleBack}
        />
        <Form
          name="message_form"
          form={form}
          className={styles.messageForm}
          size="large"
        >
          <Form.Item name="body">
            <Input.TextArea
              disabled={selectedVehicles.length === 0}
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder={t("fleetManagerMessages.messagePlaceholder")}
            />
          </Form.Item>
        </Form>
        {showPredefinedMessages && (
          <PredefinedMessages
            messages={predefinedMessages?.data}
            loading={loadingPredefinedMessages}
            messageBtnSize="small"
            className={styles.predefinedWrapperSmall}
            onSelectPredefinedMessage={sendPredefinedMessage}
          />
        )}
        <ActionButtons
          disabled={selectedVehicles.length === 0}
          vehiclesNo={selectedVehicles?.map(vehicle => vehicle.id)}
          showBtnTitle
          activatePredefined={showPredefinedMessages}
          className={styles.actionGroupMessage}
          togglePredefined={() =>
            setShowPredefinedMessages(!showPredefinedMessages)
          }
          attachmentUploaded={(fileUri: any, file: any) =>
            sendAttachment(fileUri, file)
          }
        />
      </Modal>
    );
  }
);

export default ComposeMessageModal;
