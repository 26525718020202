import { useEffect, useState } from "react";

/**
 * Hook that return true if clicks outside of the passed ref
 */
const useOutsideClick = (elementRef: React.RefObject<HTMLElement>) => {
  const [outsideClicked, setOutsideClicked] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      elementRef.current &&
      event.target instanceof HTMLElement &&
      !elementRef.current.contains(event.target)
    ) {
      // Clicked outside
      setOutsideClicked(true);
    } else {
      // Clicked inside
      setOutsideClicked(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);

  return { outsideClicked };
};

export default useOutsideClick;
