import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { GoogleMapDefaults } from "@app/constants/map.constants";

import { fleetManagerApi } from "../api/fleet-manager.api";
import {
  MapProjectionDef,
  VehiclePositionDef,
  VehiclePositionFilterDef,
} from "../types/vehicle-position.types";

type VehiclesPositionsState = {
  vehiclePositions: {
    totalCount: number;
    vehicles: VehiclePositionDef[];
  };
  loadingPositions: boolean;
  mapProjection: MapProjectionDef;
  activeVehicleDetailId: string | undefined;
};

const initialState: VehiclesPositionsState = {
  vehiclePositions: {
    totalCount: 0,
    vehicles: [],
  },
  loadingPositions: false,
  mapProjection: {
    zoom: GoogleMapDefaults.ZOOM,
    center: undefined,
  },
  activeVehicleDetailId: undefined,
};

export const getVehiclesPositions = createAsyncThunk(
  "vehicles/positions",
  async (filters?: VehiclePositionFilterDef) => {
    try {
      const response = await fleetManagerApi.getVehiclesPositions(filters);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const getVehiclesPositionsForStatus = createAsyncThunk(
  "vehicles/positions-status",
  async (filters?: VehiclePositionFilterDef) => {
    try {
      const response = await fleetManagerApi.getVehiclesPositions(filters);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

const vehiclesPositionsSlice = createSlice({
  name: "vehiclesPositions",
  initialState,
  reducers: {
    setMapProjection: (state, action: PayloadAction<MapProjectionDef>) => {
      state.mapProjection = action.payload;
    },
    resetMapProjectionCenter: state => {
      state.mapProjection.center = initialState.mapProjection.center;
    },
    clearVehiclePositions: state => {
      state.vehiclePositions = initialState.vehiclePositions;
    },
    setActiveVehicleDetailId: (state, action: PayloadAction<string>) => {
      state.activeVehicleDetailId = action.payload;
    },
    resetActiveVehicleDetailId: state => {
      state.activeVehicleDetailId = initialState.activeVehicleDetailId;
    },
  },
  extraReducers: builder => {
    builder.addCase(getVehiclesPositions.pending, state => {
      state.loadingPositions = true;
    });
    builder.addCase(getVehiclesPositions.fulfilled, (state, action) => {
      state.vehiclePositions = action.payload || initialState.vehiclePositions;
      state.loadingPositions = false;
    });
    builder.addCase(getVehiclesPositions.rejected, state => {
      state.loadingPositions = false;
    });
  },
});

export const {
  setMapProjection,
  resetMapProjectionCenter,
  clearVehiclePositions,
  setActiveVehicleDetailId,
  resetActiveVehicleDetailId,
} = vehiclesPositionsSlice.actions;

export default vehiclesPositionsSlice.reducer;
