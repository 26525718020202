import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { PaginationDef } from "@app/types/pagination.types";

import { fleetManagerApi } from "../api/fleet-manager.api";
// import { VisibleVehicleGroupFilterDef } from "../types/messages.types";
import {
  SimplifiedVehicleGroupDef,
  SimplifiedVehicleDef,
  VehicleGroupDef,
  VehicleDef,
  VehicleGroupFilterDef,
} from "../types/vehicle-group.types";

type VehicleGroupState = {
  vehicleGroups: {
    data: SimplifiedVehicleGroupDef[];
    pagination: PaginationDef;
  };
  loadingVehicleGroups: boolean;
  selectedVehicles: SimplifiedVehicleDef[];
};

const initialPaginationState = {
  current_page: 1,
  per_page: 10,
  total: 0,
  total_pages: 0,
};

const initialState: VehicleGroupState = {
  vehicleGroups: {
    data: [],
    pagination: initialPaginationState,
  },
  loadingVehicleGroups: false,
  selectedVehicles: [],
};

// This will get vehicle groups with static vehicles only
export const getVehicleGroupsWithStaticVehicles = createAsyncThunk(
  "vehicleGroups/getVehicleGroupsWithStaticVehicles",
  async (filters: VehicleGroupFilterDef) => {
    try {
      const response = await fleetManagerApi.getVehicleGroupsWithStaticVehicles(
        filters
      );
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

// This will get vehicle groups with all vehicles associated with it
export const getVehicleGroupsWithVehicles = createAsyncThunk(
  "vehicleGroups/getVehicleGroupsWithVehicles",
  async ({ userId, filters }: { userId: any; filters?: any }) => {
    try {
      const response = await fleetManagerApi.getVehicleGroupsWithVehicles(
        userId,
        filters
      );
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

const manipulateData = (vehicleGroups: VehicleGroupDef[]) => {
  return vehicleGroups?.map((vehicleGroup: VehicleGroupDef) => {
    const simplifiedVehicleGroup: SimplifiedVehicleGroupDef = {
      key: vehicleGroup.id?.toString(),
      id: vehicleGroup.id?.toString(),
      name: vehicleGroup.name,
      isGroup: true,
    };
    if (vehicleGroup.vehicles?.length > 0) {
      simplifiedVehicleGroup.vehicles = vehicleGroup.vehicles.map(
        (vehicle: VehicleDef): SimplifiedVehicleDef => ({
          // Assuming all the vehicles groups have different ids
          key: `${vehicle.vehicle_no}_${simplifiedVehicleGroup.id}`,
          id: vehicle.vehicle_no,
          name: vehicle.vehicle_no,
          parentId: simplifiedVehicleGroup.id,
        })
      );
    }
    return simplifiedVehicleGroup;
  });
};

const vehicleGroupsSlice = createSlice({
  name: "vehicleGroups",
  initialState,
  reducers: {
    setSelectedVehicles: (state, action) => {
      state.selectedVehicles = [...action.payload];
    },
    resetSelectedVehicles: state => {
      state.selectedVehicles = initialState.selectedVehicles;
    },
    resetVehicleGroups: state => {
      state.vehicleGroups = initialState.vehicleGroups;
    },
  },
  extraReducers: builder => {
    builder.addCase(getVehicleGroupsWithVehicles.pending, state => {
      state.loadingVehicleGroups = true;
    });
    builder.addCase(getVehicleGroupsWithVehicles.fulfilled, (state, action) => {
      state.vehicleGroups.data = manipulateData(action.payload);
      state.loadingVehicleGroups = false;
    });
    builder.addCase(getVehicleGroupsWithVehicles.rejected, state => {
      state.loadingVehicleGroups = false;
    });
  },
});

export const {
  setSelectedVehicles,
  resetSelectedVehicles,
  resetVehicleGroups,
} = vehicleGroupsSlice.actions;
export default vehicleGroupsSlice.reducer;
