import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fileUploadApi } from "../api/file-upload.api";
import { FileRequestDef, FileUploadDef } from "../types/file-upload.types";

type UploadState = {
  isUploading: boolean;
};

const initialState: UploadState = {
  isUploading: false,
};

export const requestUploadGrant = createAsyncThunk(
  "fileUpload/requestUploadGrant",
  async (fields: FileRequestDef, { rejectWithValue }) => {
    try {
      const response = await fileUploadApi.requestFileUploadGrant(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "fileUpload/uploadFile",
  async (fields: FileUploadDef, { rejectWithValue }) => {
    try {
      const response = await fileUploadApi.uploadFile(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {},
});

export default fileUploadSlice.reducer;
