import {
  FleetManagerPathsEnum,
  FLEET_MANAGER_ROUTES,
} from "@app/features/fleet-manager/fleet-manager";
import { REPORTING_ROUTES } from "@app/features/reporting/reporting";
import { SETTINGS_ROUTES } from "@app/features/settings/settings";

export const ROOT_ROUTE = FleetManagerPathsEnum.MAP;

export const PRIVATE_LIST = [
  ...FLEET_MANAGER_ROUTES,
  ...SETTINGS_ROUTES,
  ...REPORTING_ROUTES,
];
