import { memo } from "react";

import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./MessageHeader.module.scss";

const { Title, Text } = Typography;

interface MessageHeaderProps {
  vehicleNo: string;
  driver: any;
}

const MessageHeader = memo(({ vehicleNo, driver }: MessageHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs={7} xxl={6} className={styles.messageHeaderInfo}>
        {t("fleetManagerMessages.vehicleNo")}
        <Title level={5} className={styles.title}>
          <Text>{vehicleNo}</Text>
        </Title>
      </Col>
      <Col xs={8} xxl={7} className={styles.messageHeaderInfo}>
        {t("fleetManagerMessages.currentDriver")}
        <Title level={5} className={styles.title}>
          <Text>{driver}</Text>
        </Title>
      </Col>
      <Col xs={9} xxl={11} className={styles.messageHeaderInfo}>
        {t("fleetManagerMessages.phoneNumber")}
        <Title level={5} className={styles.title}>
          <Text>{driver?.phone}</Text>
        </Title>
      </Col>
    </Row>
  );
});

export default MessageHeader;
