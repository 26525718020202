export enum ReportingEndpointsEnum {
  SESSION = "/reporting/sessions",
  SESSION_REPORT_EXPORT = "/reporting/sessions/export",
  MESSAGE = "/v2/messenger/dispatcher",
  MESSAGE_REPORT_EXPORT = "/reporting/messages/export",
  CHASSIS_CHECK = "/v2/chassis-check",
  CHASSIS_CHECK_REPORT_EXPORT = "/chassis/checks/export",
  CHASSIS_CHECK_CATEGORIES = "v2/chassis-check-categories",
  REPORTING_STREAKS = "/v2/reporting",
  AUDITLOG = "/auditlog",
}
