import { memo, useEffect, useState } from "react";

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Row, Col, Checkbox, Typography, Empty } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import cx from "classnames";
import { FixedSizeList as VirtualizedList } from "react-window";

import Button from "@app/components/atoms/Button/Button";

import styles from "./SelectionBox.module.scss";

const { Title, Text } = Typography;

interface SelectionBoxProps {
  items: string[];
  selected: string[];
  availableTitle: string;
  selectedTitle: string;
  deselectAllTitle: string;
  emptySelectedMessage: string;
  className?: string;
  onChangeSelection: (selectedItems: string[]) => void;
}

type RenderItemDef = {
  data: string[];
  index: number;
  style: React.CSSProperties;
};

const SelectionBox = memo(
  ({
    items,
    selected,
    availableTitle,
    selectedTitle,
    deselectAllTitle,
    emptySelectedMessage,
    className,
    onChangeSelection,
  }: SelectionBoxProps) => {
    const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);
    const [itemsToAdd, setItemsToAdd] = useState<CheckboxValueType[]>([]);
    const [itemsToRemove, setItemsToRemove] = useState<CheckboxValueType[]>([]);
    const [triggerOnChange, setTriggerOnChange] = useState(false);

    useEffect(() => {
      selected?.length && setSelectedItems(selected);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.length]);

    useEffect(() => {
      onChangeSelection(selectedItems as string[]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerOnChange]);

    const onChangeAvailable = (e: CheckboxChangeEvent) => {
      const { value, checked } = e.target;
      if (checked) {
        setItemsToAdd(prevItems => [...prevItems, value]);
      } else {
        setItemsToAdd(itemsToAdd.filter(item => item !== value));
      }
    };

    const onChangeSelected = (e: CheckboxChangeEvent) => {
      const { value, checked } = e.target;
      if (checked) {
        setItemsToRemove(prevItems => [...prevItems, value]);
      } else {
        setItemsToRemove(itemsToRemove.filter(item => item !== value));
      }
    };

    const handleAddition = () => {
      const newItems = itemsToAdd.filter(item => !selectedItems.includes(item));
      setSelectedItems(prevItems => [...prevItems, ...newItems]);
      setTriggerOnChange(!triggerOnChange);
      setItemsToAdd([]); // Clearing the state
    };

    const handleRemoval = () => {
      const remainingItems = selectedItems.filter(
        item => !itemsToRemove.includes(item)
      );
      setSelectedItems(remainingItems);
      setTriggerOnChange(!triggerOnChange);
      setItemsToRemove([]); // Clearing the state
    };

    const handleDeselectAll = () => {
      setItemsToAdd([]);
    };

    const renderedItem = ({ data, index, style }: RenderItemDef) => (
      <div style={style} className={styles.item} key={items[index]}>
        <Checkbox value={data[index]} onChange={onChangeAvailable}>
          {data[index]}
        </Checkbox>
      </div>
    );

    return (
      <Row className={cx(styles.selectionBoxContainer, className)}>
        <Col span={10}>
          <Row justify="center" className={styles.availableItems}>
            <div className={styles.boxTitle}>
              <Title level={5}>{availableTitle}</Title>
              {itemsToAdd?.length > 0 && (
                <Button
                  type="text"
                  size="small"
                  className={styles.deselectBtn}
                  onClick={handleDeselectAll}
                >
                  <Text type="success">{deselectAllTitle}</Text>
                </Button>
              )}
            </div>
            <div className={styles.listItems}>
              <Checkbox.Group
                className={styles.checkBoxGroup}
                value={itemsToAdd}
              >
                {items.length ? (
                  <VirtualizedList
                    height={448}
                    itemCount={items.length}
                    itemSize={30}
                    itemData={items}
                    width={260}
                  >
                    {renderedItem}
                  </VirtualizedList>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Checkbox.Group>
            </div>
          </Row>
        </Col>
        <Col span={4}>
          <div className={styles.controlButtons}>
            <Button
              className={styles.button}
              shape="circle"
              onClick={handleAddition}
              icon={<ArrowRightOutlined />}
            />
            <Button
              className={styles.button}
              shape="circle"
              onClick={handleRemoval}
              icon={<ArrowLeftOutlined />}
            />
          </div>
        </Col>
        <Col span={10}>
          <Row justify="center" className={styles.selectedItems}>
            <div className={styles.boxTitle}>
              <Title ellipsis level={5}>
                {selectedTitle}
              </Title>
            </div>
            <div
              className={cx([styles.listItems, styles.scrollable], {
                [styles.emptyList]: selectedItems.length === 0,
              })}
            >
              {selectedItems?.length > 0 ? (
                <Checkbox.Group
                  className={styles.checkBoxGroup}
                  value={itemsToRemove}
                >
                  {selectedItems?.map(item => (
                    <Col
                      span={24}
                      className={styles.item}
                      key={item.toString()}
                    >
                      <Checkbox value={item} onChange={onChangeSelected}>
                        {item}
                      </Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              ) : (
                <Text type="secondary" className={styles.emptyMessage}>
                  {emptySelectedMessage}
                </Text>
              )}
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
);

export default SelectionBox;
