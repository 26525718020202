import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { reportingApi } from "../api/reporting.api";
import { ReportingPositionDef } from "../types/reporting-position.types";

type PositionReportState = {
  report: {
    data: ReportingPositionDef | null;
  };
  loadingReport: boolean;
};

const initialState: PositionReportState = {
  report: {
    data: null,
  },
  loadingReport: false,
};

export const getPositionReport = createAsyncThunk(
  "positionReport/getPositionReport",
  async (filter: any, { rejectWithValue }) => {
    try {
      const response = await reportingApi.getPositionReport(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addStreakId = (report: ReportingPositionDef) => {
  const streaks = report?.streaks?.map(streak => {
    return { ...streak, id: uuidv4() };
  });
  return {
    ...report,
    streaks,
  };
};

const positionReportSlice = createSlice({
  name: "positionReport",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPositionReport.pending, state => {
      state.loadingReport = true;
    });
    builder.addCase(getPositionReport.fulfilled, (state, action) => {
      state.loadingReport = false;
      state.report.data = addStreakId(action.payload) ?? null;
    });
    builder.addCase(getPositionReport.rejected, state => {
      state.loadingReport = false;
    });
  },
});

export default positionReportSlice.reducer;
