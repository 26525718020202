// import { MenuOutlined } from "@ant-design/icons";
import { Table, Tooltip } from "antd";
// import _compact from "lodash/compact";
import { useTranslation } from "react-i18next";

import TableView from "@app/components/molecules/TableView/TableView";
import { LanguageCodes, Languages } from "@app/constants/language.constants";
import { PaginationDef } from "@app/types/pagination.types";

import {
  PredefinedMessageDef,
  PredefinedMessageLocalization,
} from "../../types/predefined-message.types";
import styles from "./PredefinedMessageTable.module.scss";

interface PredefinedMessageTableProps {
  data: PredefinedMessageDef[];
  pagination: PaginationDef;
  loading: boolean;
  onEdit: (record: PredefinedMessageDef) => void;
  onDelete: (record: PredefinedMessageDef) => void;
}

const PredefinedMessageTable = ({
  data,
  pagination,
  loading,
  onEdit,
  onDelete,
}: PredefinedMessageTableProps) => {
  const { t } = useTranslation();

  return (
    <TableView
      dataSource={data}
      rowKey="id"
      actionTitle={t("default.columnAction")}
      onEdit={onEdit}
      onDelete={onDelete}
      loading={loading}
      pagination={{ ...pagination }}
    >
      {/* <Table.Column
        dataIndex="id"
        width={80}
        title={t("settingsPredefinedMessages.columnSort")}
        render={() => <MenuOutlined />}
      /> */}
      <Table.Column
        key="localizations"
        dataIndex="localizations"
        ellipsis
        title={t("settingsPredefinedMessages.columnMessage")}
        render={(localizations: PredefinedMessageLocalization[]) =>
          localizations?.filter(
            (localization: PredefinedMessageLocalization) =>
              localization.language_code === Languages.ENGLISH
          )[0]?.localized_text
        }
      />
      <Table.Column
        key="localizations"
        dataIndex="localizations"
        ellipsis
        title={t("settingsPredefinedMessages.columnLanguages")}
        render={(localizations: PredefinedMessageLocalization[]) => {
          const localizationWithoutEn = localizations?.filter(
            (localization: PredefinedMessageLocalization) =>
              localization.language_code !== Languages.ENGLISH
          );

          const truncatedMessages = localizationWithoutEn
            .map((value: PredefinedMessageLocalization) =>
              value && value.localized_text.length > 150
                ? `${value?.localized_text.substr(0, 150 - 1)}... `
                : value.localized_text
            )
            .join(", ");

          return (
            <Tooltip
              overlayClassName={styles.messageTooltip}
              placement="topLeft"
              title={truncatedMessages}
            >
              {localizationWithoutEn
                .map(
                  (code: PredefinedMessageLocalization) =>
                    LanguageCodes[code.language_code]
                )
                .join(", ")}
            </Tooltip>
          );
        }}
      />
    </TableView>
  );
};

export default PredefinedMessageTable;
