import { memo } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";

import { VehiclePositionDef } from "../../../../types/reporting-position.types";
import PositionAddress from "../PositionAddress/PositionAddress";
import styles from "./PositionDetailsCard.module.scss";

const { Text } = Typography;

interface PositionDetailsCardProps {
  position?: VehiclePositionDef;
  onClose?: () => void;
}

const PositionDetailsCard = memo(
  ({ position, onClose }: PositionDetailsCardProps) => {
    return (
      <Card
        bordered={false}
        className={styles.cardContainer}
        style={{ width: 300 }}
      >
        <Row>
          <Col md={24} className={styles.cardAction}>
            <CloseOutlined className={styles.closeIcon} onClick={onClose} />
          </Col>
          <Col md={24}>
            <Text className={styles.cardTitle}>
              {position?.activity?.address ? (
                <PositionAddress address={position?.activity?.address} />
              ) : (
                "-"
              )}
            </Text>
          </Col>
        </Row>
      </Card>
    );
  }
);

export default PositionDetailsCard;
