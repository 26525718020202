import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import {
  PaginationDef,
  PaginationFilterDef,
} from "@app/types/pagination.types";

import { settingsApi } from "../api/settings.api";
import {
  VehicleRequestDef,
  DepartmentDef,
  PlanSubDepartmentDef,
  ResponsibleDef,
  VehicleDef,
  VehiclesFilterDef,
} from "../types/vehicle.types";

type VehiclesState = {
  vehicles: {
    data: VehicleDef[];
    pagination: PaginationDef;
  };
  departments: DepartmentDef[];
  planSubDepartments: PlanSubDepartmentDef[];
  vehicleResponsibles: ResponsibleDef[];
  loadingVehicles: boolean;
};

const initialState: VehiclesState = {
  vehicles: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  departments: [],
  planSubDepartments: [],
  vehicleResponsibles: [],
  loadingVehicles: false,
};

export const getVehicles = createAsyncThunk(
  "vehicles/getVehicles",
  async (filter: VehiclesFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicles(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehicleByNo = createAsyncThunk(
  "vehicles/getVehicleByNo",
  async (vehicleNo: VehicleDef["vehicle_no"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicleByNo(vehicleNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDepartments = createAsyncThunk(
  "vehicles/getDepartments",
  async (filter: PaginationFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getDepartments(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPlanSubDepartments = createAsyncThunk(
  "vehicles/getPlanSubDepartments",
  async (filter: PaginationFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getPlanSubDepartments(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehicleResponsibles = createAsyncThunk(
  "vehicles/getVehicleResponsibles",
  async (filter: PaginationFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicleResponsibles(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewVehicle = createAsyncThunk(
  "vehicles/addNewVehicle",
  async (fields: VehicleRequestDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.addNewVehicle(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVehicle = createAsyncThunk(
  "vehicles/updateVehicle",
  async (
    data: { vehicleNo: VehicleDef["vehicle_no"]; fields: VehicleRequestDef },
    { rejectWithValue }
  ) => {
    const { vehicleNo, fields } = data;
    try {
      const response = await settingsApi.updateVehicle(vehicleNo, fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVehicle = createAsyncThunk(
  "vehicles/deleteVehicle",
  async (vehicleNo: VehicleDef["vehicle_no"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deleteVehicle(vehicleNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVehicles.pending, state => {
      state.loadingVehicles = true;
    });
    builder.addCase(getVehicles.fulfilled, (state, action) => {
      state.loadingVehicles = false;
      state.vehicles.data = action.payload.data || [];
      state.vehicles.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getVehicles.rejected, state => {
      state.loadingVehicles = false;
    });
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      state.departments = action.payload.data || [];
    });
    builder.addCase(getPlanSubDepartments.fulfilled, (state, action) => {
      state.planSubDepartments = action.payload.data || [];
    });
    builder.addCase(getVehicleResponsibles.fulfilled, (state, action) => {
      state.vehicleResponsibles = action.payload.data || [];
    });
  },
});

export default vehiclesSlice.reducer;
