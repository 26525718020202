import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";

const getMe = (): Promise<AxiosResponse> => {
  return api.get(AuthEndpointsEnum.USER_ME);
};

export const authApi = {
  getMe,
};
