import {
  memo,
  useEffect,
  useState,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
} from "react";

import { Input } from "antd";
import { useTranslation } from "react-i18next";

import SelectionBox from "../../../../components/SelectionBox/SelectionBox";
import { filterListByName } from "../../../../helpers/settings.helpers";
import { VehicleDef } from "../../../../types/vehicle-group.types";

const { Search } = Input;
interface StaticGroupProps {
  vehicles: VehicleDef["vehicle_no"][];
  editMode: boolean;
  groupName: string;
  groupVehicles?: VehicleDef["vehicle_no"][];
  onSelection: (selectedItems: VehicleDef["vehicle_no"][]) => void;
}

const StaticGroup = memo(
  ({
    vehicles,
    editMode,
    groupVehicles,
    groupName,
    onSelection,
  }: StaticGroupProps) => {
    const [searchValue, setSearchValue] = useState("");
    const [filteredVehicles, setFilteredVehicles] = useState<
      VehicleDef["vehicle_no"][]
    >([]);

    useEffect(() => {
      setFilteredVehicles(vehicles);
    }, [vehicles]);

    const { t } = useTranslation();

    const handleSearch = (
      value: string,
      event?:
        | ChangeEvent<HTMLInputElement>
        | MouseEvent<HTMLElement>
        | KeyboardEvent<HTMLInputElement>
    ) => {
      event?.preventDefault();
      const filteredResult: VehicleDef["vehicle_no"][] = filterListByName(
        vehicles,
        value.trim()
      );
      setFilteredVehicles(filteredResult);
    };

    const handleSelection = (selectedVehicles: VehicleDef["vehicle_no"][]) => {
      onSelection(selectedVehicles);
    };

    return (
      <>
        <Search
          placeholder={t("settingsVehicleGroups.searchVehiclePlaceholder")}
          size="large"
          allowClear
          value={searchValue}
          style={{ width: 320 }}
          onChange={e => setSearchValue(e.target.value.trim())}
          onSearch={handleSearch}
        />
        <SelectionBox
          items={filteredVehicles}
          selected={editMode && groupVehicles?.length ? groupVehicles : []}
          availableTitle={t("settingsVehicleGroups.availableVehiclesTitle")}
          selectedTitle={groupName}
          deselectAllTitle={t("default.deselectAll")}
          emptySelectedMessage={t(
            "settingsVehicleGroups.staticEmptySelectionMessage"
          )}
          onChangeSelection={handleSelection}
        />
      </>
    );
  }
);

export default StaticGroup;
