import { useEffect, useState } from "react";

import { Col, Row } from "antd";
import _isEmpty from "lodash/isEmpty";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useInterval, useUnmount } from "react-use";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import { VehicleLocationFetchTime } from "@app/constants/map.constants";
import { RootState } from "@app/redux/root-reducer";

import {
  filterVehicleGroupsByVehicleName,
  filterVehiclesFromGroups,
  getAllVehiclesFromGroup,
  getSelectedVehicles,
  handleSelection,
  getIdenticalVehicles,
} from "../../helpers/fleet-manager.helpers";
import { setSelectedVehicles } from "../../redux/selected-vehicles.slice";
import {
  getVehicleGroupsWithVehicles,
  resetVehicleGroups,
} from "../../redux/vehicle-groups.slice";
import {
  clearVehiclePositions,
  getVehiclesPositions,
  getVehiclesPositionsForStatus,
} from "../../redux/vehicles-positions.slice";
import {
  SimplifiedVehicleDef,
  SimplifiedVehicleGroupDef,
} from "../../types/vehicle-group.types";
import {
  MapBoundDef,
  VehiclePositionFilterDef,
} from "../../types/vehicle-position.types";
import styles from "./MapScreen.module.scss";
import Map from "./components/Map/Map";
import MapVehicleList from "./components/MapVehicleList/MapVehicleList";

const MapScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<
    SimplifiedVehicleGroupDef[]
  >([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const [
    positionFilterParams,
    setPositionFilterParams,
  ] = useState<VehiclePositionFilterDef>({});

  const [bounds, setBounds] = useState<MapBoundDef>();

  const { vehiclePositions } = useSelector(
    (state: RootState) => state.vehiclesPositions
  );

  const { selectedVehicles } = useSelector(
    (state: RootState) => state.selectedVehicles
  );

  const { vehicleGroups, loadingVehicleGroups } = useSelector(
    (state: RootState) => state.vehicleGroups
  );

  const userId = useSelector((state: RootState) => state.auth.user?.id);

  const getPositions = async (groups: any) => {
    const vehicles = filterVehiclesFromGroups(groups);

    const params = { vehicleNos: vehicles.toString() };

    // TODO: Adding this line to prevent unnecessary api calling without vehicles Ticket: ANCO-1685
    if (vehicles && vehicles.length === 0) return;

    const res: any = await dispatch(getVehiclesPositionsForStatus(params));
    const data = groups.map((grp: any) => {
      return {
        ...grp,
        vehicles: grp.vehicles?.filter((vh: any) => {
          return (
            res.payload.vehicles?.find((vkh: any) => vkh.no === vh.id)?.no ===
            vh.id
          );
        }),
      };
    });
    setFilteredVehicleGroups(data);
  };

  /**
   * Handle group search
   * @param value
   */
  const handleSearch = (value: string) => {
    const filteredGroups: SimplifiedVehicleGroupDef[] = filterVehicleGroupsByVehicleName(
      vehicleGroups.data,
      value.trim()
    );
    getPositions(filteredGroups);
  };

  /**
   * Reset values on un mount
   */
  useUnmount(() => {
    setPositionFilterParams({});
    dispatch(resetVehicleGroups());
    dispatch(clearVehiclePositions());
  });

  /**
   * Effects on dispatch
   */
  useEffect(() => {
    const filters: { page?: number; pageSize?: number } = {
      page: 1,
      pageSize: 10000,
    };
    dispatch(getVehicleGroupsWithVehicles({ userId, filters }));
  }, [dispatch, userId]);

  /**
   * Effects on vehicle group data
   */
  useEffect(() => {
    // TODO: Commenting this call as this could be the reason. Need to test properly, Ticket: ANCO-1685
    // getPositions(vehicleGroups.data);

    if (!(vehicleGroups.data?.length > 0)) {
      return;
    }

    // If the selected vehicles is null or undefined
    if (selectedVehicles == null) {
      const vehicles = filterVehiclesFromGroups(vehicleGroups.data);
      if (vehicles.length) {
        const params = { vehicleNos: vehicles.toString() };
        setPositionFilterParams(params);
      }
      // Selected row keys, default all selected
      const rowKeys = vehicleGroups.data.flat().map(item => item.key);
      setSelectedRowKeys(rowKeys);
      return;
    }

    // If there are selected vehicles in the store
    if (selectedVehicles?.length) {
      const identicalVehicles: SimplifiedVehicleDef[] = [];
      selectedVehicles.forEach(item => {
        const data = getIdenticalVehicles(vehicleGroups.data, item);
        identicalVehicles.push(...data);
      });

      const rowKeys = identicalVehicles.map(item => item.key);
      setSelectedRowKeys(rowKeys);
      return;
    }

    // If the selected vehicles exists but the list is empty
    if (!selectedVehicles?.length) {
      setPositionFilterParams({});
      dispatch(clearVehiclePositions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleGroups.data]);

  /**
   * Effects on position filter params
   */
  useEffect(() => {
    if (!_isEmpty(positionFilterParams)) {
      dispatch(getVehiclesPositions(positionFilterParams));
    }
  }, [positionFilterParams, dispatch]);

  /**
   * Effects on location search params and vehicle group data
   */
  useEffect(() => {
    const { vehicleNos } = qs.parse(location.search);

    handleSearch(vehicleNos?.toString() ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, vehicleGroups.data]);

  /**
   * Effects on vehicle selection
   */
  useEffect(() => {
    if (selectedVehicles) {
      let params;
      const vehicles = selectedVehicles
        .filter(item => !item.isGroup)
        .map(item => item.id);

      if (vehicles.length) {
        params = { vehicleNos: vehicles.toString() };
      } else {
        params = {};
        dispatch(clearVehiclePositions());
      }

      setPositionFilterParams(params);
    }
  }, [selectedVehicles, dispatch]);

  /**
   * Fetch vehicle positions in interval
   */
  useInterval(() => {
    if (!_isEmpty(positionFilterParams)) {
      dispatch(getVehiclesPositions(positionFilterParams));
    }
  }, VehicleLocationFetchTime.INTERVAL);

  /**
   * Handle row selection
   * @param record
   * @param selected
   * @param selectedRows
   */
  const handleRowSelection = (
    record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
    selected: boolean,
    selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[]
  ) => {
    const rowKeys = handleSelection(
      record,
      selected,
      selectedRows,
      filteredVehicleGroups
    );

    setSelectedRowKeys(rowKeys);
    const data = getSelectedVehicles(rowKeys, filteredVehicleGroups);

    dispatch(setSelectedVehicles(data));
  };

  /**
   * Handle select all
   * @param selected
   */
  const handleAllSelect = (selected: boolean) => {
    const rowKeys = selected
      ? filteredVehicleGroups.flat().map(item => item.key)
      : [];
    setSelectedRowKeys(rowKeys);

    const allVehicles = getAllVehiclesFromGroup(filteredVehicleGroups);

    const allKeys = selected ? allVehicles.map(item => item.key) : [];

    const allSelectedVehicles = getSelectedVehicles(
      allKeys,
      filteredVehicleGroups
    );

    dispatch(setSelectedVehicles(allSelectedVehicles));
  };

  /**
   * Handle map bounds based on locations
   * @param mapBounds
   */
  const handleSelectBounds = (mapBounds: MapBoundDef | undefined) => {
    setBounds(mapBounds);
  };

  return (
    <Row gutter={12}>
      <Col xs={24} md={8}>
        <ContentLayout className={styles.listColumnContainer}>
          <ScreenTitle
            title={t("fleetManagerMap.title")}
            subTitle={t("fleetManagerMap.subTitle")}
            filters={
              <SearchFilters
                placeholder={t("default.searchPlaceholder")}
                searchBy="vehicleNos"
                className={styles.searchFilter}
              />
            }
          />
          <MapVehicleList
            groups={filteredVehicleGroups}
            vehiclePositions={vehiclePositions.vehicles}
            loading={loadingVehicleGroups}
            selectedRowKeys={selectedRowKeys}
            onRowSelection={handleRowSelection}
            onAllSelect={handleAllSelect}
            onSelectBound={handleSelectBounds}
            // positionFilterParams={positionFilterParams}
          />
        </ContentLayout>
      </Col>
      <Col xs={24} md={16}>
        <ContentLayout>
          <Map vehicles={vehiclePositions.vehicles} mapBounds={bounds} />
        </ContentLayout>
      </Col>
    </Row>
  );
};

export default MapScreen;
