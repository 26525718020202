import { memo } from "react";

import { List } from "antd";

import { MessageDef } from "../../../../types/messages.types";
import MessageBubble from "../MessageBubble/MessageBubble";
import MessageDateDivider from "../MessageDateDivider/MessageDateDivider";
import MessageSenderName from "../MessageSenderName/MessageSenderName";
import styles from "./MessageThread.module.scss";

interface MessageThreadProps {
  conversations: MessageDef[];
  loading: boolean;
}

const MessageThread = memo(({ conversations, loading }: MessageThreadProps) => {
  return (
    <>
      <List
        className={styles.messageList}
        dataSource={conversations}
        loading={loading}
        renderItem={(item, dataIndex) => (
          <List.Item key={dataIndex}>
            <MessageDateDivider
              prevItem={dataIndex ? conversations[dataIndex - 1] : undefined}
              currentItem={item}
            />
            <MessageSenderName
              prevItem={dataIndex ? conversations[dataIndex - 1] : undefined}
              currentItem={item}
            />
            <MessageBubble message={item} />
          </List.Item>
        )}
      />
    </>
  );
});

export default MessageThread;
