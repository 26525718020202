export enum VehicleLocationFetchTime {
  INTERVAL = 10000,
}

export enum GoogleMapDefaults {
  MAP_CENTER_LAT = 55.6711876,
  MAP_CENTER_LNG = 12.4537421,
  ZOOM = 8,
  PROJECTION_ZOOM_IN = 15,
  PROJECTION_ZOOM_OUT = 7,
  CLUSTER_RADIUS = 100,
  CLUSTER_MAX_ZOOM = 20,
}

export enum VehicleListPagination {
  PAGE_SIZE = 9999,
}

export enum GoogleMapPolygon {
  STROKE_WEIGHT = 5,
  STROKE_COLOR = "#40a9ff",
  SELECT_STROKE_WEIGHT = 6,
  SELECT_STROKE_COLOR = "#389e0d",
}
