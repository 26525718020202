// TODO Separate independent components to other files
import { useEffect, useState } from "react";

import { differenceInMinutes, format } from "date-fns";
import { parseISO } from "date-fns/esm";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { DateFormats, TimeFormats } from "@app/constants/date.constants";
import useSearchParams from "@app/hooks/useSearchParams";

import { VehicleStatusEnum } from "../../../../constants/vehicle-status.constants";
import { convertMinsToHrsMins } from "../../../../helpers/reporting.helpers";
import {
  StreakDef,
  VehiclePositionDef,
} from "../../../../types/reporting-position.types";
import {
  GraphPointDef,
  PositionReportFilterDef,
} from "../../../../types/reporting.types";
import styles from "./Chat.module.scss";

interface ChartProps {
  streaks: StreakDef[] | undefined;
}
export default function Chart({ streaks }: ChartProps) {
  const [graphDatas, setGraphDatas] = useState<StreakDef[][] | undefined>([]);
  const [dates, setDates] = useState<string[]>([]);
  const { t } = useTranslation();
  const {
    search,
  }: {
    search: PositionReportFilterDef;
  } = useSearchParams();

  // TODO: need to set type
  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className={styles.toolTip}>
          <div className="label">{`${t("reportingGraph.tooltipLabelTime")} : ${
            payload[0].payload.time
          }`}</div>
          {t("reportingGraph.tooltipLabelSpeed")} :{" "}
          <span className={styles.toolTipSpeed}>{`${parseInt(
            payload[0].payload.speed,
            16
          ).toFixed(2)}`}</span>
          <div>
            {t("reportingGraph.tooltipLabelStatus")} :{" "}
            <span
              className={styles.toolTipStatus}
            >{`${payload[0].payload.status}`}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const calculateTotalDistance = (streakArray: StreakDef[]) => {
    let totalDistance = 0;
    streakArray?.forEach((streak: StreakDef) => {
      totalDistance += streak.total_distance;
    });
    return Math.round(totalDistance);
  };

  const calculateTotalDrivingTime = (
    streakArray: StreakDef[],
    requiredStatus: string
  ) => {
    let drivingTime = 0;
    streakArray.forEach((streak: StreakDef) => {
      if (streak.status?.toUpperCase() === requiredStatus) {
        drivingTime += differenceInMinutes(
          parseISO(streak.end),
          parseISO(streak.start)
        );
      }
    });
    return convertMinsToHrsMins(drivingTime);
  };

  const positionData = (getPositionData: StreakDef[]): GraphPointDef[] => {
    const positions: GraphPointDef[] = [];
    getPositionData?.forEach((streak: StreakDef) => {
      if (streak.positions) {
        const speed = streak.positions?.map((position: VehiclePositionDef) => {
          const graphPoints: GraphPointDef = {
            time: format(new Date(position.created), TimeFormats.SHORT),
            speed: position.speed,
            date: format(
              new Date(streak.start),
              DateFormats.DAYNAME_DAY_MONTH_YEAR
            ),
            status: streak.status,
            driver: streak.driver?.name,
            vehicle: streak.vehicle_no,
          };
          positions.push(graphPoints);
          return graphPoints;
        });
        return speed;
      }
      if (streak && !streak.positions) {
        const graphPoints: GraphPointDef = {
          time: format(new Date(streak.end), TimeFormats.SHORT),
          speed: streak.avg_speed,
          date: format(
            new Date(streak.end),
            DateFormats.DAYNAME_DAY_MONTH_YEAR
          ),
          status: streak.status,
          driver: streak.driver?.name,
          vehicle: streak.vehicle_no,
        };
        positions.push(graphPoints);
        return streak;
      }
      return streak;
    });

    return positions;
  };

  useEffect(() => {
    setDates([]);
    streaks?.forEach((streak: StreakDef) => {
      const currentDate = format(
        new Date(streak.start),
        DateFormats.DAYNAME_DAY_MONTH_YEAR
      );
      if (!dates.includes(currentDate)) {
        dates.push(currentDate);
      }
      return streak;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaks]);

  useEffect(() => {
    const newStreaks: StreakDef[][] | undefined =
      streaks &&
      dates
        .map((date: string) =>
          streaks?.filter(
            (streak: StreakDef) =>
              format(
                new Date(streak.end),
                DateFormats.DAYNAME_DAY_MONTH_YEAR
              ) === date
          )
        )
        .filter((streak: StreakDef[]) => streak.length > 0);
    setGraphDatas(newStreaks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaks]);

  // set graph data to empty where there is no divers or vehicles
  useEffect(() => {
    if (!search.drivers || !search.vehicles) {
      setGraphDatas([]);
    }
  }, [search]);
  return (
    <div>
      {graphDatas?.map((data: StreakDef[]) => (
        <div>
          <h3 className={styles.graphHeading}>
            <span>{positionData(data) && positionData(data)[0]?.date}</span>
            {positionData(data)[0]?.driver && (
              <span>
                Driver: {positionData(data) && positionData(data)[0]?.driver}
              </span>
            )}
            <span>
              Vehicle: {positionData(data) && positionData(data)[0]?.vehicle}
            </span>
          </h3>
          <div style={{ display: "flex" }}>
            <ResponsiveContainer width="90%" height={400}>
              <LineChart
                width={50}
                height={30}
                data={positionData(data) && positionData(data)}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis dataKey="time" interval={15} />
                <YAxis tickFormatter={(label: string) => `${label}km/h`} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="speed"
                  stroke="#8884d8"
                  dot={false}
                  activeDot={{ r: 8 }}
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="graphReport">
              <div>
                {t("reportingGraph.totalDistance")}:{" "}
                {calculateTotalDistance(data)}
              </div>
              <div>
                {t("reportingGraph.statusDriving")}:{" "}
                {calculateTotalDrivingTime(data, VehicleStatusEnum.DRIVING)}
              </div>
              <div>
                {t("reportingGraph.statusStopped")}:{" "}
                {calculateTotalDrivingTime(data, VehicleStatusEnum.STOPPED)}
              </div>
              <div>
                {t("reportingGraph.statusOffline")}:{" "}
                {calculateTotalDrivingTime(data, VehicleStatusEnum.OFFLINE)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
