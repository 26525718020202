/* eslint-disable camelcase */

import { useEffect, useMemo, useState } from "react";

import { Table, Tooltip, message as AlertMessage, Spin } from "antd";
import cx from "classnames";
import _truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useInterval, useMount } from "react-use";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import TableView, {
  ActionMenuDef,
} from "@app/components/molecules/TableView/TableView";
import { ChassisRepairStatus } from "@app/constants/chassis.constants";
import { DateTimeFormats } from "@app/constants/date.constants";
import { EXPORTED_CONTENT_TYPE } from "@app/constants/file.constants";
import { MessageFetchTime } from "@app/constants/message.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import {
  formattedDateTime,
  processBlobToFileForPdf,
} from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getUsers } from "../../../settings/redux/users.slice";
import {
  getChassisCheckCategories,
  getChassisCheckReport,
  updateChassisCheckReport,
  getVehicleGroups,
  getChassisCheckPdfById,
} from "../../redux/chassis-check-report.slice";
import {
  ChassisCheckReportDef,
  ChassisCheckReportFilterDef,
  ChassisCheckReportRequestDef,
} from "../../types/reporting.types";
import styles from "./ChassisCheckReportScreen.module.scss";
import ChassisCheckDamages from "./components/ChassisCheckDamages/ChassisCheckDamges";
import ChassisCheckFilters from "./components/ChassisCheckFilters/ChassisCheckFilters";
import ChassisReportModal from "./components/ChassisReportModal/ChassisReportModal";

const ChassisCheckReportScreen = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [loadingOnInterval, setLoadingOnInterval] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search }: { search: ChassisCheckReportFilterDef } = useSearchParams();
  const { updateSearchParams } = useSearchParams();
  const reports = useSelector(
    (state: RootState) => state.chassisCheckReport.report
  );
  const loadingReport = useSelector(
    (state: RootState) => state.chassisCheckReport.loadingReport
  );

  useMount(() => {
    dispatch(getChassisCheckCategories({ pageSize: MAX_PAGE_SIZE }));
    dispatch(getUsers({ role: "Driver", pageSize: MAX_PAGE_SIZE }));
  });

  useEffect(() => {
    dispatch(
      getChassisCheckReport({
        page:
          search.vehicles ||
          search.chassisNos ||
          search.damageCategories ||
          search.damagesReported ||
          search.from ||
          search.to ||
          search.status ||
          search.users ||
          search.vehicles
            ? DEFAULT_PAGE
            : search?.page ?? DEFAULT_PAGE,
        pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
        vehicles: search?.vehicles ?? undefined,
        users: search?.users ?? undefined,
        chassisNos: search?.chassisNos ?? undefined,
        to: search?.to ?? undefined,
        from: search?.from ?? undefined,
        damageCategories: search?.damageCategories?.toString() ?? undefined,
        damagesReported: search?.damagesReported ?? undefined,
        status: search?.status ?? undefined,
        vehicleGroups: search?.vehicleGroups ?? undefined,
      })
    );
    dispatch(
      getVehicleGroups({
        pageSize: MAX_PAGE_SIZE,
      })
    );
    setLoadingOnInterval(false);
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.vehicles,
    search?.users,
    search?.chassisNos,
    search?.to,
    search?.from,
    search?.damageCategories,
    search?.damagesReported,
    search?.status,
    search?.vehicleGroups,
    triggerRefetch,
  ]);

  useInterval(() => {
    dispatch(
      getChassisCheckReport({
        page: search?.page ?? DEFAULT_PAGE,
        pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
        vehicles: search?.vehicles ?? undefined,
        users: search?.users ?? undefined,
        chassisNos: search?.chassisNos ?? undefined,
        to: search?.to ?? undefined,
        from: search?.from ?? undefined,
        damageCategories: search?.damageCategories?.toString() ?? undefined,
        damagesReported: search?.damagesReported ?? undefined,
        status: search?.status ?? undefined,
        vehicleGroups: search?.vehicleGroups ?? undefined,
      })
    );
    setLoadingOnInterval(true);
  }, MessageFetchTime.INTERVAL);

  const showAlertMessage = (isSuccess: boolean, message: string) => {
    if (isSuccess) {
      AlertMessage.success(message);
    } else {
      AlertMessage.error(message ?? t("default.errorMessage"));
    }
  };

  const updateChassisCheck = async (
    id: ChassisCheckReportDef["id"],
    fields: ChassisCheckReportRequestDef
  ) => {
    const response = await dispatch(updateChassisCheckReport({ id, fields }));
    if (updateChassisCheckReport.fulfilled.match(response)) {
      setTriggerRefetch(!triggerRefetch);
      const message = fields.status
        ? t("reportingChassisCheck.chassisStatusChange")
        : t("reportingChassisCheck.chassisReportNotesAdded");
      showAlertMessage(true, message);
    }
    if (updateChassisCheckReport.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const menu: ActionMenuDef = useMemo(
    () => [
      {
        key: ChassisRepairStatus.NEW,
        label: t("reportingChassisCheck.markNew"),
      },
      {
        key: ChassisRepairStatus.READ,
        label: t("reportingChassisCheck.markRead"),
      },
      {
        key: ChassisRepairStatus.IN_REPAIR,
        label: t("reportingChassisCheck.markInRepair"),
      },
      {
        key: ChassisRepairStatus.FIXED,
        label: t("reportingChassisCheck.markFixed"),
      },
      {
        key: ChassisRepairStatus.FIXED_ALL,
        label: t("reportingChassisCheck.markAllAsFixed"),
      },
    ],
    [t]
  );

  const handleView = (chassisReport: ChassisCheckReportDef) => {
    updateSearchParams(modalAction.view(chassisReport.id.toString()));
  };

  const handlePdf = async (chassisReport: ChassisCheckReportDef) => {
    if (chassisReport.id) {
      setPdfLoading(true);
      const response = await dispatch(getChassisCheckPdfById(chassisReport.id));
      const blob = new Blob([response.payload], {
        type: "application/pdf;charset=utf-8",
      });
      processBlobToFileForPdf(
        blob,
        `chassisId-${chassisReport.id}.pdf`,
        EXPORTED_CONTENT_TYPE.PDF,
        setPdfLoading
      );
    }
  };

  const handleActionMenu = (
    key: string,
    chassisReport: ChassisCheckReportDef
  ) => {
    if (key === "FixedAll") {
      updateChassisCheck(chassisReport.id, {
        fixall: true,
        status: "Fixed",
      } as ChassisCheckReportRequestDef);
    } else {
      updateChassisCheck(chassisReport.id, {
        status: key,
        fixall: false,
      } as ChassisCheckReportRequestDef);
    }
  };

  const handleCloseModal = () => {
    updateSearchParams(modalAction.close());
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("reportingChassisCheck.pageTitle")}
        subTitle={t("reporting.filterHelperText")}
        filters={<ChassisCheckFilters />}
      />
      <Spin spinning={pdfLoading}>
        <TableView
          rowKey="id"
          actionTitle={t("default.columnAction")}
          dataSource={reports.data}
          loading={loadingReport && !loadingOnInterval}
          pagination={{ ...reports.pagination }}
          className={styles.chassisCheckTable}
          rowClassName={record => {
            return cx({
              [styles.chassisCheckUnread]: !record.readDate,
            });
          }}
          onView={handleView}
          onActionMenu={handleActionMenu}
          actionMenu={menu}
          onPdf={handlePdf}
        >
          <Table.Column
            dataIndex="vehicle_no"
            title={t("reportingChassisCheck.columnVehicleNo")}
          />
          <Table.Column
            dataIndex="reported"
            render={(reported: ChassisCheckReportDef["reported"]) =>
              reported?.user_name
            }
            title={t("reporting.columnDriver")}
          />
          <Table.Column
            dataIndex="chassis"
            title={t("reportingChassisCheck.columnChassisNo")}
            render={(chassis: ChassisCheckReportDef["chassis"]) =>
              chassis?.chassis_no
            }
          />
          <Table.Column
            dataIndex="chassis"
            title={t("reportingChassisCheck.columnChassisNumberPlate")}
            render={(chassis: ChassisCheckReportDef["chassis"]) =>
              chassis?.number_plate
            }
          />
          <Table.Column
            dataIndex="reported"
            title={t("reportingChassisCheck.columnCheckPerformed")}
            render={(reported: ChassisCheckReportDef["reported"]) => (
              <>
                {reported?.time &&
                  formattedDateTime(
                    reported?.time,
                    DateTimeFormats.TIME_DAY_MONTH_YEAR
                  )}
              </>
            )}
          />
          <Table.Column
            dataIndex="damages"
            title={t("reportingChassisCheck.columnDamages")}
            render={(damages: ChassisCheckReportDef["damages"]) =>
              damages && (
                <ChassisCheckDamages
                  damages={damages}
                  displayNumberOfDamages={2}
                />
              )
            }
          />
          <Table.Column
            title={t("reportingChassisCheck.columnNotes")}
            render={(chassisRes: ChassisCheckReportDef) => (
              <>
                <Tooltip
                  placement="top"
                  overlayClassName={cx(styles.tooltipNotes, {
                    [styles.tooltipLargeNotes]: chassisRes.notes?.length > 50,
                  })}
                  title={
                    <>
                      <div>{chassisRes.notes}lll</div>
                      {chassisRes.notes_last_changed && (
                        <div>
                          {t("reportingChassisCheck.notesChangedBy")}:
                          {chassisRes?.notes_last_changed?.user_name}
                        </div>
                      )}
                    </>
                  }
                >
                  {_truncate(chassisRes.notes, { length: 50 })}
                </Tooltip>
              </>
            )}
          />
          <Table.Column
            dataIndex="address"
            title={t("reportingChassisCheck.columnAddress")}
          />
          <Table.Column
            dataIndex="last_modified"
            title={t("reportingChassisCheck.columnLastModifiedBy")}
            render={(last_modified: ChassisCheckReportDef["last_modified"]) =>
              last_modified && (
                <>
                  {last_modified?.user_name},
                  {last_modified &&
                    formattedDateTime(
                      last_modified.time,
                      DateTimeFormats.TIME_DAY_MONTH_YEAR
                    )}
                </>
              )
            }
          />
          <Table.Column
            dataIndex="status"
            title={t("reportingChassisCheck.columnRepairStatus")}
            render={(status: ChassisRepairStatus) => (
              <>{t(`chassisCheckRepairStatus.status${status}`)}</>
            )}
          />
        </TableView>
      </Spin>

      <ChassisReportModal
        onClose={handleCloseModal}
        onUpdateChassisReport={updateChassisCheck}
      />
    </ContentLayout>
  );
};

export default ChassisCheckReportScreen;
