export const DEFAULT_PERMISSIONS = [
  {
    grantee: "anco:iam:user/ANCODOM:*",
    permission: "FULL_CONTROL",
  },
  {
    grantee: "anco:fleet:user-role:Dispatcher",
    permission: "WRITE",
  },
  {
    grantee: "anco:fleet:user-role:Administrator",
    permission: "FULL_CONTROL",
  },
];
