import { memo } from "react";

import { MessageTargetEnum } from "../../../../constants/fleet-manager.constants";
import { MessageDef } from "../../../../types/messages.types";
import styles from "./MessageSenderName.module.scss";

interface MessageSenderNameProps {
  prevItem: MessageDef | undefined;
  currentItem: MessageDef;
}

const MessageSenderName = memo(
  ({ prevItem, currentItem }: MessageSenderNameProps) => {
    return (
      <>
        {(!prevItem ||
          prevItem.sender_user?.user_id !== currentItem.sender_user?.user_id ||
          (prevItem.pushed_device === MessageTargetEnum.GROUP &&
            currentItem.pushed_device !== MessageTargetEnum.GROUP)) && (
          <>
            {currentItem.direction_type === MessageTargetEnum.DISPATCHER ? (
              <div className={styles.driverName} />
            ) : (
              currentItem.direction_type === MessageTargetEnum.DRIVER && (
                <div className={styles.dispatcherName}>
                  {currentItem.sender_user?.user_name}
                </div>
              )
            )}
          </>
        )}
        {currentItem.pushed_device === MessageTargetEnum.GROUP && (
          <div className={styles.dispatcherName}>
            {currentItem.sender_user?.user_name}
          </div>
        )}
      </>
    );
  }
);

export default MessageSenderName;
