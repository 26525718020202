import { memo } from "react";

import { Divider } from "antd";
import { isSameDay, isToday } from "date-fns";
import { useTranslation } from "react-i18next";

import { DateFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";

import { MessageDef } from "../../../../types/messages.types";
import styles from "./MessageDateDivider.module.scss";

interface MessageDateDividerProps {
  prevItem: MessageDef | undefined;
  currentItem: MessageDef;
}

const MessageDateDivider = memo(
  ({ prevItem, currentItem }: MessageDateDividerProps) => {
    const { t } = useTranslation();
    return (
      <>
        {(!prevItem ||
          !isSameDay(
            new Date(currentItem.sender_user?.time),
            new Date(prevItem.sender_user?.time)
          )) && (
          <div className={styles.dateDivider}>
            <Divider plain>
              {isToday(new Date(currentItem.sender_user?.time))
                ? t("fleetManagerMessages.today")
                : formattedDateTime(
                    currentItem.sender_user?.time,
                    DateFormats.MONTH_DAY_YEAR
                  )}
            </Divider>
          </div>
        )}
      </>
    );
  }
);

export default MessageDateDivider;
