import { DownOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";

import { navigationPermissions } from "@app/features/permissions/permissions";
import { RouteGroupDef, RouteItemDef } from "@app/types/route.types";

import NavLink from "../NavLink/NavLink";
import styles from "./NavSubMenu.module.scss";

interface NavSubMenuProps {
  item: RouteItemDef;
}

const NavSubMenu = ({ item, ...props }: NavSubMenuProps) => {
  const { t } = useTranslation();
  return (
    <Menu.SubMenu
      popupOffset={[-16, 7]}
      key={Array.isArray(item.path) ? item.path[0] : item.path}
      title={
        <div className={styles.subMenuItem}>
          <span>
            {item.navigationTitle
              ? t(item.navigationTitle)
              : `Missing navigationTitle for "${item.id}"`}
          </span>
          <DownOutlined className={styles.icon} />
        </div>
      }
      {...props}
    >
      {item.nestedRoutes?.map((subItem: RouteItemDef | RouteGroupDef) =>
        "groupTitle" in subItem ? (
          (!subItem.permissions ||
            (subItem.permissions &&
              navigationPermissions(subItem.permissions[0]))) && (
            <Menu.ItemGroup key={subItem.id} title={t(subItem.groupTitle)}>
              {subItem.nestedRoutes?.map(
                subGroupItem =>
                  (!subGroupItem.permissions ||
                    (subGroupItem.permissions &&
                      navigationPermissions(subGroupItem.permissions[0]))) && (
                    <Menu.Item
                      key={
                        Array.isArray(subGroupItem.path)
                          ? subGroupItem.path[0]
                          : subGroupItem.path
                      }
                    >
                      <NavLink navItem={subGroupItem} />
                    </Menu.Item>
                  )
              )}
            </Menu.ItemGroup>
          )
        ) : (
          <Menu.Item
            key={Array.isArray(subItem.path) ? subItem.path[0] : subItem.path}
          >
            <NavLink navItem={subItem} />
          </Menu.Item>
        )
      )}
    </Menu.SubMenu>
  );
};

export default NavSubMenu;
