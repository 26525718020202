export enum FleetManagerPathsEnum {
  FLEET_MANAGER = "/fleet-manager",
  MAP = "/fleet-manager/map",
  MESSAGES = "/fleet-manager/messages",
  MESSAGES_INCOMING = "/fleet-manager/messages/incoming",
  MESSAGES_OUTGOING = "/fleet-manager/messages/outgoing",
  MESSAGES_THREAD = "/fleet-manager/messages/:type/:vehicleNo/:id",
  NEW_MESSAGE = "/fleet-manager/messages/new-message",
  NEW_MESSAGE_COMPOSE = "/fleet-manager/messages/new-message/compose",
}
