import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _merge from "lodash/merge";

import { MapTypeEnum } from "../constants/map-type.constants";
import { MapSettingsOptionsDef } from "../types/map-settings.types";

type MapSettingsState = {
  mapSettings: MapSettingsOptionsDef;
};

const initialState: MapSettingsState = {
  mapSettings: {
    mapOptions: {
      zoomControl: false,
      fullscreenControl: true,
      mapTypeId: MapTypeEnum.ROAD_MAP,
    },
    mapDisplaySettings: {
      advancedSettings: {
        displayClusters: true,
        displayTraffic: false,
      },
      displayLabelSettings: {
        displayDriverName: false,
        displayVehicleNumber: true,
        displayApplicationStatus: false,
        displayDateTimeOfLastPosition: false,
      },
    },
  },
};

const mapSettingsOptionsSlice = createSlice({
  name: "mapSettingsOptions",
  initialState,
  reducers: {
    setMapSettings: (
      state,
      action: PayloadAction<Partial<MapSettingsOptionsDef>>
    ) => {
      state.mapSettings = _merge(state.mapSettings, action.payload);
    },
    resetMapSettings: state => {
      state.mapSettings = initialState.mapSettings;
    },
  },
});

export const {
  setMapSettings,
  resetMapSettings,
} = mapSettingsOptionsSlice.actions;

export default mapSettingsOptionsSlice.reducer;
