import { RouteItemDef } from "@app/types/route.types";

import { ReportingPathsEnum } from "../constants/reporting.paths";
// import AuditLogReportScreen from "../screens/AuditLogReportScreen/AuditLogReportScreen";
import ChassisCheckReportScreen from "../screens/ChassisCheckReportScreen/ChassisCheckReportScreen";
import GraphReportScreen from "../screens/GraphReportScreen/GraphReportScreen";
import MessageReportScreen from "../screens/MessageReportScreen/MessageReportScreen";
import PositionReportScreen from "../screens/PositionReportScreen/PositionReportScreen";
// import SessionReportScreen from "../screens/SessionReportScreen/SessionReportScreen";
import ReportingRoutes from "./ReportingRoutes";

const REPORTING_SCREEN: RouteItemDef = {
  id: "reporting",
  path: ReportingPathsEnum.REPORTING,
  navigationTitle: "reporting.navigationTitle",
  component: ReportingRoutes,
  nestedRoutes: [
    {
      id: "report-management",
      groupTitle: "reporting.navigationTitle",
      nestedRoutes: [
        {
          id: "position-report",
          path: ReportingPathsEnum.POSITIONS,
          navigationTitle: "reportingPosition.navigationTitle",
          component: PositionReportScreen,
        },
        // TODO: ANCO-1774: Hide "Session & Login" and "Auditlog" until we use them.
        // {
        //   id: "session-and-login-report",
        //   path: ReportingPathsEnum.SESSIONS,
        //   navigationTitle: "reporting.sessionReportingNavigationTitle",
        //   component: SessionReportScreen,
        // },
        {
          id: "messages-report",
          path: ReportingPathsEnum.MESSAGES,
          navigationTitle: "reporting.messageReportingNavigationTitle",
          component: MessageReportScreen,
        },
        {
          id: "chassis-check-report",
          path: ReportingPathsEnum.CHASSIS_CHECK,
          navigationTitle: "reportingChassisCheck.navigationTitle",
          component: ChassisCheckReportScreen,
        },
        // {
        //   id: "audit-log-chassis-vehicles",
        //   path: ReportingPathsEnum.AUDIT_LOG,
        //   navigationTitle: "reportingAuditLog.navigationTitle",
        //   component: AuditLogReportScreen,
        // },
        {
          id: "graph-report",
          path: ReportingPathsEnum.GRAPH,
          navigationTitle: "reportingGraph.navigationTitle",
          component: GraphReportScreen,
        },
      ],
    },
  ],
};

export const REPORTING_ROUTES = [REPORTING_SCREEN];
