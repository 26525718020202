import { memo, useState } from "react";

import { PaperClipOutlined } from "@ant-design/icons";
import { Upload, Button, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";

import { getFileGrantPermissions } from "@app/helpers/util.helpers";
import { useAppDispatch } from "@app/redux/store";

import {
  requestUploadGrant,
  uploadFile,
} from "../../../../../file-upload/redux/file-upload.slice";
import { FileTypeDef } from "../../../../../file-upload/types/file-upload.types";

interface UploadAttachmentProps {
  vehiclesNo: string[];
  uploadSuccess: (fileUri: string, file: FileTypeDef) => void;
  disabled: boolean;
  isUploading?: boolean;
}

const UploadAttachment = memo(
  ({
    vehiclesNo,
    uploadSuccess,
    disabled,
    isUploading,
  }: UploadAttachmentProps) => {
    const [isUploadingC, setIsUploading] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const uploadSelectedFile = async (
      fileUploadUri: string,
      fileUri: string,
      file: FileTypeDef
    ) => {
      const response = await dispatch(
        uploadFile({
          fileUploadUri,
          file,
        })
      );
      if (uploadFile.fulfilled.match(response)) {
        setIsUploading(false);
        uploadSuccess(fileUri, file);
        AlertMessage.success(
          t("fleetManagerMessages.fileUploadSuccessMessage")
        );
      }
      if (uploadFile.rejected.match(response)) {
        setIsUploading(false);
        AlertMessage.error(
          (response.payload as string) ??
            t("fleetManagerMessages.fileUploadErrorMessage")
        );
      }
    };

    const getUploadGrant = async (file: FileTypeDef) => {
      setIsUploading(true);

      const response = await dispatch(
        requestUploadGrant({
          name: file.name,
          permissions: getFileGrantPermissions(vehiclesNo),
        })
      );
      if (requestUploadGrant.fulfilled.match(response)) {
        uploadSelectedFile(
          response.payload?.upload_parts[0].uri,
          response.payload?.file_uri,
          file
        );
      }
      if (requestUploadGrant.rejected.match(response)) {
        setIsUploading(false);
        AlertMessage.error(
          (response.payload as string) ??
            t("fleetManagerMessages.fileUploadErrorMessage")
        );
      }
    };

    const beforeUpload = (file: FileTypeDef) => {
      getUploadGrant(file);
      return false; // For manual upload
    };

    return (
      <Upload showUploadList={false} beforeUpload={beforeUpload}>
        <Button
          shape="circle"
          size="large"
          disabled={disabled || isUploadingC || isUploading}
          loading={isUploadingC || isUploading}
          icon={<PaperClipOutlined />}
        />
      </Upload>
    );
  }
);

export default UploadAttachment;
