/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo } from "react";

import cx from "classnames";
import { useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { VehiclePositionDef } from "../../../../types/reporting-position.types";
import styles from "./PositionMarker.module.scss";

interface PositionMarkerProps {
  position: VehiclePositionDef;
  selected: boolean;
  onClickMarker: (position: VehiclePositionDef) => void;
}

const PositionMarker = memo(
  ({ position, selected, onClickMarker }: PositionMarkerProps) => {
    const { positionMapSettings } = useSelector(
      (state: RootState) => state.positionMapSettingsOptions
    );

    /**
     * Handle marker click
     */
    const handleClickMarker = () => {
      onClickMarker(position);
    };

    return (
      <>
        <div
          className={cx(styles.marker, {
            [styles.markerSelected]: selected,
          })}
          onClick={handleClickMarker}
        >
          {positionMapSettings.advancedSettings?.pointsOnMap && (
            <div className={styles.markerContainer}>
              <div className={styles.markerStopCircle} />
            </div>
          )}
        </div>
      </>
    );
  }
);

export default PositionMarker;
