/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { Table, message as AlertMessage } from "antd";
import _camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import TableView from "@app/components/molecules/TableView/TableView";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import { formattedArray } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import {
  getVehicleGroups,
  getVehicles,
  deleteVehicleGroup,
} from "../../redux/vehicle-groups-list.slice";
import {
  getDepartments,
  getPlanSubDepartments,
  getVehicleResponsibles,
} from "../../redux/vehicles.slice";
import {
  DepartmentDef,
  PlanSubDepartmentDef,
  ResponsibleDef,
  VehicleGroupDef,
} from "../../types/vehicle-group.types";
import { VehicleDef } from "../../types/vehicle.types";
import VehicleGroupModal from "./components/VehicleGroupModal/VehicleGroupModal";

const VehicleGroupsScreen = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [allVehicles, setAllVehicles] = useState<VehicleDef["vehicle_no"][]>(
    []
  );

  const { vehicleGroups, loadingVehicleGroups } = useSelector(
    (state: RootState) => state.vehicleGroupsList
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { search, updateSearchParams } = useSearchParams();

  useEffect(() => {
    if (search.searchBy && search.page) {
      dispatch(
        getVehicleGroups({
          page:
            search.searchBy && search.page > 1 ? search?.page : DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          name: search?.searchBy?.toString(),
        })
      );
    }
    if (search.searchBy && !search.page) {
      dispatch(
        getVehicleGroups({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          name: search?.searchBy?.toString(),
        })
      );
    }
    if (!search.searchBy && search.page) {
      dispatch(
        getVehicleGroups({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          name: search?.searchBy?.toString(),
        })
      );
    }

    if (!search.searchBy && !search.page) {
      dispatch(
        getVehicleGroups({
          page: DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          name: search?.searchBy?.toString(),
        })
      );
    }
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.searchBy,
    triggerRefetch,
  ]);

  useMount(() => {
    dispatch(getDepartments({ pageSize: MAX_PAGE_SIZE }));
    dispatch(getPlanSubDepartments({ pageSize: MAX_PAGE_SIZE }));
    dispatch(getVehicleResponsibles({ pageSize: MAX_PAGE_SIZE }));
  });

  useMount(() => {
    const getAllVehicles = async () => {
      const response = await dispatch(
        getVehicles({
          pageSize: MAX_PAGE_SIZE,
          hideInavtive: true,
        })
      );
      if (getVehicles.fulfilled.match(response)) {
        const vehicleNos = response.payload.data?.map(
          (vehicle: VehicleDef) => vehicle.vehicle_no
        );
        setAllVehicles(vehicleNos);
      }
      if (getVehicles.rejected.match(response)) {
        setAllVehicles([]);
      }
    };
    getAllVehicles();
  });

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (group: VehicleGroupDef) => {
    updateSearchParams(modalAction.edit(group.id.toString()));
  };

  const handleDelete = async (vehicleGroup: VehicleGroupDef) => {
    const response = await dispatch(deleteVehicleGroup(vehicleGroup.id));
    if (deleteVehicleGroup.fulfilled.match(response)) {
      AlertMessage.success(t("settingsVehicleGroups.deleteSuccessMessage"));
      setTriggerRefetch(!triggerRefetch);
    }
    if (deleteVehicleGroup.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef | undefined) => {
    updateSearchParams(modalAction.close());
    action?.triggerRefetch && setTriggerRefetch(!triggerRefetch);
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsVehicleGroups.title")}
        filters={
          <SearchFilters
            placeholder={t("settingsVehicleGroups.searchPlaceholder")}
          />
        }
        actions={
          <Button type="primary" size="large" onClick={handleAdd}>
            {t("settingsVehicleGroups.buttonNewVehicleGroup")}
          </Button>
        }
      />

      <TableView
        dataSource={vehicleGroups.data}
        rowKey="id"
        actionTitle={t("default.columnAction")}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loadingVehicleGroups}
        pagination={{ ...vehicleGroups.pagination }}
      >
        <Table.Column
          key="name"
          dataIndex="name"
          title={t("settingsVehicleGroups.columnName")}
        />
        <Table.Column
          key="departmentId"
          dataIndex="departments"
          title={t("settingsVehicles.columnDepartment")}
          render={(departments: DepartmentDef[]) => {
            return formattedArray(departments, "code");
          }}
        />
        <Table.Column
          key="planDepartmentId"
          dataIndex="plan_departments"
          title={t("settingsVehicles.columnPlanDept")}
          render={(planDepartments: DepartmentDef[]) =>
            formattedArray(planDepartments, "code")
          }
        />
        <Table.Column
          key="planSubdepartmentCode"
          dataIndex="plan_subdepartments"
          title={t("settingsVehicles.columnPlanSubDept")}
          render={(planSubdepartments: PlanSubDepartmentDef[]) =>
            formattedArray(planSubdepartments, "code")
          }
        />
        <Table.Column
          key="responsibleCode"
          dataIndex="responsible"
          title={t("settingsVehicles.columnResponsible")}
          render={(responsible: ResponsibleDef[]) =>
            formattedArray(responsible, "code")
          }
        />
        <Table.Column
          key="vehicles"
          dataIndex="number_of_vehicles"
          title={t("settingsVehicleGroups.columnNoOfVehicles")}
          render={(number_of_vehicles: any) => {
            return number_of_vehicles;
          }}
        />
        <Table.Column
          key="type"
          dataIndex="type"
          title={t("settingsVehicleGroups.columnType")}
          render={type =>
            t(
              `settingsVehicleGroups.${_camelCase(
                `${type === 1 ? "dynamic" : "static"}Type`
              )}`
            )
          }
        />
      </TableView>
      <VehicleGroupModal
        allVehicles={allVehicles}
        onClose={action => handleCloseModal(action)}
      />
    </ContentLayout>
  );
};

export default VehicleGroupsScreen;
