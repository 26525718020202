import { memo, useEffect, useRef, useState } from "react";

import { Modal, Input } from "antd";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMount, useUnmount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import { FleetManagerPathsEnum } from "@app/features/fleet-manager/fleet-manager";
import { RootState } from "@app/redux/root-reducer";

import {
  filterVehicleGroupsByVehicleName,
  getSelectedVehicles,
  handleSelection,
  handleRemoveVehicle,
  filterVehiclesFromGroups,
  getSelectedRows,
} from "../../../../helpers/fleet-manager.helpers";
import {
  getVehicleGroupsWithVehicles,
  resetSelectedVehicles,
  setSelectedVehicles,
} from "../../../../redux/vehicle-groups.slice";
import {
  // clearVehiclePositions,
  getVehiclesPositions,
} from "../../../../redux/vehicles-positions.slice";
import {
  SimplifiedVehicleGroupDef,
  SimplifiedVehicleDef,
} from "../../../../types/vehicle-group.types";
import { VehiclePositionFilterDef } from "../../../../types/vehicle-position.types";
import SelectedVehicles from "../SelectedVehicles/SelectedVehicles";
import VehicleGroupList from "../VehicleGroupList/VehicleGroupList";
import styles from "./NewMessageModal.module.scss";

const { Search } = Input;
interface NewMessageModalProps {
  visible: boolean;
  onCloseModal: () => void;
}

const NewMessageModal = memo(
  ({ visible, onCloseModal }: NewMessageModalProps) => {
    const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<
      SimplifiedVehicleGroupDef[]
    >([]);
    const cachedSelectedRowsRef = useRef<any>();
    const [cachedSelectedRowKeys, setCachedSelectedRowKeys] = useState<any[]>(
      []
    );

    const [searchValue, setSearchValue] = useState("");
    const [searching, setSearching] = useState(false);
    const [
      positionFilterParams,
      setPositionFilterParams,
    ] = useState<VehiclePositionFilterDef>({});

    const vehicleGroups = useSelector(
      (state: RootState) => state.vehicleGroups.vehicleGroups
    );
    const loadingVehicleGroups = useSelector(
      (state: RootState) => state.vehicleGroups.loadingVehicleGroups
    );
    const selectedVehicles = useSelector(
      (state: RootState) => state.vehicleGroups.selectedVehicles
    );
    const userId = useSelector((state: RootState) => state.auth.user?.id);
    const { vehiclePositions } = useSelector(
      (state: RootState) => state.vehiclesPositions
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useMount(() => {
      const filters: { page?: number; pageSize?: number } = {
        page: 1,
        pageSize: 1000,
      };
      dispatch(getVehicleGroupsWithVehicles({ userId, filters }));
    });
    useUnmount(() => {
      setPositionFilterParams({});
    });

    /**
     * Effects on position filter params
     */
    useEffect(() => {
      if (!_isEmpty(positionFilterParams)) {
        dispatch(getVehiclesPositions(positionFilterParams));
      }
    }, [positionFilterParams, dispatch]);

    useEffect(() => {
      const vehicles = filterVehiclesFromGroups(vehicleGroups.data);
      if (vehicles.length) {
        const params = { vehicleNos: vehicles.toString() };
        setPositionFilterParams(params);
      }

      // // If the selected vehicles exists but the list is empty
      // if (!selectedVehicles?.length) {
      //   setPositionFilterParams({});
      //   dispatch(clearVehiclePositions());
      // }
    }, [vehicleGroups.data, dispatch]);

    useEffect(() => {
      if (!visible && !selectedVehicles.length) {
        setSearchValue("");
        cachedSelectedRowsRef.current = [];
        setCachedSelectedRowKeys([]);
        setSearching(false);
      }
    }, [visible, selectedVehicles.length]);

    useEffect(() => {
      visible && setFilteredVehicleGroups(vehicleGroups.data);
    }, [visible, vehicleGroups.data]);

    /**
     * Dispatch set selected vehicles on change selected row keys
     */
    useEffect(() => {
      const vehiclesDefault = getSelectedVehicles(
        cachedSelectedRowKeys,
        vehicleGroups.data
      );
      dispatch(setSelectedVehicles(vehiclesDefault));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cachedSelectedRowKeys, filteredVehicleGroups, dispatch]);

    const handleSearch = (value: string) => {
      const filteredGroups: SimplifiedVehicleGroupDef[] = filterVehicleGroupsByVehicleName(
        vehicleGroups.data,
        value.trim()
      );
      setFilteredVehicleGroups(filteredGroups);
      setSearching(true);
    };

    const handleRowSelection = (
      record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
      selected: boolean,
      selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[]
    ) => {
      const selectedAllRows = getSelectedRows(
        record,
        selected,
        selectedRows,
        vehicleGroups.data
      );
      if (searching) {
        const partialSelectedRowKeys = handleSelection(
          record,
          selected,
          selectedRows,
          vehicleGroups.data
        );
        const leftOutRows = cachedSelectedRowsRef.current?.filter(
          (row: any) => partialSelectedRowKeys.indexOf(row.key) < 0
        );
        cachedSelectedRowsRef.current = [...leftOutRows, ...selectedAllRows];
      } else {
        cachedSelectedRowsRef.current = selectedAllRows;
      }
      setCachedSelectedRowKeys(
        handleSelection(
          record,
          selected,
          cachedSelectedRowsRef.current,
          filteredVehicleGroups
        )
      );
    };

    const handleRemove = (record: SimplifiedVehicleDef) => {
      setCachedSelectedRowKeys(previousSelectedKeys =>
        handleRemoveVehicle(record, previousSelectedKeys, vehicleGroups.data)
      );
    };

    const handleReset = () => {
      setCachedSelectedRowKeys([]);
      cachedSelectedRowsRef.current = [];
      dispatch(resetSelectedVehicles());
    };

    return (
      <Modal
        title={t("fleetManagerMessages.messageModalTitle")}
        visible={visible}
        onCancel={onCloseModal}
        destroyOnClose
        centered
        footer={[
          <Button onClick={onCloseModal}>{t("default.cancelTitle")}</Button>,
          <Button
            className={styles.composeMessageBtn}
            type="primary"
            disabled={selectedVehicles.length === 0}
            to={FleetManagerPathsEnum.NEW_MESSAGE_COMPOSE}
            onClick={() => {
              setSearching(false);
              setSearchValue("");
            }}
          >
            {t("fleetManagerMessages.buttonComposeMessage")}
          </Button>,
        ]}
      >
        <SelectedVehicles
          selectedVehicles={selectedVehicles}
          resetBtnText={t("default.resetTitle")}
          backBtnText={t("default.editTitle")}
          onRemove={handleRemove}
          onReset={handleReset}
        />
        <Search
          placeholder={t("default.searchPlaceholder")}
          size="large"
          allowClear
          value={searchValue}
          style={{ width: 320 }}
          onChange={e => {
            if (!e.target.value.trim().length) {
              setSearching(false);
            }
            setSearchValue(e.target.value.trim());
          }}
          onSearch={handleSearch}
        />
        <VehicleGroupList
          vehiclePositions={vehiclePositions.vehicles}
          items={filteredVehicleGroups}
          loading={loadingVehicleGroups}
          selectedRowKeys={cachedSelectedRowKeys}
          onRowSelection={handleRowSelection}
          checkStrictSelection
        />
        {/* )} */}
      </Modal>
    );
  }
);

export default NewMessageModal;
