import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { getPositionReport } from "../../redux/position-report.slice";
import { PositionReportFilterDef } from "../../types/reporting.types";
import Chart from "./components/Chart/Chart";
import GraphFilters from "./components/GraphFilters/GraphFilters";

export default function GraphReportScreen() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const reports = useSelector(
    (state: RootState) => state.positionReport.report
  );

  const {
    search,
  }: {
    search: PositionReportFilterDef;
  } = useSearchParams();

  useEffect(() => {
    if (search?.vehicles || search?.drivers) {
      dispatch(
        getPositionReport({
          vehicleNo: search?.vehicles ?? undefined,
          drivers: search?.drivers?.toString() ?? undefined,
          from: search?.from ?? undefined,
          to: search?.to ?? undefined,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(search)]);
  return (
    <ContentLayout>
      <ScreenTitle
        title={t("reportingGraph.positionGraphTitle")}
        subTitle={t("reportingGraph.positionGraphSubtitle")}
        filters={<GraphFilters />}
      />
      <Chart streaks={reports.data?.streaks} />
    </ContentLayout>
  );
}
