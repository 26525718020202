/* eslint-disable camelcase */
import { useEffect, useState } from "react";

import { Table, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import TableView from "@app/components/molecules/TableView/TableView";
import { DateFormats } from "@app/constants/date.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { TimeStampDef } from "../../../reporting/types/reporting.types";
import {
  getVehicles,
  getDepartments,
  getPlanSubDepartments,
  getVehicleResponsibles,
  deleteVehicle,
} from "../../redux/vehicles.slice";
import { VehicleDef } from "../../types/vehicle.types";
import styles from "./VehiclesScreen.module.scss";
import VehiclesModal from "./components/VehiclesModal/VehiclesModal";

const VehiclesScreen = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const vehicles = useSelector((state: RootState) => state.vehicles.vehicles);
  const loadingVehicles = useSelector(
    (state: RootState) => state.vehicles.loadingVehicles
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { search, updateSearchParams } = useSearchParams();

  useMount(() => {
    dispatch(getDepartments({ pageSize: MAX_PAGE_SIZE }));
    dispatch(getPlanSubDepartments({ pageSize: MAX_PAGE_SIZE }));
    dispatch(getVehicleResponsibles({ pageSize: MAX_PAGE_SIZE }));
  });

  useEffect(() => {
    if (search.searchBy && search.page) {
      dispatch(
        getVehicles({
          page:
            search.searchBy && search.page > 1 ? search?.page : DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          vehicleNo: search?.searchBy?.toString(),
          hideInavtive: true,
        })
      );
    }
    if (search.searchBy && !search.page) {
      dispatch(
        getVehicles({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          vehicleNo: search?.searchBy?.toString(),
          hideInavtive: true,
        })
      );
    }
    if (!search.searchBy && search.page) {
      dispatch(
        getVehicles({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          vehicleNo: search?.searchBy?.toString(),
          hideInavtive: true,
        })
      );
    }

    if (!search.searchBy && !search.page) {
      dispatch(
        getVehicles({
          page: DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          vehicleNo: search?.searchBy?.toString(),
          hideInavtive: true,
        })
      );
    }
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.searchBy,
    triggerRefetch,
  ]);

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (vehicle: VehicleDef) => {
    updateSearchParams(modalAction.edit(vehicle.vehicle_no.toString()));
  };

  const handleDelete = async (vehicle: VehicleDef) => {
    const response = await dispatch(deleteVehicle(vehicle.vehicle_no));
    if (deleteVehicle.fulfilled.match(response)) {
      AlertMessage.success(t("settingsVehicles.deleteSuccessMessage"));
      setTriggerRefetch(!triggerRefetch);
    }
    if (deleteVehicle.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef) => {
    updateSearchParams(modalAction.close());
    if (action.triggerRefetch) {
      setTriggerRefetch(!triggerRefetch);
    }
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsVehicles.title")}
        filters={
          <SearchFilters
            placeholder={t("settingsVehicles.searchVehiclePlaceholder")}
            className={styles.searchFilter}
          />
        }
        actions={
          <Button type="primary" size="large" onClick={handleAdd}>
            {t("settingsVehicles.buttonAddVehicle")}
          </Button>
        }
      />

      <TableView
        dataSource={vehicles.data}
        rowKey="vehicleNo"
        actionTitle={t("default.columnAction")}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loadingVehicles}
        pagination={{ ...vehicles.pagination }}
      >
        <Table.Column
          dataIndex="vehicle_no"
          title={t("settingsVehicles.columnVehicleNo")}
        />
        <Table.Column
          key="departmentId"
          dataIndex={["department", "code"]}
          title={t("settingsVehicles.columnDepartment")}
        />
        <Table.Column
          key="plan_department"
          dataIndex={["plan_department", "code"]}
          title={t("settingsVehicles.columnPlanDept")}
        />
        <Table.Column
          key="plan_subdepartment"
          dataIndex={["plan_subdepartment", "code"]}
          title={t("settingsVehicles.columnPlanSubDept")}
        />
        <Table.Column
          key="responsibleCode"
          dataIndex={["responsible", "code"]}
          title={t("settingsVehicles.columnResponsible")}
        />
        <Table.Column
          key="no_of_axles"
          dataIndex="no_of_axles"
          title={t("settingsVehicles.columnNumberAxels")}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsVehicles.columnLastModifiedBy")}
          render={(last_modified: TimeStampDef) => {
            return (
              last_modified &&
              `${last_modified.user_name}, ${formattedDateTime(
                last_modified.time,
                DateFormats.DAY_MONTH_YEAR
              )}`
            );
          }}
        />
      </TableView>

      <VehiclesModal onClose={action => handleCloseModal(action)} />
    </ContentLayout>
  );
};

export default VehiclesScreen;
