import { memo } from "react";

import { AddressDef } from "../../../../types/vehicle-position.types";

interface VehicleRouteAddressProps {
  address: AddressDef;
}

const VehicleRouteAddress = memo(({ address }: VehicleRouteAddressProps) => {
  return (
    <>
      {`${address.name}, ${address.street} ${address.house_no}, ${address.zip} ${address.city}, ${address.country.code}`}
    </>
  );
});

export default VehicleRouteAddress;
