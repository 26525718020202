export enum SettingsPathsEnum {
  SETTINGS = "/settings",
  VEHICLES = "/settings/vehicles",
  VEHICLE_GROUPS = "/settings/vehicle-groups",
  CHASSIS = "/settings/chassis",
  DRIVERS = "/settings/drivers",
  USERS = "/settings/users",
  DISPATCHER_PREDEFINED_MESSAGES = "/settings/dispatcher-predefined-messages",
  DRIVER_PREDEFINED_MESSAGES = "/settings/driver-predefined-messages",
  MAP_MESSENGER_CONFIG = "/settings/map-messenger-config",
}
