import { memo } from "react";

import { Tag, TagProps } from "antd";
import _camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";

import { VehicleStatusEnum } from "../../../../constants/vehicle-status.constants";
import styles from "./VehicleStatus.module.scss";

interface VehicleStatusProps {
  status?: VehicleStatusEnum;
  className?: string;
}

/**
 * Get driver status color
 * @param status
 */
const getDriverStatusColor = (status: VehicleStatusEnum) => {
  let color: TagProps["color"];
  switch (status) {
    case VehicleStatusEnum.ARRIVED:
      color = "processing";
      break;
    case VehicleStatusEnum.DRIVING:
      color = "warning";
      break;
    case VehicleStatusEnum.STOPPED:
      color = "error";
      break;
    case VehicleStatusEnum.OFFLINE:
      color = "default";
      break;
    default:
      color = "default";
      break;
  }

  return color;
};

const VehicleStatus = memo(({ status, className }: VehicleStatusProps) => {
  const { t } = useTranslation();
  return status ? (
    <div className={className}>
      <Tag color={getDriverStatusColor(status)} className={styles.tag}>
        {t(`vehicleDriverStatus.${_camelCase(status)}`)}
      </Tag>
    </div>
  ) : null;
});

export default VehicleStatus;
