import { useEffect, useState } from "react";

import { Table, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import TableView from "@app/components/molecules/TableView/TableView";
import { DateFormats } from "@app/constants/date.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { deleteChassis, getChassisList } from "../../redux/chassis.slice";
import { ChassisDef } from "../../types/chassis.types";
import ChassisModal from "./components/ChassisModal/ChassisModal";

const ChassisScreen = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const chassisList = useSelector(
    (state: RootState) => state.chassis.chassisList
  );
  const loadingChassisList = useSelector(
    (state: RootState) => state.chassis.loadingChassis
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { search, updateSearchParams } = useSearchParams();

  useEffect(() => {
    if (search.searchBy && search.page) {
      dispatch(
        getChassisList({
          page:
            search.searchBy && search.page > 1 ? search?.page : DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
        })
      );
    }
    if (search.searchBy && !search.page) {
      dispatch(
        getChassisList({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
        })
      );
    }
    if (!search.searchBy && search.page) {
      dispatch(
        getChassisList({
          page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
        })
      );
    }

    if (!search.searchBy && !search.page) {
      dispatch(
        getChassisList({
          page: DEFAULT_PAGE,
          pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
          q: search?.searchBy?.toString(),
        })
      );
    }
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.searchBy,
    triggerRefetch,
  ]);

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (chassis: ChassisDef) => {
    updateSearchParams(modalAction.edit(chassis.chassis_no.toString()));
  };

  const handleDelete = async (chassis: ChassisDef) => {
    const response = await dispatch(deleteChassis(chassis.chassis_no));
    if (deleteChassis.fulfilled.match(response)) {
      AlertMessage.success(t("settingsChassis.deleteSuccessMessage"));
      setTriggerRefetch(!triggerRefetch);
    }
    if (deleteChassis.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef) => {
    updateSearchParams(modalAction.close());
    if (action.triggerRefetch) {
      setTriggerRefetch(!triggerRefetch);
    }
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsChassis.title")}
        filters={
          <SearchFilters
            placeholder={t("settingsChassis.searchChassisPlaceholder")}
          />
        }
        actions={
          <Button type="primary" size="large" onClick={handleAdd}>
            {t("settingsChassis.buttonAddChassis")}
          </Button>
        }
      />

      <TableView
        dataSource={chassisList.data}
        rowKey="chassis_no"
        actionTitle={t("default.columnAction")}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loadingChassisList}
        pagination={{ ...chassisList.pagination }}
      >
        <Table.Column
          dataIndex="chassis_no"
          title={t("settingsChassis.columnChassisNo")}
        />
        <Table.Column
          key="number_plate"
          dataIndex="number_plate"
          title={t("settingsChassis.columnNumberPlate")}
        />
        <Table.Column
          key="chassis_type"
          dataIndex="chassis_type"
          title={t("settingsChassis.columnChassisType")}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsChassis.columnLastModified")}
          // eslint-disable-next-line camelcase
          render={(last_modified: ChassisDef["last_modified"]) => {
            return (
              last_modified?.time &&
              formattedDateTime(last_modified?.time, DateFormats.DAY_MONTH_YEAR)
            );
          }}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsChassis.columnModifiedBy")}
          // eslint-disable-next-line camelcase
          render={(last_modified: ChassisDef["last_modified"]) => {
            return last_modified?.user_name?.split("@")[0];
          }}
        />
      </TableView>

      <ChassisModal onClose={action => handleCloseModal(action)} />
    </ContentLayout>
  );
};

export default ChassisScreen;
