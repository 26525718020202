import { format, set } from "date-fns";
import { utc } from "moment";

import {
  DateTimeFormats,
  DateFormats,
  TimeFormats,
} from "@app/constants/date.constants";
import { EXPORTED_CONTENT_TYPE } from "@app/constants/file.constants";
import { DEFAULT_PERMISSIONS } from "@app/constants/upload-grant.constants";
import {
  ResponsePaginationDef,
  PaginationDef,
} from "@app/types/pagination.types";

export const formattedDateTime = (
  value: string,
  displayFormat: DateTimeFormats | DateFormats | TimeFormats
): string => {
  const parsedTime = utc(value).local().toDate();

  return value && format(parsedTime, displayFormat);
};

export const resetAndFormatToISO = (date: string | number | Date) => {
  return format(
    set(new Date(date), { seconds: 0, milliseconds: 0 }),
    DateFormats.ISO
  );
};

export const mapPagination = (
  pagination: ResponsePaginationDef
): PaginationDef => {
  return {
    current_page: pagination?.current_page ?? 1,
    per_page: pagination?.per_page ?? 10,
    total: pagination?.total ?? 0,
    total_pages: pagination?.total_pages ?? 0,
  };
};

export const formattedArray = (
  items: { [key: string]: string | number | unknown }[],
  key: string
) => {
  if (items?.length) {
    const values = items.map(item => item[key]);
    return values.join(", ");
  }
  return "-";
};

/** Convert blob file to given file type */
export const processBlobToFile = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blobData: any,
  fileName: string,
  contentType: EXPORTED_CONTENT_TYPE
) => {
  const blob = new Blob([blobData], { type: contentType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
};

/** Convert blob file to given file type */
export const processBlobToFileForPdf = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blobData: any,
  fileName: string,
  contentType: EXPORTED_CONTENT_TYPE,
  setPdfLoading: any
) => {
  const blob = new Blob([blobData], { type: contentType });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  setPdfLoading(false);
  window.open(link.href, "_blank");
};

/**
 * Get document file grant permissions
 * @param vehiclesNo
 * @returns
 */
export const getFileGrantPermissions = (vehiclesNo: string[]) => {
  const permissions = [...DEFAULT_PERMISSIONS];
  vehiclesNo.forEach(vehicleNo => {
    permissions.push({
      grantee: `anco:fleet:vehicle:${vehicleNo}`,
      permission: "READ",
    });
  });
  return permissions;
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};
