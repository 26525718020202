export enum Languages {
  ENGLISH = "en",
  GERMAN = "de",
  POLISH = "pl",
  ROMANIAN = "ro",
  DANISH = "da",
  SWEDISH = "sv",
  DUTCH = "nl",
  RUSSIAN = "ru",
  BULGARIAN = "bg",
}

export const LanguageCodes: { [key: string]: string } = {
  en: "English",
  de: "German",
  pl: "Polish",
  ro: "Romanian",
  da: "Danish",
  sv: "Swedish",
  nl: "Dutch",
  ru: "Russian",
  bg: "Bulgarian",
};
