/* eslint-disable no-restricted-properties */
import { memo, useEffect, useState } from "react";

import { MessageOutlined } from "@ant-design/icons";
import { Menu, Badge, Avatar, Dropdown, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useInterval, useMount } from "react-use";

import { useValidateAccess } from "@app/features/auth/auth";
import { getInitials } from "@app/helpers/user.helper";
import { RootState } from "@app/redux/root-reducer";

import { MessageFetchTime } from "../../../../../constants/message.constants";
import { FleetManagerPathsEnum } from "../../../../../features/fleet-manager/constants/fleet-manager.paths";
import {
  getUnreadMessagesCount,
  setServerStatusUnavailable,
} from "../../../../../features/fleet-manager/redux/messages.slice";
import styles from "./NavRightContent.module.scss";

const NavRightContent = memo(() => {
  const [retriedTimes, setRetriedTimes] = useState(0);
  const [errorEncountered, setErrorEncountered] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const unreadMessagesCount = useSelector(
    (state: RootState) => state.messages.unreadMessagesCount
  );

  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<any>();
  const { logout } = useValidateAccess();

  useMount(() => {
    dispatch(getUnreadMessagesCount());
  });

  // API polling exponential back off interval
  // if server returns error if not then call normally with interval
  useInterval(
    () => {
      if (retriedTimes <= MessageFetchTime.MAX_RETRY_TIMES) {
        dispatch(getUnreadMessagesCount())
          .unwrap()
          .then(() => {
            setErrorEncountered(false);
            setRetriedTimes(0);
            dispatch(setServerStatusUnavailable(false));
          })
          .catch(() => {
            setErrorEncountered(true);
          });
      }
      setRetriedTimes(retriedTimes + 1);
    },
    errorEncountered && retriedTimes <= MessageFetchTime.MAX_RETRY_TIMES
      ? MessageFetchTime.INTERVAL * Math.pow(2, retriedTimes)
      : MessageFetchTime.INTERVAL
  );

  // Display Error modal if Server is unavailable after 5 consecutive retries
  useEffect(() => {
    if (
      retriedTimes === MessageFetchTime.MAX_RETRY_TIMES + 1 &&
      errorEncountered
    ) {
      Modal.error({
        title: t("default.serverErrorTitle"),
        content: t("default.serverErrorMessage"),
        centered: true,
      });
      dispatch(setServerStatusUnavailable(true));
    }
  }, [errorEncountered, retriedTimes, dispatch, t]);

  const handleLogout = () => {
    logout();
  };

  const userInitials = getInitials(`${user?.first_name} ${user?.last_name}`, 3);

  const userMenu = (
    <Menu className={styles.userMenu}>
      <Menu.Item className={styles.userMenuItem} onClick={handleLogout}>
        {t("default.logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Menu
        className={styles.navMenu}
        mode="horizontal"
        selectedKeys={[location.pathname]}
      >
        <Menu.Item
          key={FleetManagerPathsEnum.MESSAGES}
          className={styles.menuItem}
        >
          <Link to={FleetManagerPathsEnum.MESSAGES}>
            <Badge count={unreadMessagesCount} className={styles.badge}>
              <MessageOutlined className={styles.messageIcon} />
            </Badge>
          </Link>
        </Menu.Item>
        <Menu.Item key="2" className={styles.menuItem}>
          <Dropdown
            overlay={userMenu}
            trigger={["click"]}
            placement="bottomCenter"
          >
            <Avatar size={40} className={styles.avatar}>
              {userInitials}
            </Avatar>
          </Dropdown>
        </Menu.Item>
      </Menu>
    </>
  );
});

export default NavRightContent;
