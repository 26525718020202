import { Form, Input, Select } from "antd";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useMount } from "react-use";

import DatePicker from "@app/components/atoms/DatePicker/DatePicker";
import { DateFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";

import { getChassisRepairStatusOptions } from "../../../../helpers/reporting.helpers";
import styles from "./ChassisCheckFilters.module.scss";

interface ChassisCheckFilterFormDef {
  vehicles: string;
  vehicleGroups: string;
  users: string;
  chassisNos: string;
  from: string;
  to: string;
  damageCategories: string[];
  status: string;
  damagesReported: boolean;
}

const { Option } = Select;

const ChassisCheckFilters = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { t } = useTranslation();
  const { setSearchParams } = useSearchParams();

  const chassisCheckCategories = useSelector(
    (state: RootState) => state.chassisCheckReport.chassisCheckCategories
  );

  const drivers = useSelector((state: RootState) => state.users.drivers.data);

  const vehicleGroupsList = useSelector(
    (state: RootState) => state.chassisCheckReport.vehicleGroupsList
  );

  const onFinish = (values: ChassisCheckFilterFormDef) => {
    values.to = formattedDateTime(values.to, DateFormats.YEAR_MONTH_DAY) ?? "";
    values.from =
      formattedDateTime(values.from, DateFormats.YEAR_MONTH_DAY) ?? "";

    setSearchParams(values as ChassisCheckFilterFormDef);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const setFilterValue = (values?: ChassisCheckFilterFormDef) => {
    form.setFieldsValue({
      vehicles: values?.vehicles?.toString() ?? "",
      vehicleGroup: values?.vehicleGroups?.toString() ?? "",
      users: values?.users?.toString() ?? undefined,
      chassisNos: values?.chassisNos?.toString() ?? "",
      from: values?.from ? new Date(values?.from) : "",
      to: values?.to ? new Date(values?.to) : "",
      damageCategories: values?.damageCategories ?? [],
      status: values?.status ?? undefined,
      damagesReported: values?.damagesReported ?? undefined,
    });
  };

  useMount(() => {
    const queryParams = qs.parse(location.search);
    if (queryParams && Object.keys(queryParams).length !== 0) {
      setFilterValue(queryParams as never);
    } else {
      setFilterValue();
    }
  });

  /**
   * Handle export report
   */

  return (
    <div className={styles.chassisCheckFilterWrapper}>
      <Form
        onFinish={onFinish}
        className={styles.chassisCheckFilterForm}
        layout="inline"
        form={form}
      >
        <Form.Item name="vehicles" label={t("reporting.labelVehicleNo")}>
          <Input
            size="middle"
            onPressEnter={handleFormSubmit}
            onBlur={handleFormSubmit}
            placeholder={t("reporting.placeholderVehicleNo")}
          />
        </Form.Item>
        <Form.Item
          name="vehicleGroups"
          label={t("reportingChassisCheck.labelVehicleGroup")}
        >
          <Select
            showSearch
            className={styles.multipleSelection}
            optionFilterProp="children"
            onChange={handleFormSubmit}
            allowClear
            placeholder={t("reportingChassisCheck.placeholderVehicleGroup")}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {vehicleGroupsList?.map(vehicleGroup => (
              <Option key={vehicleGroup.id} value={vehicleGroup.id?.toString()}>
                {vehicleGroup.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="users" label={t("reporting.labelDriver")}>
          {/* <Input
            size="middle"
            onPressEnter={handleFormSubmit}
            onBlur={handleFormSubmit}
            placeholder={t("reporting.placeholderDriver")}
          /> */}
          <Select
            showSearch
            className={styles.multipleSelection}
            optionFilterProp="children"
            onChange={handleFormSubmit}
            allowClear
            placeholder={t("reporting.placeholderDriver")}
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {drivers?.map((driver: any) => (
              <Option key={driver.id} value={driver.id?.toString()}>
                {`${driver.first_name} ${driver.last_name}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="chassisNos"
          label={t("reportingChassisCheck.labelChassisNo")}
        >
          <Input
            size="middle"
            onPressEnter={handleFormSubmit}
            onBlur={handleFormSubmit}
            placeholder={t("reportingChassisCheck.placeholderChassisNo")}
          />
        </Form.Item>
        <Form.Item name="from" label={t("reporting.labelFromDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderFromDate")}
          />
        </Form.Item>
        <Form.Item name="to" label={t("reporting.labelToDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderToDate")}
          />
        </Form.Item>
        <Form.Item
          name="damageCategories"
          label={t("reportingChassisCheck.labelDamages")}
          rules={[{ type: "array" }]}
          className={styles.multipleSelection}
        >
          <Select
            onChange={handleFormSubmit}
            mode="multiple"
            allowClear
            maxTagCount={4}
            placeholder={t("reportingChassisCheck.placeholderDamages")}
          >
            {chassisCheckCategories?.map(category => (
              <Option key={category.id} value={category.id.toString()}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="damagesReported"
          label={t("reportingChassisCheck.labelDamageReport")}
        >
          <Select
            onChange={handleFormSubmit}
            placeholder={t("reportingChassisCheck.placeholderDamageReport")}
            allowClear
          >
            <Option key="1" value="false">
              {t("reportingChassisCheck.damageAllOk")}
            </Option>
            <Option key="2" value="true">
              {t("reportingChassisCheck.damageNotOk")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label={t("reportingChassisCheck.labelRepairStatus")}
          className={styles.multipleSelection}
        >
          <Select
            onChange={handleFormSubmit}
            placeholder={t("reportingChassisCheck.placeholderRepairStatus")}
            allowClear
          >
            {getChassisRepairStatusOptions().map(status => (
              <Option key={status.id} value={status.id}>
                {t(`chassisCheckRepairStatus.status${status.value}`)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {/* <div className={styles.chassisCheckExportContainer}>
        <Button
          loading={loadingExportingData}
          onClick={handleExportReport}
          type="primary"
          size="large"
        >
          {t("reporting.exportReportButtonText")}
        </Button>
      </div> */}
    </div>
  );
};

export default ChassisCheckFilters;
