import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserRole } from "@app/constants/user.constants";
import {
  PermissionEnum,
  setPermissions,
} from "@app/features/permissions/permissions";
import { UserDef } from "@app/types/user.types";

import { authApi } from "../api/auth.api";
import { removeTokenAndRedirect } from "../helpers/auth.helpers";

type AuthState = {
  isAuthenticated: boolean;
  user: UserDef | null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
};

export const getMe = createAsyncThunk("auth/getMe", async () => {
  try {
    const response = await authApi.getMe();
    return response.data;
  } catch (error) {
    return error.response.data as string;
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    clearUser: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      if (
        action.payload.role === UserRole.ADMIN ||
        action.payload.role === UserRole.DISPATCHER
      ) {
        state.user = action.payload;
        action.payload.role === UserRole.ADMIN &&
          setPermissions(Object.values(PermissionEnum));
      } else {
        () => initialState;
        removeTokenAndRedirect();
      }
    });
    builder.addCase(getMe.rejected, () => {
      () => initialState;
      removeTokenAndRedirect();
    });
  },
});

export const { setAuthentication, clearUser } = authSlice.actions;

export default authSlice.reducer;
