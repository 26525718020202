import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { ApiStatusCodes } from "@app/constants/api.constants";
import { ENV } from "@app/constants/env";
import {
  getTokens,
  isTokenValid,
  isTokenExpiring,
} from "@app/features/auth/auth";

// Navigate to middleware to get new access token with return uri
const getNewAccessToken = () => {
  const currentLocation = `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`;
  window.location.replace(
    `${ENV.AUTH_CREDENTIALS.REDIRECT_URI}?return_uri=${currentLocation}`
  );
};

/**
 * Authorization header to API calls
 * @param request
 */
const authInterceptor = (request: AxiosRequestConfig) => {
  const requestConfig = request;

  const accessToken = getTokens();

  if (accessToken && isTokenValid()) {
    // Navigate to middleware to get new access token
    // with return uri when token is nearly expired
    if (isTokenExpiring() && process.env.NODE_ENV !== "development") {
      getNewAccessToken();
    }
    requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  }
  return requestConfig;
};

/**
 * Axios error response from API calls
 * @param axiosError
 */
const errorInterceptor = (axiosError: AxiosError) => {
  if (axiosError?.response) {
    if (axiosError.response.status === ApiStatusCodes.UNAUTHORIZED) {
      const accessToken = getTokens();
      if (
        accessToken &&
        !isTokenValid() &&
        process.env.NODE_ENV !== "development"
      ) {
        getNewAccessToken();
      }
    }
  }
  return Promise.reject(axiosError);
};

/**
 * API instances
 */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: { "Content-Type": "application/json" },
});

export const messengerApi = axios.create({
  baseURL: ENV.API_HOST,
  headers: { "Content-Type": "application/json" },
});

export const devApi = axios.create({
  baseURL: ENV.DEV_API_HOST,
  headers: { "Content-Type": "application/json" },
});

export const documentApi = axios.create({
  baseURL: ENV.DOCUMENT_API_HOST,
  headers: { "Content-Type": "application/json" },
});

/**
 * Add interceptors
 */
api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(res => res, errorInterceptor);
messengerApi.interceptors.request.use(authInterceptor);
messengerApi.interceptors.response.use(res => res, errorInterceptor);
devApi.interceptors.request.use(authInterceptor);
devApi.interceptors.response.use(res => res, errorInterceptor);
documentApi.interceptors.request.use(authInterceptor);
documentApi.interceptors.response.use(res => res, errorInterceptor);
