import jwtDecode, { JwtPayload } from "jwt-decode";
import cookie from "react-cookies";

import { ENV } from "@app/constants/env";
import { clearPermissions } from "@app/features/permissions/permissions";

import { AUTH_TOKEN } from "../constants/auth.keys";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const authCredentials: any = { ...ENV.AUTH_CREDENTIALS };

const COOKIE_OPTIONS = {
  path: "/",
};

/**
 * Save auth tokens in cookie
 */
export const saveTokens = (token: string) => {
  cookie.save(AUTH_TOKEN, token, COOKIE_OPTIONS);
};

/**
 * Loads token from cookie
 */
export const getTokens = () => {
  return cookie.load(AUTH_TOKEN);
};

/**
 * Remove tokens from cookie
 */
export const removeTokens = () => {
  return cookie.remove(AUTH_TOKEN, COOKIE_OPTIONS);
};

/**
 * Check token validity
 */
export const isTokenValid = (): boolean => {
  const decoded = jwtDecode<JwtPayload>(getTokens());
  return Date.now() / 1000 < (decoded.exp ?? 0);
};

/**
 * Check if token is going to expire soon or not
 * Threshold: 60 Sec
 */
export const isTokenExpiring = (): boolean => {
  const decoded = jwtDecode<JwtPayload>(getTokens());
  return decoded.exp ? decoded.exp - Date.now() / 1000 <= 60 : false;
};

/**
 * Generate login url from env file
 */
export const getLoginUrl = () => {
  const queryParams = Object.keys(authCredentials)
    .map(key => `${key.toLowerCase()}=${authCredentials[key]}`)
    .join("&");
  return `${ENV.AUTH_BASE_URL}?${queryParams}`;
};

/**
 * Redirect to desired url
 * @param url
 */
export const redirect = (url: string) => {
  window.location.replace(url);
};

/**
 * Remove tokens and redirect to logout from ancotrans
 */
export const removeTokenAndRedirect = () => {
  removeTokens();
  clearPermissions();
  redirect(
    `${ENV.AUTH_LOGOUT_URL}?referer=${window.location.protocol}//${window.location.host}`
  );
};
