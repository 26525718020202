/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useRef, useState } from "react";

import { SettingOutlined, ToolOutlined } from "@ant-design/icons";
import { Space } from "antd";
import GoogleMapReact, {
  BootstrapURLKeys,
  ChangeEventValue as MapChangeEventValue,
} from "google-map-react";
import MeasureTool from "measuretool-googlemaps-v3";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useSupercluster from "use-supercluster";

import Button from "@app/components/atoms/Button/Button";
import { ENV } from "@app/constants/env";
import { GoogleMapDefaults } from "@app/constants/map.constants";
import useOutsideClick from "@app/hooks/useOutsideClick";
import { RootState } from "@app/redux/root-reducer";

import { resetMapProjectionCenter } from "../../../../redux/vehicles-positions.slice";
import {
  GeoLocationDef,
  MapBoundDef,
  VehiclePositionDef,
} from "../../../../types/vehicle-position.types";
import Cluster from "../Cluster/Cluster";
import MapSettingsPanel from "../MapSettingsPanel/MapSettingsPanel";
import Marker from "../Marker/Marker";
import PlaceSearchBox from "../PlaceSearchBox/PlaceSearchBox";
import styles from "./Map.module.scss";

interface VehiclePositionProps {
  vehicles: VehiclePositionDef[];
  mapBounds: MapBoundDef | undefined;
}

const defaultMapProps = {
  center: {
    lat: GoogleMapDefaults.MAP_CENTER_LAT,
    lng: GoogleMapDefaults.MAP_CENTER_LNG,
  },
  zoom: GoogleMapDefaults.ZOOM,
  clusterRadius: GoogleMapDefaults.CLUSTER_RADIUS,
  clusterMaxZoom: GoogleMapDefaults.CLUSTER_MAX_ZOOM,
};

const bootstrapMapProps: BootstrapURLKeys = {
  key: `${ENV.GOOGLE_MAP_API_KEY}`,
  libraries: ["places", "geometry"],
};

const Map = memo(({ vehicles, mapBounds }: VehiclePositionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { mapSettings } = useSelector(
    (state: RootState) => state.mapSettingsOptions
  );

  const displayClusters =
    mapSettings.mapDisplaySettings.advancedSettings?.displayClusters;

  const [showSettingsPanel, setShowSettingsPanel] = useState(false);
  const [bounds, setBounds] = useState<number[]>();
  const [zoom, setZoom] = useState<number>(defaultMapProps.zoom);
  const [measureStart, setMeasureStart] = useState<boolean>(false);
  const [isMapApiLoaded, setIsMapApiLoaded] = useState<boolean>(false);

  const mapsRef = useRef<any>();
  const mapRef = useRef<any>();
  const trafficRef = useRef<any>();
  const measureToolRef = useRef<any>();
  const mapSettingsPanelRef = useRef<HTMLDivElement>(null);

  const { outsideClicked } = useOutsideClick(mapSettingsPanelRef);

  const points =
    vehicles?.map(vehicle => ({
      properties: { vehicle },
      geometry: {
        coordinates: [vehicle.geo_decimal.lng, vehicle.geo_decimal.lat],
      },
    })) ?? [];

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: defaultMapProps.clusterRadius,
      maxZoom: defaultMapProps.clusterMaxZoom,
    },
  });

  useEffect(() => {
    const display =
      mapSettings.mapDisplaySettings.advancedSettings?.displayTraffic;

    if (mapsRef.current && mapRef.current && trafficRef.current) {
      if (display) {
        trafficRef.current.setMap(mapRef.current);
      } else {
        trafficRef.current.setMap(null);
      }
    }
  }, [
    mapSettings.mapDisplaySettings.advancedSettings?.displayTraffic,
    isMapApiLoaded,
  ]);

  /**
   * When show on map is clicked fitBounds make it
   * centered along with dynamically setting zoom level
   * to display all the vehicles of a group
   */
  useEffect(() => {
    mapBounds && mapRef.current?.fitBounds(mapBounds);
  }, [mapBounds]);

  /**
   * Reset map projection center on map change
   * Set zoom and bounds for cluster
   */
  const handleMapChange = (mapVal: MapChangeEventValue) => {
    setZoom(mapVal.zoom);
    setBounds([
      mapVal.bounds.nw.lng,
      mapVal.bounds.se.lat,
      mapVal.bounds.se.lng,
      mapVal.bounds.nw.lat,
    ]);

    dispatch(resetMapProjectionCenter());
  };

  /**
   * Handle map settings panel display
   */
  const handleMapSettingsPanel = () => {
    if (outsideClicked) {
      setShowSettingsPanel(false);
    } else {
      setShowSettingsPanel(prevShowPanel => !prevShowPanel);
    }
  };

  /**
   * Effects on outside clicked
   */
  useEffect(() => {
    if (outsideClicked) {
      handleMapSettingsPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outsideClicked]);

  /**
   * Handle map api loaded
   * @param map
   * @param maps
   */
  const handleApiLoaded = (map: any, maps: any) => {
    mapsRef.current = maps;
    mapRef.current = map;
    trafficRef.current = new maps.TrafficLayer();
    measureToolRef.current = new MeasureTool(map, { contextMenu: false });
    setIsMapApiLoaded(true);
  };

  /**
   * Handle cluster click
   * @param clusterId
   * @param geoLocations
   */
  const handleClusterClick = (
    clusterId: unknown,
    geoLocations: GeoLocationDef
  ) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(clusterId),
      defaultMapProps.clusterMaxZoom
    );
    mapRef.current.setZoom(expansionZoom);
    mapRef.current.panTo({ lat: geoLocations.lat, lng: geoLocations.lng });
  };

  /**
   * Handle measure tool
   */
  const handleMeasureTool = () => {
    setMeasureStart(!measureStart);
    if (measureStart) {
      measureToolRef.current.end();
    } else {
      const startingPoint = [
        { lat: mapRef.current.center.lat(), lng: mapRef.current.center.lng() },
      ];
      measureToolRef.current.start(startingPoint);
    }
  };

  return (
    <div className={styles.mapContainer}>
      {isMapApiLoaded && <PlaceSearchBox map={mapRef.current} />}

      <GoogleMapReact
        bootstrapURLKeys={bootstrapMapProps}
        defaultCenter={defaultMapProps.center}
        defaultZoom={defaultMapProps.zoom}
        options={mapSettings.mapOptions}
        onChange={handleMapChange}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {!displayClusters &&
          vehicles.map(vehicle => (
            <Marker
              key={`vehicle-${vehicle.no}`}
              {...vehicle.geo_decimal}
              vehicle={vehicle}
            />
          ))}

        {displayClusters &&
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const geoLocations = {
              lat: latitude,
              lng: longitude,
            };
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster) {
              return (
                <Cluster
                  key={`cluster-${cluster.id}`}
                  clusterId={cluster.id}
                  points={points}
                  pointCount={pointCount}
                  handleClusterClick={handleClusterClick}
                  geoLocations={geoLocations}
                  {...geoLocations}
                />
              );
            }

            return (
              <Marker
                key={`crime-${cluster.properties.vehicle.no}`}
                {...geoLocations}
                vehicle={cluster.properties.vehicle}
              />
            );
          })}
      </GoogleMapReact>
      {showSettingsPanel && (
        <div ref={mapSettingsPanelRef}>
          <MapSettingsPanel />
        </div>
      )}

      <div className={styles.settingsContainer}>
        {measureStart && (
          <div className={styles.measureGuide}>
            {t("settingsMap.measureGuide")}
          </div>
        )}
        <Space>
          <Button
            onClick={handleMeasureTool}
            className={styles.mapSettingsBtn}
            icon={<ToolOutlined />}
          >
            {measureStart
              ? t("settingsMap.measureEndButton")
              : t("settingsMap.measureButton")}
          </Button>
          <Button
            onClick={handleMapSettingsPanel}
            className={styles.mapSettingsBtn}
            icon={<SettingOutlined />}
          >
            {t("settingsMap.mapSettingsButton")}
          </Button>
        </Space>
      </div>
    </div>
  );
});

export default Map;
