import { memo } from "react";

import { Table } from "antd";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";

import { getLocationsFittedBounds } from "../../../../helpers/fleet-manager.helpers";
import {
  SimplifiedVehicleDef,
  SimplifiedVehicleGroupDef,
} from "../../../../types/vehicle-group.types";
import {
  MapBoundDef,
  VehiclePositionDef,
  GeoLocationDef,
} from "../../../../types/vehicle-position.types";
import VehicleStatus from "../VehicleStatus/VehicleStatus";
import styles from "./MapVehicleList.module.scss";

interface VehicleGroupListProps {
  groups: SimplifiedVehicleGroupDef[];
  vehiclePositions: VehiclePositionDef[];
  loading: boolean;
  selectedRowKeys:
    | SimplifiedVehicleDef["id"][]
    | SimplifiedVehicleGroupDef["id"][];
  onRowSelection: (
    record: SimplifiedVehicleGroupDef | SimplifiedVehicleDef,
    selected: boolean,
    selectedRows: SimplifiedVehicleGroupDef[] | SimplifiedVehicleDef[]
  ) => void;
  onAllSelect: (selected: boolean) => void;
  onSelectBound: (bounds: MapBoundDef | undefined) => void;
  // positionFilterParams: VehiclePositionFilterDef;
}

const MapVehicleList = memo(
  ({
    groups,
    vehiclePositions,
    loading,
    selectedRowKeys,
    onRowSelection,
    onAllSelect,
    onSelectBound,
  }: VehicleGroupListProps) => {
    const { t } = useTranslation();

    /**
     * Render vehicle driving status
     * @param record
     */
    const renderStatus = (record: SimplifiedVehicleGroupDef) => {
      const vehicleInfo = vehiclePositions?.find(item => item.no === record.id);
      return vehicleInfo?.status;
    };

    /**
     * Handle map bound based on vehicle position
     * @param record
     */
    const handleMapBounds = (record: SimplifiedVehicleGroupDef) => {
      const locations: GeoLocationDef[] = [];

      // If record has vehicles find the vehicle positions and get the center of locations and zoom out
      if (record.vehicles) {
        record.vehicles.forEach(vehicle => {
          const info = vehiclePositions?.find(pos => pos.no === vehicle.id);
          if (info) {
            locations.push(info.geo_decimal);
          }
        });
      }
      if (locations.length) {
        const bounds = getLocationsFittedBounds(locations);
        onSelectBound(bounds);
        return;
      }

      // If record is a single vehicle find the position and zoom in
      const vehicleInfo = vehiclePositions?.find(item => item.no === record.id);
      if (!vehicleInfo) {
        return;
      }
      const bounds = getLocationsFittedBounds([{ ...vehicleInfo.geo_decimal }]);
      onSelectBound(bounds);
    };

    // Row selection props
    const rowSelection = {
      selectedRowKeys,
      onSelect: (
        record: SimplifiedVehicleGroupDef | SimplifiedVehicleDef,
        selected: boolean,
        selectedRows: SimplifiedVehicleGroupDef[]
      ) => {
        onRowSelection(record, selected, selectedRows);
      },
      onSelectAll: (selected: boolean) => {
        onAllSelect(selected);
      },
    };

    return (
      <Table
        dataSource={groups}
        loading={loading}
        pagination={false}
        childrenColumnName="vehicles"
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
        }}
        scroll={{ y: "calc(100vh - 320px)" }}
        className={styles.table}
      >
        <Table.Column
          key="name"
          title={t("fleetManagerMap.vehicleGroupColumnTitleName")}
          dataIndex="name"
        />

        <Table.Column
          key="status"
          title={t("fleetManagerMap.vehicleGroupColumnTitleStatus")}
          render={(value, record: SimplifiedVehicleGroupDef) => (
            <VehicleStatus status={renderStatus(record)} />
          )}
        />
        <Table.Column
          key="action"
          title={t("fleetManagerMap.vehicleGroupColumnTitleActions")}
          render={(value, record: SimplifiedVehicleGroupDef) => (
            <Button
              onClick={() => handleMapBounds(record)}
              type="link"
              size="middle"
              noPadding
            >
              {t("fleetManagerMap.actionButtonShowOnMap")}
            </Button>
          )}
        />
      </Table>
    );
  }
);

export default MapVehicleList;
