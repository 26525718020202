import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { fleetManagerApi } from "../api/fleet-manager.api";
import {
  MessageDef,
  DriverDef,
  MessageFilterDef,
} from "../types/messages.types";

type ConversationState = {
  conversations: {
    data: MessageDef[];
    driver: DriverDef | null;
    pagination: PaginationDef;
  };
  loadingConversations: boolean;
};

const initialState: ConversationState = {
  conversations: {
    data: [],
    driver: null,
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingConversations: false,
};

export const getFullConversation = createAsyncThunk(
  "messageThread/getFullConversation",
  async (filters: MessageFilterDef) => {
    try {
      const response = await fleetManagerApi.getFullConversation(filters);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

const messageThreadSlice = createSlice({
  name: "messageThread",
  initialState,
  reducers: {
    setConversationsEmpty(state) {
      state.conversations = initialState.conversations;
    },
  },
  extraReducers: builder => {
    builder.addCase(getFullConversation.pending, state => {
      state.loadingConversations = true;
    });
    builder.addCase(getFullConversation.fulfilled, (state, action) => {
      state.loadingConversations = false;
      // This condition is only for getting the latest messages replacing all the old messages
      if (action.payload?.meta?.pagination.current_page === 1) {
        state.conversations.data = [...(action.payload.data?.reverse() || [])];
      } else {
        state.conversations.data = [
          ...(action.payload.data?.reverse() || []),
          ...state.conversations.data,
        ];
      }
      state.conversations.driver = action.payload.meta?.driver;
      state.conversations.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getFullConversation.rejected, state => {
      state.loadingConversations = false;
    });
  },
});

export const { setConversationsEmpty } = messageThreadSlice.actions;
export default messageThreadSlice.reducer;
