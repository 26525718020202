import { useEffect } from "react";

import { useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { resetMapSettings } from "../../fleet-manager/redux/map-settings.slice";
import { clearTypedMessage } from "../../fleet-manager/redux/message-store.slice";
import { resetSelectedVehicles } from "../../fleet-manager/redux/selected-vehicles.slice";
import { getAuthentication } from "../api/local-auth.api";
import {
  getTokens,
  isTokenValid,
  saveTokens,
  removeTokens,
  getLoginUrl,
  redirect,
  removeTokenAndRedirect,
} from "../helpers/auth.helpers";
import { getMe, setAuthentication, clearUser } from "../redux/auth.slice";

const useValidateAccess = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const dispatch = useAppDispatch();

  const accessToken = getTokens();

  const validateAccess = () => {
    // Checking access token validity
    if (accessToken && isTokenValid()) {
      dispatch(setAuthentication(true));
      dispatch(getMe());
      return;
    }
    dispatch(setAuthentication(false));
    removeTokens();

    // This if only for running the app in local dev
    // nothing to do with actual authentication flow
    if (process.env.NODE_ENV === "development") {
      getAuthentication().then(response => {
        response?.data?.access_token &&
          saveTokens(response?.data?.access_token);
        redirect(`${window.location.protocol}//${window.location.host}`);
      });
    } else {
      redirect(getLoginUrl());
    }
  };

  useEffect(() => {
    validateAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    dispatch(clearUser());
    dispatch(resetSelectedVehicles());
    dispatch(resetMapSettings());
    dispatch(clearTypedMessage());
    removeTokenAndRedirect();
  };

  return { isAuthenticated, logout };
};

export default useValidateAccess;
