import { memo } from "react";

import { Col, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Item } from "@app/components/atoms/FormModal/FormModal";
import { RootState } from "@app/redux/root-reducer";

import { VehicleDef } from "../../../../types/vehicle.types";
import styles from "./DynamicGroup.module.scss";

const { Text } = Typography;

interface DynamicGroupProps {
  vehicles: VehicleDef["vehicle_no"][];
}

const DynamicGroup = memo(({ vehicles }: DynamicGroupProps) => {
  const { t } = useTranslation();

  const departments = useSelector(
    (state: RootState) => state.vehicles.departments
  );
  const planSubDepartments = useSelector(
    (state: RootState) => state.vehicles.planSubDepartments
  );
  const vehicleResponsibles = useSelector(
    (state: RootState) => state.vehicles.vehicleResponsibles
  );

  return (
    <>
      <Text type="secondary" className={styles.subTitle}>
        {t("settingsVehicleGroups.dynamicGroupSubtitle")}
      </Text>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelDepartment")} name="departments">
          <Select
            mode="multiple"
            allowClear
            placeholder={t("settingsVehicles.departmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsVehicles.labelPlanDepartment")}
          name="plan_departments"
        >
          <Select
            mode="multiple"
            allowClear
            placeholder={t("settingsVehicles.planDepartmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsVehicles.labelPlanSubDepartment")}
          name="plan_subdepartments"
        >
          <Select
            mode="multiple"
            allowClear
            placeholder={t("settingsVehicles.planSubDepartmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {planSubDepartments?.map(planSubDept => (
              <Select.Option key={planSubDept.code} value={planSubDept.code}>
                {planSubDept.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelResponsible")} name="responsible">
          <Select
            mode="multiple"
            allowClear
            placeholder={t("settingsVehicles.responsiblePlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {vehicleResponsibles?.map(responsible => (
              <Select.Option
                key={responsible.code}
                name={responsible.name}
                value={responsible.code}
              >
                {responsible.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelVehicles")} name="vehicles">
          <Select
            mode="multiple"
            allowClear
            placeholder={t("settingsVehicles.vehiclePlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {vehicles?.map(vehicle => (
              <Select.Option key={vehicle} value={vehicle}>
                {vehicle}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
    </>
  );
});

export default DynamicGroup;
