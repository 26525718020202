import { memo } from "react";

import { AddressDef } from "../../../../types/reporting-position.types";

interface PositionAddressProps {
  address?: AddressDef;
}

const PositionAddress = memo(({ address }: PositionAddressProps) => {
  return (
    <>
      {address
        ? `${address.name}, ${address.street} ${address.house_no}, ${address.zip} ${address.city}, ${address.country.code}`
        : "-"}
    </>
  );
});

export default PositionAddress;
