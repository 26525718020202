/* eslint-disable camelcase */
import { memo, useEffect, useState } from "react";

import { Form, Input, Col, Select, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FormModal, { Item } from "@app/components/atoms/FormModal/FormModal";
import { ItemModalEnum } from "@app/constants/route.constants";
import useShowModal from "@app/hooks/useShowModal";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { getAxlesOptions } from "../../../../helpers/settings.helpers";
import {
  addNewVehicle,
  getVehicleByNo,
  updateVehicle,
} from "../../../../redux/vehicles.slice";
import { VehicleDef, VehicleRequestDef } from "../../../../types/vehicle.types";

interface VehiclesModalProps {
  onClose: (action: ModalCloseActionDef) => void;
}

interface FormValuesDef {
  vehicle_no: string;
  department: string | undefined;
  plan_department: string | undefined;
  plan_subdepartment: string | undefined;
  responsible: string;
  no_of_axles: number | undefined;
}

const VehiclesModal = memo(({ onClose }: VehiclesModalProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showModal, action, actionId } = useShowModal();
  const [form] = Form.useForm();

  const departments = useSelector(
    (state: RootState) => state.vehicles.departments
  );
  const planSubDepartments = useSelector(
    (state: RootState) => state.vehicles.planSubDepartments
  );
  const vehicleResponsibles = useSelector(
    (state: RootState) => state.vehicles.vehicleResponsibles
  );

  const editMode = action === ItemModalEnum.EDIT;

  const setFormValue = (vehicle: VehicleDef) => {
    form.setFieldsValue({
      vehicle_no: vehicle.vehicle_no,
      department: vehicle.department?.code,
      plan_department: vehicle.plan_department?.code,
      plan_subdepartment: vehicle.plan_subdepartment?.code,
      responsible: vehicle.responsible?.code,
      no_of_axles: vehicle.no_of_axles,
    });
  };

  useEffect(() => {
    if (editMode && actionId) {
      const getVehicle = async () => {
        setLoading(true);
        const response = await dispatch(getVehicleByNo(actionId));
        if (getVehicleByNo.fulfilled.match(response)) {
          setFormValue(response.payload);
          setLoading(false);
        }
        if (getVehicleByNo.rejected.match(response)) {
          setLoading(false);
          AlertMessage.error(t("default.errorMessage"));
        }
      };
      getVehicle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, editMode]);

  const showAlertMessage = (isSuccess: boolean, message: string) => {
    setLoading(false);
    if (isSuccess) {
      AlertMessage.success(message);
      form.resetFields();
      onClose({ triggerRefetch: true });
    } else {
      AlertMessage.error(message ?? t("default.errorMessage"));
    }
  };

  const addVehicle = async (fields: VehicleRequestDef) => {
    const response = await dispatch(addNewVehicle(fields));
    if (addNewVehicle.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsVehicles.addSuccessMessage"));
    }
    if (addNewVehicle.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const editVehicle = async (
    vehicleNo: VehicleDef["vehicle_no"],
    fields: VehicleRequestDef
  ) => {
    const response = await dispatch(updateVehicle({ vehicleNo, fields }));
    if (updateVehicle.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsVehicles.editSuccessMessage"));
    }
    if (updateVehicle.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const handleSubmit = (values: FormValuesDef) => {
    setLoading(true);
    const fields: VehicleRequestDef = {
      vehicle_no: values.vehicle_no.trim(),
      ...(values.department && {
        department: {
          code: values.department,
        },
      }),
      ...(values.plan_department && {
        plan_department: {
          code: values.plan_department,
        },
      }),
      ...(values.plan_subdepartment && {
        plan_subdepartment: {
          code: values.plan_subdepartment,
        },
      }),
      ...(values.responsible && {
        responsible: {
          code: values.responsible,
        },
      }),
      ...(values.no_of_axles && { no_of_axles: values.no_of_axles }),
    };
    if (editMode && actionId) editVehicle(actionId, fields);
    else addVehicle(fields);
  };

  const handleClose = () => {
    form.resetFields();
    onClose({ triggerRefetch: false });
  };

  const validateMessages = {
    required: t("default.requiredMessage"),
    string: {
      max: t("default.maxLengthMessage", {
        max: 50,
      }),
    },
  };

  return (
    <FormModal
      form={form}
      validateMessages={validateMessages}
      title={
        editMode
          ? t("settingsVehicles.editVehicleTitle")
          : t("settingsVehicles.addVehicleTitle")
      }
      visible={showModal}
      submitButtonText={
        editMode ? t("default.editTitle") : t("default.addTitle")
      }
      destroyOnClose
      loadingSubmit={loading}
      loadingContent={loading}
      onClose={handleClose}
      onFinish={handleSubmit}
    >
      <Col span={24}>
        <Item
          label={t("settingsVehicles.labelVehicleNo")}
          name="vehicle_no"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input
            disabled={editMode}
            placeholder={t("settingsVehicles.vehicleNoPlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelDepartment")} name="department">
          <Select
            placeholder={t("settingsVehicles.departmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsVehicles.labelPlanDepartment")}
          name="plan_department"
        >
          <Select
            placeholder={t("settingsVehicles.planDepartmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsVehicles.labelPlanSubDepartment")}
          name="plan_subdepartment"
        >
          <Select
            placeholder={t("settingsVehicles.planSubDepartmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {planSubDepartments?.map(planSubDept => (
              <Select.Option key={planSubDept.code} value={planSubDept.code}>
                {planSubDept.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelResponsible")} name="responsible">
          <Select
            placeholder={t("settingsVehicles.responsiblePlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {vehicleResponsibles?.map(responsible => (
              <Select.Option key={responsible.code} value={responsible.code}>
                {responsible.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item label={t("settingsVehicles.labelAxles")} name="no_of_axles">
          <Select
            placeholder={t("settingsVehicles.axlesPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {getAxlesOptions().map(option => (
              <Select.Option key={option.id} value={option.value}>
                {option.value}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
    </FormModal>
  );
});

export default VehiclesModal;
