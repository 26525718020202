import { messengerApi, api } from "@app/api/api";

import { FleetManagerEndpointsEnum } from "../constants/fleet-manager.endpoints";
import {
  MessageFilterDef,
  SendMessageDef,
  SendGroupMessageDef,
  VisibleVehicleGroupFilterDef,
} from "../types/messages.types";
import { VehicleGroupFilterDef } from "../types/vehicle-group.types";
import { VehiclePositionFilterDef } from "../types/vehicle-position.types";

const getIncomingMessages = (filter?: MessageFilterDef) => {
  return messengerApi.get(FleetManagerEndpointsEnum.MESSAGES_INCOMING, {
    params: filter,
  });
};
const getOutgoingMessages = (filter?: MessageFilterDef) => {
  return messengerApi.get(FleetManagerEndpointsEnum.MESSAGES_OUTGOING, {
    params: filter,
  });
};
const getUnreadMessageCount = () => {
  return messengerApi.get(FleetManagerEndpointsEnum.MESSAGES_UNREAD);
};

const getFullConversation = (filters: MessageFilterDef) => {
  return messengerApi.get(
    `${FleetManagerEndpointsEnum.MESSAGES}/${filters?.vehicleNo}`,
    { params: filters }
  );
};

const markMessageRead = (messageId: string) => {
  return messengerApi.patch(
    `${FleetManagerEndpointsEnum.MESSAGES}/${messageId}`
  );
};

const sendMessageToDriver = (fields: SendMessageDef | SendGroupMessageDef) => {
  return messengerApi.post(FleetManagerEndpointsEnum.MESSAGES, fields);
};

const sendMessageToMultipleDriver = (
  fields: SendMessageDef | SendGroupMessageDef
) => {
  return messengerApi.post(
    FleetManagerEndpointsEnum.GROUP_MESSAGE_SENT,
    fields
  );
};

const getPredefinedMessages = (filters: MessageFilterDef) => {
  return messengerApi.get(FleetManagerEndpointsEnum.MESSAGES_PREDEFINED, {
    params: filters,
  });
};

// This will get vehicle groups with static vehicles only
const getVehicleGroupsWithStaticVehicles = (filters: VehicleGroupFilterDef) => {
  return api.get(
    FleetManagerEndpointsEnum.VEHICLE_GROUPS_WITH_STATIC_VEHICLES,
    {
      params: filters,
    }
  );
};

// This will get vehicle groups with all vehicles associated with it
const getVehicleGroupsWithVehicles = (
  userId: number,
  filters?: VisibleVehicleGroupFilterDef
) => {
  return api.get(
    `${FleetManagerEndpointsEnum.VEHICLE_GROUPS_WITH_VEHICLES}/${userId}/vehicle-groups`,
    {
      params: filters,
    }
  );
};

const getVehiclesPositions = (filters?: VehiclePositionFilterDef) => {
  return api.get(FleetManagerEndpointsEnum.VEHICLES_POSITIONS, {
    params: filters,
  });
};

export const fleetManagerApi = {
  getIncomingMessages,
  getUnreadMessageCount,
  getFullConversation,
  markMessageRead,
  sendMessageToDriver,
  sendMessageToMultipleDriver,
  getPredefinedMessages,
  getVehicleGroupsWithStaticVehicles,
  getVehicleGroupsWithVehicles,
  getVehiclesPositions,
  getOutgoingMessages,
};
