/* eslint-disable camelcase */
import { memo, useEffect, useState } from "react";

import { Form, Input, Col, Select, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FormModal, { Item } from "@app/components/atoms/FormModal/FormModal";
import { ItemModalEnum } from "@app/constants/route.constants";
import useShowModal from "@app/hooks/useShowModal";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { getUserTypes } from "../../../../helpers/settings.helpers";
import {
  getUserById,
  addNewUser,
  updateUser,
} from "../../../../redux/users.slice";
import { UserDef, UserRequestDef } from "../../../../types/user.types";

interface UsersModalProps {
  onClose: (action: ModalCloseActionDef) => void;
}

interface FormValuesDef {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  role: string;
  email: string;
  company: string;
  department: {
    values: {
      value: string;
      label: string;
    };
  };
}

const UsersModal = memo(({ onClose }: UsersModalProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showModal, action, actionId } = useShowModal();
  const [users, setUsers] = useState<UserDef | null>(null);
  const [form] = Form.useForm();

  const editMode = action === ItemModalEnum.EDIT;

  const departments = useSelector(
    (state: RootState) => state.vehicles.departments
  );

  const setFormValue = (user: UserDef) => {
    form.setFieldsValue({
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email,
      company: user.company,
      ...((user.department?.code && {
        department: {
          values: {
            value: user.department.code,
            label: user.department.name,
          },
        },
      }) || { department: null }),
      role: user.role,
    });
  };

  useEffect(() => {
    if (editMode && actionId) {
      const getUser = async () => {
        setLoading(true);
        const response = await dispatch(getUserById(parseInt(actionId, 10)));
        if (getUserById.fulfilled.match(response)) {
          setFormValue(response.payload);
          setUsers(response.payload);
          setLoading(false);
        }
        if (getUserById.rejected.match(response)) {
          setLoading(false);
          AlertMessage.error(t("default.errorMessage"));
        }
      };
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, editMode]);

  const showAlertMessage = (isSuccess: boolean, message: string) => {
    setLoading(false);
    if (isSuccess) {
      AlertMessage.success(message);
      form.resetFields();
      onClose({ triggerRefetch: true });
    } else {
      AlertMessage.error(message ?? t("default.errorMessage"));
    }
  };

  const addUser = async (fields: UserRequestDef) => {
    const response = await dispatch(addNewUser(fields));
    if (addNewUser.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsUsers.addSuccessMessage"));
    }
    if (addNewUser.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const editVehicle = async (id: UserDef["id"], fields: UserRequestDef) => {
    const response = await dispatch(updateUser({ id, fields }));
    if (updateUser.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsUsers.editSuccessMessage"));
    }
    if (updateUser.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const handleSubmit = (values: FormValuesDef) => {
    setLoading(true);
    const fields: UserRequestDef = {
      ...values,
      ...((values.department.values && {
        department: {
          code: values.department.values.value,
          name: values.department.values.label,
        },
      }) || { department: null }),
      is_active: users?.is_active,
    };

    if (editMode && actionId) editVehicle(parseInt(actionId, 10), fields);
    else addUser(fields);
  };

  const handleClose = () => {
    form.resetFields();
    onClose({ triggerRefetch: false });
  };

  const validateMessages = {
    required: t("default.requiredMessage"),
    string: {
      max: t("default.maxLengthMessage", {
        max: 50,
      }),
    },
    types: {
      email: t("default.invalidEmailMessage", {
        label: t("settingsUsers.labelEmail"),
      }),
    },
    pattern: {
      mismatch: t("default.invalidNumberMessage", {
        label: t("settingsUsers.labelPhoneNumber"),
      }),
    },
  };

  return (
    <FormModal
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      title={
        editMode
          ? t("settingsUsers.editUserTitle")
          : t("settingsUsers.addUserTitle")
      }
      visible={showModal}
      submitButtonText={
        editMode ? t("default.editTitle") : t("default.addTitle")
      }
      destroyOnClose
      loadingSubmit={loading}
      loadingContent={loading}
      onClose={handleClose}
      onFinish={handleSubmit}
    >
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelUsername")}
          name="username"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input
            disabled={editMode}
            placeholder={t("settingsUsers.usernamePlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelPassword")}
          name="password"
          rules={[
            {
              required: !editMode,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input.Password
            placeholder={t("settingsUsers.passwordPlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelFirstName")}
          name="first_name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsUsers.firstNamePlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelLastName")}
          name="last_name"
          rules={[
            {
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsUsers.lastNamePlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelPhoneNumber")}
          name="phone"
          rules={[
            {
              required: true,
              whitespace: true,
              pattern: /^(\+|00)[1-9][0-9]{7,15}$/,
            },
          ]}
        >
          <Input placeholder={t("settingsUsers.phoneNumberPlaceholder")} />
        </Item>
      </Col>

      <Col span={24}>
        <Item
          label={t("settingsUsers.labelUserType")}
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={t("settingsUsers.userTypePlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {getUserTypes()?.map(type => (
              <Select.Option key={type.id} value={type.value}>
                {type.value}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>

      <Col span={24}>
        <Item
          label={t("settingsUsers.labelEmail")}
          name="email"
          rules={[
            {
              type: "email",
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsUsers.emailPlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelCompany")}
          name="company"
          rules={[
            {
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsUsers.companyPlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelDepartment")}
          name={["department", "values"]}
        >
          <Select
            labelInValue
            placeholder={t("settingsUsers.departmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
    </FormModal>
  );
});

export default UsersModal;
