import { PermissionEnum } from "@app/features/permissions/permissions";
import { RouteItemDef } from "@app/types/route.types";

import { SettingsPathsEnum } from "../constants/settings.paths";
import ChassisScreen from "../screens/ChassisScreen/ChassisScreen";
import DispatcherMessageScreen from "../screens/DispatcherMessageScreen/DispatcherMessageScreen";
import DriverMessageScreen from "../screens/DriverMessageScreen/DriverMessageScreen";
import DriversScreen from "../screens/DriversScreen/DriversScreen";
import MapMessengerConfigScreen from "../screens/MapMessengerConfigScreen/MapMessengerConfigScreen";
import UsersScreen from "../screens/UsersScreen/UsersScreen";
import VehicleGroupsScreen from "../screens/VehicleGroupsScreen/VehicleGroupsScreen";
import VehiclesScreen from "../screens/VehiclesScreen/VehiclesScreen";
import SettingsRoutes from "./SettingsRoutes";

const SETTINGS_SCREEN: RouteItemDef = {
  id: "settings",
  path: SettingsPathsEnum.SETTINGS,
  navigationTitle: "settings.navigationTitle",
  component: SettingsRoutes,
  nestedRoutes: [
    {
      id: "entity-management",
      groupTitle: "settings.groupEntityManagement",
      permissions: [PermissionEnum.ENTITY_MANAGEMENT],
      nestedRoutes: [
        {
          id: "vehicle-groups",
          path: SettingsPathsEnum.VEHICLE_GROUPS,
          navigationTitle: "settingsVehicleGroups.navigationTitle",
          component: VehicleGroupsScreen,
          permissions: [PermissionEnum.VEHICLE_GROUPS],
        },
        {
          id: "vehicles",
          path: SettingsPathsEnum.VEHICLES,
          navigationTitle: "settingsVehicles.navigationTitle",
          component: VehiclesScreen,
          permissions: [PermissionEnum.VEHICLES],
        },
        {
          id: "chassis",
          path: SettingsPathsEnum.CHASSIS,
          navigationTitle: "settingsChassis.navigationTitle",
          component: ChassisScreen,
          permissions: [PermissionEnum.CHASSIS],
        },
        {
          id: "drivers",
          path: SettingsPathsEnum.DRIVERS,
          navigationTitle: "settingsDrivers.navigationTitle",
          component: DriversScreen,
          permissions: [PermissionEnum.DRIVERS],
        },
      ],
    },
    {
      id: "admin-settings",
      groupTitle: "settings.groupAdminSettings",
      nestedRoutes: [
        {
          id: "users",
          path: SettingsPathsEnum.USERS,
          navigationTitle: "settingsUsers.navigationTitle",
          component: UsersScreen,
          permissions: [PermissionEnum.USERS],
        },
        {
          id: "dispatcher-predefined-message",
          path: SettingsPathsEnum.DISPATCHER_PREDEFINED_MESSAGES,
          navigationTitle:
            "settingsPredefinedMessages.navigationTitleDispatcher",
          component: DispatcherMessageScreen,
          permissions: [PermissionEnum.PREDEFINED_MESSAGES],
        },
        {
          id: "driver-predefined-message",
          path: SettingsPathsEnum.DRIVER_PREDEFINED_MESSAGES,
          navigationTitle: "settingsPredefinedMessages.navigationTitleDriver",
          component: DriverMessageScreen,
          permissions: [PermissionEnum.PREDEFINED_MESSAGES],
        },
        {
          id: "map-messenger-config",
          path: SettingsPathsEnum.MAP_MESSENGER_CONFIG,
          navigationTitle: "settingsMapMessengerConfig.navigationTitle",
          component: MapMessengerConfigScreen,
        },
      ],
    },
  ],
};

export const SETTINGS_ROUTES = [SETTINGS_SCREEN];
