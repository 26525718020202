import { memo } from "react";

import { ThunderboltOutlined } from "@ant-design/icons";
import cx from "classnames";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

import Button from "@app/components/atoms/Button/Button";

import UploadAttachment from "../UploadAttachment/UploadAttachment";
import styles from "./ActionButtons.module.scss";

interface ActionButtonsProps {
  disabled: boolean;
  showBtnTitle: boolean;
  activatePredefined: boolean;
  vehiclesNo: string[];
  className?: string;
  isUploading?: boolean;
  togglePredefined: () => void;
  attachmentUploaded: (attachmentUri: string, file: any) => void;
  beforeUpload?: (file: any) => void;
  page?: string;
}

const ActionButtons = memo(
  ({
    disabled,
    showBtnTitle,
    activatePredefined,
    vehiclesNo,
    className,
    isUploading,
    togglePredefined,
    attachmentUploaded,
    beforeUpload,
    page,
  }: ActionButtonsProps) => {
    const { t } = useTranslation();

    const returnAdditionalClass = (
      isDragAccept: boolean,
      isDragReject: boolean
    ) => {
      if (isDragAccept) {
        return `${styles.accept}`;
      }
      if (isDragReject) {
        return "reject";
      }
      return "";
    };

    return (
      <div className={cx(styles.actionButtonsWrapper, className)}>
        <div className={styles.predefinedMessageBtn}>
          <Button
            shape="circle"
            disabled={disabled}
            className={cx({
              [styles.activated]: activatePredefined,
            })}
            onClick={togglePredefined}
            icon={<ThunderboltOutlined />}
          />
          {showBtnTitle && (
            <span className={styles.buttonLabel}>
              {t("fleetManagerMessages.buttonPredefinedMessage")}
            </span>
          )}
        </div>
        <div className={styles.attachmentBtn}>
          {page === "Message" ? (
            <Dropzone onDrop={beforeUpload} minSize={1024} maxSize={3072000}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragAccept,
                isDragReject,
              }) => {
                const additionalClass = returnAdditionalClass(
                  isDragAccept,
                  isDragReject
                );

                return (
                  <div
                    {...getRootProps({
                      className: `${styles.dropzone} ${additionalClass}`,
                    })}
                  >
                    <input {...getInputProps()} />

                    <Button
                      shape="circle"
                      size="large"
                      disabled={disabled || isUploading}
                      loading={isUploading}
                      icon={<span>{isDragActive ? "📂" : "📁"}</span>}
                    />
                  </div>
                );
              }}
            </Dropzone>
          ) : (
            <UploadAttachment
              isUploading={isUploading}
              disabled={disabled}
              vehiclesNo={vehiclesNo}
              uploadSuccess={(fileUri: any, file: any) => {
                return attachmentUploaded(fileUri, file);
              }}
            />
          )}
          {showBtnTitle && (
            <span className={styles.buttonLabel}>
              {t("fleetManagerMessages.buttonAddAttachment")}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default ActionButtons;
