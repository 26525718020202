/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useRef, useState } from "react";

import { SettingOutlined } from "@ant-design/icons";
import { Space } from "antd";
import GoogleMapReact, { BootstrapURLKeys } from "google-map-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "@app/components/atoms/Button/Button";
import { ENV } from "@app/constants/env";
import {
  GoogleMapDefaults,
  GoogleMapPolygon,
} from "@app/constants/map.constants";
import useOutsideClick from "@app/hooks/useOutsideClick";
import { RootState } from "@app/redux/root-reducer";

import { getPositionFittedBounds } from "../../../../helpers/reporting.helpers";
import {
  StreakDef,
  VehiclePositionDef,
} from "../../../../types/reporting-position.types";
import PositionDetailsCard from "../PositionDetailsCard/positionDetailsCard";
import MapSettingsPanel from "../PositionMapSettingsPanel/PositionMapSettingsPanel";
import PositionMarker from "../PositionMarker/PositionMarker";
import styles from "./PositionMap.module.scss";

interface PositionMapProps {
  streaks: StreakDef[];
  selectedMarkerPosition: VehiclePositionDef | undefined;
  onChangeMarkerSelection: (position: VehiclePositionDef) => void;
}

const defaultMapProps = {
  center: {
    lat: GoogleMapDefaults.MAP_CENTER_LAT,
    lng: GoogleMapDefaults.MAP_CENTER_LNG,
  },
  zoom: GoogleMapDefaults.ZOOM,
  clusterRadius: GoogleMapDefaults.CLUSTER_RADIUS,
  clusterMaxZoom: GoogleMapDefaults.CLUSTER_MAX_ZOOM,
};

const bootstrapMapProps: BootstrapURLKeys = {
  key: `${ENV.GOOGLE_MAP_API_KEY}`,
  libraries: ["places", "geometry"],
};

const PositionMap = memo(
  ({
    streaks,
    selectedMarkerPosition,
    onChangeMarkerSelection,
  }: PositionMapProps) => {
    const { t } = useTranslation();

    const { mapSettings } = useSelector(
      (state: RootState) => state.mapSettingsOptions
    );

    const [showSettingsPanel, setShowSettingsPanel] = useState(false);
    const [startPositions, setStartPositions] = useState<
      VehiclePositionDef[]
    >();

    const [displayMarkerDetails, setDisplayMarkerDetails] = useState<boolean>(
      false
    );

    const mapsRef = useRef<any>();
    const mapRef = useRef<any>();
    const polylineRef = useRef<any[]>([]);

    const selectedMarkerRef = useRef<VehiclePositionDef>();

    const mapSettingsPanelRef = useRef<HTMLDivElement>(null);

    const { outsideClicked } = useOutsideClick(mapSettingsPanelRef);

    /**
     * Handle map settings panel display
     */
    const handleMapSettingsPanel = () => {
      if (outsideClicked) {
        setShowSettingsPanel(false);
      } else {
        setShowSettingsPanel(prevShowPanel => !prevShowPanel);
      }
    };

    /**
     * Effects on outside clicked
     */
    useEffect(() => {
      if (outsideClicked) {
        handleMapSettingsPanel();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outsideClicked]);

    /**
     * On click on polyline
     * @param polyline
     * @param maps
     */
    const addListenersOnPolyline = (polyline: any, maps: any) => {
      maps.event.addListener(polyline, "click", () => {
        // TODO: Will implement later
        // polyline.setOptions({
        //   strokeWeight: GoogleMapPolygon.SELECT_STROKE_WEIGHT,
        //   strokeColor: GoogleMapPolygon.SELECT_STROKE_COLOR,
        //   zIndex: 1,
        // });
      });
    };

    /**
     * Hide open details card and show the active one
     * @param position
     */
    const showDetailsCard = (position: VehiclePositionDef) => {
      setDisplayMarkerDetails(false);
      selectedMarkerRef.current = position;
      setTimeout(() => {
        setDisplayMarkerDetails(true);
      }, 100);
    };

    /**
     * Select the marker and show the details card if streak any streak is selected from the list
     */
    useEffect(() => {
      selectedMarkerRef.current = selectedMarkerPosition;
      selectedMarkerPosition && showDetailsCard(selectedMarkerPosition);
    }, [selectedMarkerPosition]);

    /**
     * Clear previous drawn polyline
     */
    const clearRoute = () => {
      polylineRef.current?.forEach(polyline => {
        polyline?.setMap(null);
      });
    };

    /**
     * Draw route with polyline based on positions geo decimal
     * @param map
     * @param maps
     */
    const drawRouteOnMap = (map: any, maps: any) => {
      if (maps) {
        clearRoute();
        setStartPositions([]);
        let paths: any = [];
        streaks?.forEach(streak => {
          paths = streak.positions?.map(position => position.geo_decimal);
          const polyline = new maps.Polyline({
            path: paths ?? [],
            geodesic: true,
            strokeColor: GoogleMapPolygon.STROKE_COLOR,
            strokeWeight: GoogleMapPolygon.STROKE_WEIGHT,
          });
          polyline?.setMap(map);
          addListenersOnPolyline(polyline, maps);
          polylineRef.current?.push(polyline);
        });
      }
    };

    /**
     * Redraw route and detect stops positions of a streak
     */
    useEffect(() => {
      setStartPositions([]);
      drawRouteOnMap(mapRef.current, mapsRef.current);
      if (streaks?.length) {
        const startPoints = streaks
          ?.map(streak => {
            if (streak.positions?.length) {
              const position = { ...streak.positions[0] };
              position.streakId = streak.id;
              return position;
            }
            return undefined;
          })
          .filter(streak => streak !== undefined);
        setStartPositions((prevPoints: any) => [...prevPoints, ...startPoints]);
        const bounds = getPositionFittedBounds(
          startPoints as VehiclePositionDef[]
        );
        bounds && mapRef.current?.fitBounds(bounds);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streaks]);

    /**
     * Handle map api loaded
     * @param map
     * @param maps
     */
    const handleApiLoaded = (map: any, maps: any) => {
      mapsRef.current = maps;
      mapRef.current = map;
      drawRouteOnMap(map, maps);
    };

    /**
     * Handle marker selection and pass back selected one to parent component
     * @param position
     */
    const handleMarkerSelection = (position: VehiclePositionDef) => {
      showDetailsCard(position);
      onChangeMarkerSelection(position);
    };

    /**
     * Close position details card
     */
    const closePositionDetails = () => {
      setDisplayMarkerDetails(prevDisplayDetails => !prevDisplayDetails);
      selectedMarkerRef.current = undefined;
    };

    return (
      <div className={styles.mapContainer}>
        <GoogleMapReact
          bootstrapURLKeys={bootstrapMapProps}
          defaultCenter={defaultMapProps.center}
          defaultZoom={defaultMapProps.zoom}
          options={mapSettings.mapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {startPositions?.length &&
            startPositions?.map(position => (
              <PositionMarker
                key={position.streakId}
                {...position?.geo_decimal}
                position={position}
                selected={
                  position.streakId === selectedMarkerRef.current?.streakId
                }
                onClickMarker={handleMarkerSelection}
              />
            ))}

          {displayMarkerDetails && (
            <PositionDetailsCard
              {...selectedMarkerRef.current?.geo_decimal}
              position={selectedMarkerRef.current}
              onClose={closePositionDetails}
            />
          )}
        </GoogleMapReact>

        {showSettingsPanel && (
          <div ref={mapSettingsPanelRef}>
            <MapSettingsPanel />
          </div>
        )}

        <div className={styles.settingsContainer}>
          <Space>
            <Button
              onClick={handleMapSettingsPanel}
              className={styles.mapSettingsBtn}
              icon={<SettingOutlined />}
            >
              {t("settingsMap.mapSettingsButton")}
            </Button>
          </Space>
        </div>
      </div>
    );
  }
);

export default PositionMap;
