import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { settingsApi } from "../api/settings.api";
import { UserDef, UserFilterDef, UserRequestDef } from "../types/user.types";

type UsersState = {
  users: {
    data: UserDef[];
    pagination: PaginationDef;
  };
  drivers: {
    data: UserDef[];
    pagination: PaginationDef;
  };
  loadingUsers: boolean;
};

const initialState: UsersState = {
  users: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  drivers: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingUsers: false,
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (filter: UserFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getUsers(filter);
      return { response: response.data, role: filter.role };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (id: UserDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.getUser(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewUser = createAsyncThunk(
  "users/addNewUser",
  async (fields: UserRequestDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.addNewUser(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (
    data: { id: UserDef["id"]; fields: UserRequestDef },
    { rejectWithValue }
  ) => {
    const { id, fields } = data;
    const payload = { ...fields, id };
    try {
      const response = await settingsApi.updateUser(id, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id: UserDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deleteUser(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const activateUser = createAsyncThunk(
  "users/activateUser",
  async (data: { id: UserDef["id"]; payload: any }, { rejectWithValue }) => {
    try {
      const { id, payload } = data;
      const response = await settingsApi.activateUser(id, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deactivateUser = createAsyncThunk(
  "users/deactivateUser",
  async (id: UserDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deactivateUser(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUsers.pending, state => {
      state.loadingUsers = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loadingUsers = false;
      if (action.payload.role === "Driver") {
        state.drivers.data = action.payload?.response?.data || [];
        state.drivers.pagination = mapPagination(
          action.payload?.response.meta?.pagination
        );
      } else {
        state.users.data = action.payload.response.data || [];
        state.users.pagination = mapPagination(
          action.payload?.response?.meta?.pagination
        );
      }
    });
    builder.addCase(getUsers.rejected, state => {
      state.loadingUsers = false;
    });
  },
});

export default usersSlice.reducer;
