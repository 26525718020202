import { BrowserRouter as Router } from "react-router-dom";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Routes from "@app/routes/Routes";

import { useValidateAccess } from "./features/auth/auth";
import { useLocalization } from "./features/localization/localization";

const App = () => {
  const { isAuthenticated } = useValidateAccess();
  const { loadingTranslation } = useLocalization({
    shouldCall: isAuthenticated,
  });

  if (loadingTranslation || !isAuthenticated) {
    return <LoadingSpinner isFullscreen text="Loading Fleet Manager" />;
  }

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
