/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { adminSettingsApi } from "../api/admin-settings.api";
import {
  PredefinedMessageDef,
  FilterDef,
  PredefinedMessageRequestDef,
} from "../types/predefined-message.types";

type PredefinedMessagesState = {
  predefinedMessages: {
    data: PredefinedMessageDef[];
    pagination: PaginationDef;
  };
  loadingPredefinedMessages: boolean;
};

const initialState: PredefinedMessagesState = {
  predefinedMessages: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingPredefinedMessages: false,
};

export const getPredefinedMessages = createAsyncThunk(
  "predefinedMessages/getPredefinedMessages",
  async (filter: FilterDef, { rejectWithValue }) => {
    try {
      const response = await adminSettingsApi.getPredefinedMessages(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPredefinedMessageById = createAsyncThunk(
  "predefinedMessages/getPredefinedMessageById",
  async (
    { actionId, direction_type }: { actionId: string; direction_type: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminSettingsApi.getPredefinedMessageById(
        actionId,
        direction_type
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPredefinedMessage = createAsyncThunk(
  "predefinedMessages/addPredefinedMessage",
  async (fields: PredefinedMessageRequestDef, { rejectWithValue }) => {
    try {
      const response = await adminSettingsApi.addPredefinedMessage(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePredefinedMessage = createAsyncThunk(
  "predefinedMessages/updatePredefinedMessage",
  async (
    data: {
      id: PredefinedMessageDef["id"];
      fields: PredefinedMessageRequestDef;
    },
    { rejectWithValue }
  ) => {
    const { id, fields } = data;
    try {
      const response = await adminSettingsApi.updatePredefinedMessage(
        id,
        fields
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePredefinedMessage = createAsyncThunk(
  "predefinedMessages/deletePredefinedMessage",
  async (
    {
      id,
      directionType,
    }: { id: PredefinedMessageDef["id"]; directionType: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminSettingsApi.deletePredefinedMessage(
        id,
        directionType
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const predefinedMessagesSlice = createSlice({
  name: "predefinedMessages",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPredefinedMessages.pending, state => {
      state.loadingPredefinedMessages = true;
    });
    builder.addCase(getPredefinedMessages.fulfilled, (state, action) => {
      state.loadingPredefinedMessages = false;
      state.predefinedMessages.data = action.payload.data || [];
      state.predefinedMessages.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getPredefinedMessages.rejected, state => {
      state.loadingPredefinedMessages = false;
    });
  },
});

export default predefinedMessagesSlice.reducer;
