export enum AuditLogTypeEnum {
  CREATE = "Create",
  UPDATE = "Update",
  DELETE = "Delete",
}

export enum AuditlogEntityEnum {
  CHASSIS = "Chassis",
  VEHICLE = "Vehicle",
  USER = "User",
  DRIVER = "Driver",
}
