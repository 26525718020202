import { useEffect, useMemo, useState } from "react";

import { Table, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import SearchFilters from "@app/components/molecules/SearchFilters/SearchFilters";
import TableView, {
  ActionMenuDef,
} from "@app/components/molecules/TableView/TableView";
import { DateFormats, DateTimeFormats } from "@app/constants/date.constants";
import { EXPORTED_CONTENT_TYPE } from "@app/constants/file.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import * as modalAction from "@app/helpers/modal.helper";
import {
  formattedDateTime,
  processBlobToFile,
} from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { settingsApi } from "../../api/settings.api";
import { UserStatusEnum } from "../../constants/user-status.constant";
import {
  activateUser,
  // deactivateUser,
  deleteUser,
  getUsers,
} from "../../redux/users.slice";
import { getDepartments } from "../../redux/vehicles.slice";
import { UserDef } from "../../types/user.types";
import styles from "./UserScreen.module.scss";
import UsersModal from "./components/UsersModal/UsersModal";

const UsersScreen = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const users = useSelector((state: RootState) => state.users.users);
  const loadingUsers = useSelector(
    (state: RootState) => state.users.loadingUsers
  );
  const UserRole = useSelector((state: RootState) => state.auth.user?.role);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { search, updateSearchParams } = useSearchParams();

  useEffect(() => {
    if (UserRole) {
      if (search.searchBy && search.page) {
        dispatch(
          getUsers({
            page:
              search.searchBy && search.page > 1 ? search?.page : DEFAULT_PAGE,
            pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
            q: search?.searchBy?.toString(),
          })
        );
      }
      if (search.searchBy && !search.page) {
        dispatch(
          getUsers({
            page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
            pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
            q: search?.searchBy?.toString(),
          })
        );
      }
      if (!search.searchBy && search.page) {
        dispatch(
          getUsers({
            page: search.searchBy ? DEFAULT_PAGE : search.page ?? DEFAULT_PAGE,
            pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
            q: search?.searchBy?.toString(),
          })
        );
      }

      if (!search.searchBy && !search.page) {
        dispatch(
          getUsers({
            page: DEFAULT_PAGE,
            pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
            q: search?.searchBy?.toString(),
          })
        );
      }
    }
  }, [
    dispatch,
    search?.page,
    search?.pageSize,
    search?.searchBy,
    triggerRefetch,
    UserRole,
  ]);

  useMount(() => {
    dispatch(getDepartments({ pageSize: MAX_PAGE_SIZE }));
  });

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (user: UserDef) => {
    updateSearchParams(modalAction.edit(user.id.toString()));
  };

  /**
   * Handle export report
   */
  const handleExportUsers = async () => {
    try {
      setLoadingExport(previousLoading => !previousLoading);
      const fileName = `${t(
        "settingsUsers.exportUsersFileName"
      )}_${formattedDateTime(
        new Date().toString(),
        DateTimeFormats.FILE_NAME_TIME
      )}`;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const blobData: any = await settingsApi.getUsersExportData();
      processBlobToFile(blobData, `${fileName}.csv`, EXPORTED_CONTENT_TYPE.CSV);
    } finally {
      setLoadingExport(previousLoading => !previousLoading);
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef) => {
    updateSearchParams(modalAction.close());
    if (action.triggerRefetch) {
      setTriggerRefetch(!triggerRefetch);
    }
  };

  const handleDelete = async (user: UserDef) => {
    const response = await dispatch(deleteUser(user.id));
    if (deleteUser.fulfilled.match(response)) {
      AlertMessage.success(t("settingsUsers.deleteSuccessMessage"));
      setTriggerRefetch(!triggerRefetch);
    }
    if (deleteUser.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  /**
   * Handle User Status Update
   */
  const handleActionMenu = async (key: string, user: UserDef) => {
    if (key === UserStatusEnum.ACTIVATE) {
      const payload = {
        is_active: true,
        first_name: user.first_name,
        username: user.username,
        role: user.role,
        id: user.id,
      };

      const data = {
        id: user.id,
        payload,
      };
      const response = await dispatch(activateUser(data));
      if (activateUser.fulfilled.match(response)) {
        AlertMessage.success(t("settingsUsers.activateSuccessMessage"));
        setTriggerRefetch(!triggerRefetch);
      }
      if (activateUser.rejected.match(response)) {
        AlertMessage.error(
          (response.payload as string) ?? t("default.errorMessage")
        );
      }
    } else if (key === UserStatusEnum.DEACTIVATE) {
      const payload = {
        is_active: false,
        first_name: user.first_name,
        username: user.username,
        role: user.role,
        id: user.id,
      };
      const data = {
        id: user.id,
        payload,
      };
      const response = await dispatch(activateUser(data));

      if (activateUser.fulfilled.match(response)) {
        AlertMessage.success(t("settingsUsers.deactivateSuccessMessage"));
        setTriggerRefetch(!triggerRefetch);
      }
      if (activateUser.rejected.match(response)) {
        AlertMessage.error(
          (response.payload as string) ?? t("default.errorMessage")
        );
      }
    }
  };

  const menu: ActionMenuDef = useMemo(
    () => [
      {
        key: UserStatusEnum.ACTIVATE,
        label: t("settingsUsers.activateUser"),
      },
      {
        key: UserStatusEnum.DEACTIVATE,
        label: t("settingsUsers.deactivateUser"),
      },
    ],
    [t]
  );

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsUsers.title")}
        filters={
          <SearchFilters
            placeholder={t("settingsUsers.searchUserPlaceholder")}
            maxWidth={481}
          />
        }
        actions={
          <>
            <Button
              type="default"
              size="large"
              loading={loadingExport}
              onClick={handleExportUsers}
            >
              {t("settingsUsers.buttonExportUsers")}
            </Button>
            <Button type="primary" size="large" onClick={handleAdd}>
              {t("settingsUsers.buttonAddUser")}
            </Button>
          </>
        }
      />

      <TableView
        dataSource={users.data}
        rowKey="username"
        actionTitle={t("default.columnAction")}
        onEdit={handleEdit}
        onDelete={handleDelete}
        loading={loadingUsers}
        pagination={{ ...users.pagination }}
        onActionMenu={handleActionMenu}
        actionMenu={menu}
        actionWidth={250}
      >
        <Table.Column
          key="username"
          dataIndex="username"
          ellipsis
          title={t("settingsUsers.columnUsername")}
        />
        <Table.Column
          key="email"
          dataIndex="email"
          ellipsis
          title={t("settingsUsers.columnEmail")}
        />
        <Table.Column
          key="phone"
          dataIndex="phone"
          title={t("settingsUsers.columnPhoneNo")}
        />
        <Table.Column
          key="first_name"
          dataIndex="first_name"
          ellipsis
          title={t("settingsUsers.columnFirstName")}
        />
        <Table.Column
          key="last_name"
          dataIndex="last_name"
          ellipsis
          title={t("settingsUsers.columnLastName")}
        />
        <Table.Column
          key="role"
          dataIndex="role"
          title={t("settingsUsers.columnUserType")}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsUsers.columnLastModified")}
          // eslint-disable-next-line camelcase
          render={(lastModified: UserDef["last_modified"]) => {
            return (
              lastModified?.time &&
              formattedDateTime(lastModified.time, DateFormats.DAY_MONTH_YEAR)
            );
          }}
        />
        <Table.Column
          key="last_modified"
          dataIndex="last_modified"
          title={t("settingsUsers.columnModifiedBy")}
          render={(lastModified: UserDef["last_modified"]) => {
            return lastModified?.user_name.split("@")[0];
          }}
        />
        <Table.Column
          key="isActive"
          dataIndex="is_active"
          title={t("settingsUsers.columnUserStatus")}
          render={(isActive: UserDef["is_active"]) => {
            return isActive ? (
              <span className={styles.active}>
                {t("settingsUsers.userActiveStatus")}
              </span>
            ) : (
              <span className={styles.deActive}>
                {t("settingsUsers.userDeActiveStatus")}
              </span>
            );
          }}
        />
      </TableView>

      <UsersModal onClose={action => handleCloseModal(action)} />
    </ContentLayout>
  );
};

export default UsersScreen;
