import axios from "axios";

import { documentApi } from "@app/api/api";

import { FileUploadEndpointsEnum } from "../constants/file-upload.endpoints";
import {
  UploadGrantDef,
  FileRequestDef,
  FileUploadDef,
} from "../types/file-upload.types";

const requestFileUploadGrant = (fields: FileRequestDef) => {
  return documentApi.post(FileUploadEndpointsEnum.DOCUMENTS, fields);
};

const getFileMetaData = (documentId: UploadGrantDef["document_id"]) => {
  return documentApi.get(`${FileUploadEndpointsEnum.DOCUMENTS}/${documentId}`);
};

const uploadFile = (fields: FileUploadDef) => {
  return axios.put(fields.fileUploadUri, fields.file, {
    headers: {
      "Content-type": fields.file.type,
    },
  });
};

export const fileUploadApi = {
  requestFileUploadGrant,
  getFileMetaData,
  uploadFile,
};
