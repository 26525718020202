import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import DatePicker from "@app/components/atoms/DatePicker/DatePicker";
import { DateTimeFormats, TimeFormats } from "@app/constants/date.constants";
import { resetAndFormatToISO } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";

import styles from "./MessageFilters.module.scss";

interface MessageFilterFormDef {
  vehicleNo?: string;
  driver?: string;
  from?: string;
  to?: string;
}

const { Option } = Select;

const MessageFilters = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { search, setSearchParams } = useSearchParams<MessageFilterFormDef>();

  const drivers = useSelector((state: RootState) => state.users.drivers.data);

  const onFinish = (values: MessageFilterFormDef) => {
    values.to = values.to ? resetAndFormatToISO(values.to) : undefined;
    values.from = values.from ? resetAndFormatToISO(values.from) : undefined;

    setSearchParams(values as MessageFilterFormDef);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const setFilterValue = (values?: MessageFilterFormDef) => {
    form.setFieldsValue({
      vehicleNo: values?.vehicleNo?.toString() ?? "",
      driver: values?.driver?.toString() ?? undefined,
      from: values?.from ? new Date(values?.from) : undefined,
      to: values?.to ? new Date(values?.to) : undefined,
    });
  };

  useMount(() => {
    setFilterValue(search);
  });

  return (
    <div className={styles.messageFilterWrapper}>
      <Form
        onFinish={onFinish}
        className={styles.messageFilterForm}
        layout="inline"
        form={form}
      >
        <Form.Item name="vehicleNo" label={t("reporting.labelVehicleNo")}>
          <Input
            size="middle"
            onPressEnter={handleFormSubmit}
            onBlur={handleFormSubmit}
            placeholder={t("reporting.placeholderVehicleNo")}
          />
        </Form.Item>
        <Form.Item name="driver" label={t("reporting.labelDriver")}>
          <Select
            showSearch
            placeholder={t("reporting.placeholderDriver")}
            size="middle"
            className={styles.multipleSelection}
            optionFilterProp="children"
            onChange={handleFormSubmit}
            allowClear
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {drivers?.map((driver: any) => (
              <Option key={driver.id} value={driver.id?.toString()}>
                {`${driver.first_name} ${driver.last_name}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="from" label={t("reporting.labelFromDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderFromDate")}
            showTime={{ format: TimeFormats.SHORT }}
            format={DateTimeFormats.TIME_DAY_MONTH_YEAR}
          />
        </Form.Item>
        <Form.Item name="to" label={t("reporting.labelToDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderToDate")}
            showTime={{ format: TimeFormats.SHORT }}
            format={DateTimeFormats.TIME_DAY_MONTH_YEAR}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default MessageFilters;
