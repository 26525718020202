import { memo, useEffect } from "react";

import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

import { ChassisCheckReportRequestDef } from "../../../../types/reporting.types";
import styles from "./NotesForm.module.scss";

interface NotesFormProps {
  notes?: ChassisCheckReportRequestDef["notes"];
  onSubmit: (formValue: ChassisCheckReportRequestDef) => void;
}

const NotesForm = memo(({ notes, onSubmit }: NotesFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      notes,
    });
  }, [form, notes]);

  return (
    <Form
      name="notesForm"
      form={form}
      className={styles.notesForm}
      onFinish={onSubmit}
    >
      <Form.Item
        name="notes"
        rules={[{ max: 1000 }]}
        className={styles.notesFormItem}
      >
        <Input.TextArea
          rows={2}
          autoSize={{ minRows: 2, maxRows: 5 }}
          showCount
          placeholder={t("fleetManagerMessages.messagePlaceholder")}
        />
      </Form.Item>
      <Form.Item className={styles.notesFormItem}>
        <Button type="primary" size="middle" htmlType="submit">
          {t("reportingChassisCheck.buttonSave")}
        </Button>
      </Form.Item>
    </Form>
  );
});

export default NotesForm;
