import { memo } from "react";

import { Empty, Tooltip } from "antd";
import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";

import {
  PredefinedMessageDef,
  PredefinedMessageResDef,
} from "../../../../types/messages.types";
import styles from "./PredefinedMessages.module.scss";

interface PredefinedMessagesProps {
  messages: PredefinedMessageDef[];
  loading: boolean;
  messageBtnSize?: "small" | "middle";
  className?: string;
  onSelectPredefinedMessage: (id: PredefinedMessageDef["id"]) => void;
}

const PredefinedMessages = memo(
  ({
    messages,
    loading,
    messageBtnSize = "middle",
    className,
    onSelectPredefinedMessage,
  }: PredefinedMessagesProps) => {
    const messageContent = (message: any) => {
      return (
        <div
          className={cx({
            [styles.ellipsisMsg]: message?.localizations?.length > 50,
          })}
        >
          {message}
        </div>
      );
    };

    return (
      <div className={cx(styles.predefinedMessageWrapper, className)}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {messages.length ? (
              messages.map((msg: any) => (
                <Button
                  className={styles.predefinedMsgBtn}
                  onClick={() => onSelectPredefinedMessage(msg.id)}
                  size={messageBtnSize}
                  key={msg.id}
                >
                  {(msg.localizations?.length > 50 && (
                    <Tooltip
                      overlayClassName={styles.predefinedMessageTooltip}
                      placement="topLeft"
                      title={
                        msg.localizations?.find(
                          (predefinedMessage: PredefinedMessageResDef) =>
                            predefinedMessage.language_code === "en"
                        )?.localized_text
                      }
                    >
                      {messageContent(
                        msg.localizations?.find(
                          (predefinedMessage: PredefinedMessageResDef) =>
                            predefinedMessage.language_code === "en"
                        )?.localized_text
                      )}
                    </Tooltip>
                  )) ||
                    (msg.localizations.length > 0 &&
                      messageContent(
                        msg.localizations?.find(
                          (predefinedMessage: PredefinedMessageResDef) =>
                            predefinedMessage.language_code === "en"
                        )?.localized_text
                      ))}
                </Button>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        )}
      </div>
    );
  }
);

export default PredefinedMessages;
