import { useEffect, useState } from "react";

import { message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import {
  // UserRole,
  UserRoleForPredefinedMessage,
} from "@app/constants/user.constants";
import * as modalAction from "@app/helpers/modal.helper";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import PredefinedMessageModal from "../../components/PredefinedMessageModal/PredefinedMessageModal";
import PredefinedMessageTable from "../../components/PredefinedMessageTable/PredefinedMessageTable";
import { PredefinedMessageDirectionEnum } from "../../constants/setting.constants";
import {
  getPredefinedMessages,
  deletePredefinedMessage,
} from "../../redux/predefined-messages.slice";
import { PredefinedMessageDef } from "../../types/predefined-message.types";

const DriverMessageScreen = () => {
  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const predefinedMessages = useSelector(
    (state: RootState) => state.predefinedMessages.predefinedMessages
  );
  const loadingPredefinedMessages = useSelector(
    (state: RootState) => state.predefinedMessages.loadingPredefinedMessages
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { search, updateSearchParams } = useSearchParams();

  useEffect(() => {
    dispatch(
      getPredefinedMessages({
        page: search?.page ?? DEFAULT_PAGE,
        pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
        directionType: UserRoleForPredefinedMessage.DRIVER,
      })
    );
  }, [dispatch, search?.page, search?.pageSize, triggerRefetch]);

  const handleAdd = () => {
    updateSearchParams(modalAction.add());
  };

  const handleEdit = (predefinedMessage: PredefinedMessageDef) => {
    updateSearchParams(modalAction.edit(predefinedMessage.id.toString()));
  };

  const handleDelete = async (predefinedMessage: PredefinedMessageDef) => {
    const response = await dispatch(
      deletePredefinedMessage({
        id: predefinedMessage.id,
        directionType: UserRoleForPredefinedMessage.DRIVER,
      })
    );
    if (deletePredefinedMessage.fulfilled.match(response)) {
      AlertMessage.success(
        t("settingsPredefinedMessages.deleteSuccessMessage")
      );
      setTriggerRefetch(!triggerRefetch);
    }
    if (deletePredefinedMessage.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
    }
  };

  const handleCloseModal = (action: ModalCloseActionDef) => {
    updateSearchParams(modalAction.close());
    if (action.triggerRefetch) {
      setTriggerRefetch(!triggerRefetch);
    }
  };

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("settingsPredefinedMessages.driverMessageTitle")}
        subTitle={t("settingsPredefinedMessages.driverMessageSubtitle")}
        actions={
          <Button type="primary" size="large" onClick={handleAdd}>
            {t("settingsPredefinedMessages.buttonCreateDriverMessage")}
          </Button>
        }
      />
      <PredefinedMessageTable
        data={predefinedMessages.data}
        pagination={predefinedMessages.pagination}
        loading={loadingPredefinedMessages}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <PredefinedMessageModal
        direction_type={PredefinedMessageDirectionEnum.DIRECTION_TYPE_DRIVER}
        onClose={action => handleCloseModal(action)}
      />
    </ContentLayout>
  );
};

export default DriverMessageScreen;
