import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StoredMessageDef } from "../types/messages.types";

type MessagesState = {
  typedMessage: string;
};

const initialState: MessagesState = {
  typedMessage: "",
};

const messageStoreSlice = createSlice({
  name: "messageStore",
  initialState,
  reducers: {
    setTypedMessage: (state, action: PayloadAction<StoredMessageDef>) => {
      state.typedMessage = action.payload.typedMessage;
    },
    clearTypedMessage: state => {
      state.typedMessage = "";
    },
  },
});

export const { setTypedMessage, clearTypedMessage } = messageStoreSlice.actions;

export default messageStoreSlice.reducer;
