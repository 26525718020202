// import { useState } from "react";

import { PayloadAction } from "@reduxjs/toolkit";
import { Form, Input, Select, Spin } from "antd";
import { subDays } from "date-fns";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import DatePicker from "@app/components/atoms/DatePicker/DatePicker";
import { DateTimeFormats, TimeFormats } from "@app/constants/date.constants";
import {
  getDriverByNo,
  getDrivers,
  setDrivers,
  DriverDef,
} from "@app/features/settings/settings";
import { resetAndFormatToISO } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import styles from "./GraphFilter.module.scss";

const { Option } = Select;

interface PositionFilterFormDef {
  vehicles?: string;
  drivers?: string;
  from: string | Date;
  to: string | Date;
}

const GraphFilters = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { search, setSearchParams } = useSearchParams<PositionFilterFormDef>();
  const { drivers, loadingDrivers } = useAppSelector(state => ({
    drivers: state.drivers.drivers,
    loadingDrivers: state.drivers.loadingDrivers,
  }));
  const dispatch = useAppDispatch();

  const updateSearchParams = (values: PositionFilterFormDef) => {
    values.to = resetAndFormatToISO(values.to);
    values.from = resetAndFormatToISO(values.from);

    setSearchParams(values as PositionFilterFormDef);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const setFilterValue = (values?: Partial<PositionFilterFormDef>) => {
    const today = new Date();
    const yesterday = subDays(today, 1);
    const newValues = {
      vehicles: values?.vehicles?.toString() ?? undefined,
      drivers: values?.drivers ?? undefined,
      from: values?.from ? new Date(values?.from) : yesterday,
      to: values?.to ? new Date(values?.to) : today,
    };
    form.setFieldsValue(newValues);

    if (!values?.from || !values.to) {
      updateSearchParams(newValues);
    }
  };

  // Create promise that fetches driver by number
  const fetchDriverByNo = (
    driver: DriverDef["id"]
  ): Promise<PayloadAction<DriverDef>> => {
    return new Promise(resolve => {
      dispatch(getDriverByNo(driver)).then(data =>
        resolve(data as PayloadAction<DriverDef>)
      );
    });
  };

  useMount(() => {
    // Fetch drivers from search query
    async function handleMultipleDriversByNo(searchDrivers: DriverDef["id"][]) {
      const requests: Promise<PayloadAction<DriverDef>>[] = [];
      searchDrivers.forEach(async driver => {
        requests.push(fetchDriverByNo(driver));
      });
      // Wait for all api calls to finish
      const results = await Promise.all(requests);
      const responses = results.filter(result => !(result instanceof Error));

      if (responses?.length) {
        const fetchedDrivers: DriverDef[] = [];
        responses.forEach(response => {
          if (getDriverByNo.fulfilled.match(response)) {
            fetchedDrivers.push(response.payload);
          }
        });
        // Update redux with fetched drivers
        dispatch(setDrivers(fetchedDrivers));
      }
    }

    // Set form field values from search on mount
    setFilterValue(search);

    // Fetch driver data to populate filter
    if (search?.drivers?.length) {
      if (Array.isArray(search.drivers)) {
        // If search query contains multiple drivers
        handleMultipleDriversByNo(search.drivers);
      } else {
        // If search query contains single driver
        dispatch(getDrivers({ driverNo: parseInt(search.drivers, 10) }));
      }
    }
  });
  // TODO: Appropriate api need to implement for position reporting
  /**
   * Handle export report
   */
  //   const handleExportReport = async () => {
  //     try {
  //       setLoadingExportingData(true);
  //       const queryParams: GraphFilterDef = qs.parse(location.search);
  //       const apiArguments: GraphFilterDef = {};
  //       let fileName = t("reporting.sessionExportFileNameDefault");

  //       if (queryParams && Object.keys(queryParams).length !== 0) {
  //         if (queryParams.vehicles) {
  //           apiArguments.vehicles = queryParams.vehicles.toString();
  //           fileName += `_${t("reporting.labelVehicleNo")}_${
  //             apiArguments.vehicles
  //           }`;
  //         }

  //         if (queryParams.drivers) {
  //           apiArguments.drivers = queryParams.drivers.toString();
  //           fileName += `_${t("reporting.labelDriver")}_${apiArguments.drivers}`;
  //         }

  //         if (queryParams.from) {
  //           apiArguments.from = queryParams.from.toString();
  //           fileName += `_${t("reporting.labelFromDate")}_${apiArguments.from}`;
  //         }

  //         if (queryParams.to) {
  //           apiArguments.to = queryParams.to.toString();
  //           fileName += `_${t("reporting.labelToDate")}_${apiArguments.to}`;
  //         }
  //       }
  //         // TODO: Appropriate api need to implement for position reporting
  //         const blobData = await reportingApi.getSessionReportExportData(
  //           apiArguments
  //         );
  //         processBlobToFile(blobData, `${fileName}.csv`, EXPORTED_CONTENT_TYPE.CSV);
  //     } finally {
  //       setLoadingExportingData(false);
  //     }
  //   };

  const handleSearchDrivers = debounce((inputSearch = "") => {
    dispatch(getDrivers({ driverNo: inputSearch }));
  }, 500);

  return (
    <div className={styles.sessionFilterWrapper}>
      <Form
        onFinish={updateSearchParams}
        className={styles.sessionFilterForm}
        layout="inline"
        form={form}
      >
        <Form.Item name="vehicles" label={t("reporting.labelVehicleNo")}>
          <Input
            size="middle"
            onPressEnter={handleFormSubmit}
            onBlur={handleFormSubmit}
            placeholder={t("reporting.placeholderVehicleNo")}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item
          name="drivers"
          rules={[{ type: "array" }]}
          label={t("reporting.labelDriver")}
          className={styles.multipleSelection}
        >
          <Select
            showSearch
            filterOption={false}
            onChange={handleFormSubmit}
            allowClear
            mode="multiple"
            placeholder={t("reporting.placeholderDriver")}
            onSearch={handleSearchDrivers}
            loading={loadingDrivers}
            notFoundContent={
              loadingDrivers ? (
                <Spin size="small" />
              ) : (
                t("default.noSearchResult")
              )
            }
            dropdownMatchSelectWidth={false}
          >
            {drivers.data?.map(driver => (
              <Option key={driver.id} value={driver.id}>
                {`${driver.first_name} ${driver.last_name} (${driver.id})`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="from" label={t("reporting.labelFromDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderFromDate")}
            allowClear={false}
            showTime={{ format: TimeFormats.SHORT }}
            format={DateTimeFormats.TIME_DAY_MONTH_YEAR}
          />
        </Form.Item>
        <Form.Item name="to" label={t("reporting.labelToDate")}>
          <DatePicker
            size="middle"
            onChange={handleFormSubmit}
            placeholder={t("reporting.placeholderToDate")}
            allowClear={false}
            showTime={{ format: TimeFormats.SHORT }}
            format={DateTimeFormats.TIME_DAY_MONTH_YEAR}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default GraphFilters;
