import {
  memo,
  useState,
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
} from "react";

import { Input, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next/";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

import SpinWrapper from "@app/components/atoms/SpinWrapper/SpinWrapper";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import { MAX_PAGE_SIZE } from "@app/constants/pagination.constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { resetSelectedVehicles } from "../../../fleet-manager/redux/selected-vehicles.slice";
import { filterGroupListByName } from "../../helpers/settings.helpers";
import {
  getVisibleGroups,
  updateVisibleGroups,
} from "../../redux/dispatcher-config.slice";
import { getVehicleGroups } from "../../redux/vehicle-groups-list.slice";
import {
  VisibleGroupDef,
  VehicleGroupDef,
  VehicleGroupSelectionDef,
} from "../../types/vehicle-group.types";
import styles from "./MapMessengerConfigScreen.module.scss";
import GroupSelectionBox from "./components/GroupSelectionBox/GroupSelectionBox";

const { Search } = Input;

const MapMessengerConfigScreen = memo(() => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [vehicleGroups, setVehicleGroups] = useState<
    VehicleGroupSelectionDef[]
  >([]);
  const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<
    VehicleGroupSelectionDef[]
  >([]);

  const visibleGroups = useSelector(
    (state: RootState) => state.dispatcherConfig.visibleGroups
  );
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFilteredVehicleGroups(vehicleGroups);
  }, [vehicleGroups]);

  useMount(() => {
    const getAllVehicleGroups = async () => {
      setLoading(true);
      const response = await dispatch(
        getVehicleGroups({
          pageSize: MAX_PAGE_SIZE,
        })
      );
      if (getVehicleGroups.fulfilled.match(response)) {
        const groups = response.payload?.data?.map((group: VehicleGroupDef) => {
          return {
            id: group.id,
            name: group.name,
          };
        });
        setVehicleGroups(groups);
        setLoading(false);
      }
      if (getVehicleGroups.rejected.match(response)) {
        setVehicleGroups([]);
        setLoading(false);
      }
    };
    getAllVehicleGroups();
    dispatch(getVisibleGroups(userId));
  });

  const { t } = useTranslation();

  const updateDispatchersVisibleGroups = async (fields: any) => {
    setLoading(true);
    const response = await dispatch(updateVisibleGroups({ fields, userId }));
    if (updateVisibleGroups.fulfilled.match(response)) {
      const noOfGroups = response?.payload?.length ?? 0;
      AlertMessage.success(
        t("settingsMapMessengerConfig.addGroupSuccess", { noOfGroups })
      );
      dispatch(getVisibleGroups(userId));
      setLoading(false);
      dispatch(resetSelectedVehicles());
    }
    if (updateVisibleGroups.rejected.match(response)) {
      AlertMessage.error(
        (response.payload as string) ?? t("default.errorMessage")
      );
      setLoading(false);
    }
  };

  const handleSearch = (
    value: string,
    event?:
      | ChangeEvent<HTMLInputElement>
      | MouseEvent<HTMLElement>
      | KeyboardEvent<HTMLInputElement>
  ) => {
    event?.preventDefault();
    const filteredResult = filterGroupListByName(vehicleGroups, value.trim());
    setFilteredVehicleGroups(filteredResult);
  };

  const handleSubmit = (selectedGroups: VisibleGroupDef["groupIds"][]) => {
    const groups = selectedGroups?.filter(
      group => group.toString() && group.toString()
    );
    updateDispatchersVisibleGroups(groups);
  };

  return (
    <ContentLayout className={styles.configContent}>
      <ScreenTitle
        title={t("settingsMapMessengerConfig.title")}
        subTitle={t("settingsMapMessengerConfig.subTitle")}
        filters={
          <Search
            placeholder={t("settingsMapMessengerConfig.groupSearchPlaceholder")}
            size="large"
            style={{ width: 420 }}
            allowClear
            value={searchValue}
            onChange={e => setSearchValue(e.target.value.trim())}
            onSearch={handleSearch}
          />
        }
      />
      <SpinWrapper loading={loading}>
        <GroupSelectionBox
          className={styles.groupSelectionBox}
          items={vehicleGroups}
          availableItems={filteredVehicleGroups}
          visibleItems={visibleGroups?.map((group: any) => group.id)}
          availableTitle={t("settingsMapMessengerConfig.hiddenGroupsTitle")}
          visibleTitle={t("settingsMapMessengerConfig.visibleGroupsTitle")}
          deselectAllTitle={t("default.deselectAll")}
          emptySelectedMessage={t(
            "settingsMapMessengerConfig.emptySelectionMessage"
          )}
          onChangeSelection={handleSubmit}
        />
      </SpinWrapper>
    </ContentLayout>
  );
});

export default MapMessengerConfigScreen;
