import { createSlice } from "@reduxjs/toolkit";

import { SimplifiedVehicleGroupDef } from "../types/vehicle-group.types";

type SelectedVehiclesState = {
  selectedVehicles: SimplifiedVehicleGroupDef[] | null;
};

const initialState: SelectedVehiclesState = {
  selectedVehicles: null,
};

const selectedVehiclesSlice = createSlice({
  name: "selectedVehicles",
  initialState,
  reducers: {
    setSelectedVehicles: (state, action) => {
      state.selectedVehicles = action.payload;
    },
    resetSelectedVehicles: state => {
      state.selectedVehicles = initialState.selectedVehicles;
    },
  },
});

export const {
  setSelectedVehicles,
  resetSelectedVehicles,
} = selectedVehiclesSlice.actions;

export default selectedVehiclesSlice.reducer;
