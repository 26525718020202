export enum UserRole {
  DRIVER = "Driver",
  DISPATCHER = "Dispatcher",
  ADMIN = "Administrator",
}

export enum UserManagementRoles {
  DISPATCHER = "Dispatcher",
  ADMIN = "Administrator",
}

export enum UserRoleForPredefinedMessage {
  DRIVER = 1,
  DISPATCHER = 0,
}
