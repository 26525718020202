export enum FleetManagerEndpointsEnum {
  MESSAGES = "v2/messenger/dispatcher", // For getting full conversation in message thread with vehicle No [USED]
  MESSAGES_INCOMING = "/v2/messenger/dispatcher?direction=1",
  MESSAGES_UNREAD = "/v2/messenger/dispatcher/unread_message_count",
  MESSAGES_THREAD = "/dispatcher/messages/:messageId/thread", // Previously used for getting conversation in message thread [NOT USED NOW]
  MESSAGES_PREDEFINED = "v2/predefined/messages?messageDirection=DISPATCHER_TO_DRIVER",
  VEHICLE_GROUPS_WITH_STATIC_VEHICLES = "/dispatcher/groups",
  VEHICLE_GROUPS_WITH_VEHICLES = "/v2/users",
  VEHICLES_POSITIONS = "/v2/reporting/positions",
  MESSAGES_OUTGOING = "/v2/messenger/dispatcher?direction=0",
  GROUP_MESSAGE_SENT = "/v2/messenger/dispatcher/groupmessage",
}
