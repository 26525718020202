import { ReactNode } from "react";

import cx from "classnames";

import styles from "./ContentLayout.module.scss";

type ContentLayoutProps = {
  children: ReactNode;
  className?: string;
};

const ContentLayout = ({ className, children }: ContentLayoutProps) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

export default ContentLayout;
