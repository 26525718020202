import _compact from "lodash/compact";
import _uniq from "lodash/uniq";
import _uniqBy from "lodash/uniqBy";
import _without from "lodash/without";

import { GoogleMapDefaults } from "@app/constants/map.constants";

import {
  SimplifiedVehicleGroupDef,
  SimplifiedVehicleDef,
} from "../types/vehicle-group.types";
import { GeoLocationDef } from "../types/vehicle-position.types";

/**
 * Filter vehicle groups by name
 */
export const filterListByName = (
  items: SimplifiedVehicleGroupDef[],
  value: string
) => {
  return items?.filter(item => {
    return (
      item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
      (item.vehicles?.length &&
        item.vehicles.filter(
          (vehicle: SimplifiedVehicleDef) =>
            vehicle.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        ).length)
    );
  });
};

/**
 * Filter vehicle groups and vehicles by vehicle name
 */
export const filterVehicleGroupsByVehicleName = (
  items: SimplifiedVehicleGroupDef[],
  value: string
) => {
  return items
    .map(item => {
      return {
        ...item,
        vehicles:
          (item.vehicles?.length &&
            item.vehicles.filter(
              (vehicle: SimplifiedVehicleDef) =>
                vehicle.name?.toLowerCase().indexOf(value.toLowerCase()) !== -1
            )) ||
          [],
      };
    })
    .filter(item => item.vehicles?.length);
};

/**
 * Filter vehicles from vehicle groups
 * @param groups
 */
export const filterVehiclesFromGroups = (
  groups: SimplifiedVehicleGroupDef[]
) => {
  return groups
    .filter(item => item.vehicles)
    .map(item => item.vehicles)
    .flat()
    .map(item => item?.id)
    .filter((v, i, a) => a.indexOf(v) === i);
};

/**
 * Get selected group's vehicle ids
 */
const getGroupsVehicleIds = (record: SimplifiedVehicleGroupDef) => {
  return record.vehicles?.map(vehicle => vehicle.id);
};

/**
 * Get selected identical vehicles key from the list of vehicle groups
 */
export const getIdenticalVehicles = (
  filteredVehicleGroups: SimplifiedVehicleGroupDef[],
  record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
  vehicleIds?: string[]
) => {
  return _compact(
    _compact(
      filteredVehicleGroups.flatMap(vehicleGroup => vehicleGroup.vehicles)
    ).map(vehicle => {
      if (
        "isGroup" in record
          ? vehicleIds?.includes(vehicle.id)
          : vehicle.id === record.id
      )
        return vehicle;
      return undefined;
    })
  );
};

/**
 * Determine removable groups ids if any vehicles from a group or a group is unselected
 */
const getRemovableGroupIds = (
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[],
  record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
  vehicleIds?: string[]
) => {
  const rows = [...selectedRows];
  return _compact(
    rows.map(row => {
      if (
        "isGroup" in record
          ? vehicleIds?.includes(row.id)
          : row.id === record.id
      ) {
        if ("parentId" in row) return row.parentId;
      }
      return undefined;
    })
  );
};

/**
 * Add selected group rows and its vehicles upon selection
 */
const addSelectedGroup = (
  record: SimplifiedVehicleGroupDef,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[]
) => {
  const vehicleIds = getGroupsVehicleIds(record);
  if (vehicleIds) {
    const identicalVehicles = getIdenticalVehicles(
      filteredVehicleGroups,
      record,
      vehicleIds
    );
    // Keep the identical rows and the selected group as well
    return _uniq([...selectedRows, ...identicalVehicles, record]);
  }
  return selectedRows;
};

/**
 * Remove selected group rows and its vehicles upon unselect
 */
const removeSelectedGroup = (
  record: SimplifiedVehicleGroupDef,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[]
) => {
  const rows = [...selectedRows];
  const vehicleIds = getGroupsVehicleIds(record);
  const removableGroups = getRemovableGroupIds(
    selectedRows,
    record,
    vehicleIds
  );
  // Keep the selected groups id to removable groups as well
  removableGroups.push(record.id);
  return rows.filter(
    row => !vehicleIds?.includes(row.id) && !removableGroups?.includes(row.id)
  );
};

/**
 * Add selected vehicle rows upon selection
 */
const addSelectedVehicle = (
  record: SimplifiedVehicleDef,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[]
) => {
  const identicalVehicles = getIdenticalVehicles(filteredVehicleGroups, record);
  return _uniq([...selectedRows, ...identicalVehicles]);
};

/**
 * Remove selected vehicle rows upon unselect
 */
export const removeSelectedVehicle = (
  record: SimplifiedVehicleDef,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[]
) => {
  let rows = [...selectedRows];
  // Get removable groups
  const removableGroups = getRemovableGroupIds(
    selectedRows,
    record as SimplifiedVehicleDef
  );
  // Keep the selected vehicles group id to removable groups as well
  removableGroups.push(record.parentId);
  // Remove unselected rows
  rows = rows?.filter(
    (row: SimplifiedVehicleDef | SimplifiedVehicleGroupDef) =>
      row.id !== record.id
  );
  // Remove associated groups which have identical vehicles
  return rows.filter(row => !removableGroups.includes(row.id));
};

/**
 * Handle selected rows tree table manually
 */
export const handleSelection = (
  record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
  selected: boolean,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[]
) => {
  const filteredSelectedRows: any =
    _without(selectedRows as any, undefined) || [];

  if ("isGroup" in record) {
    if (selected) {
      const selectedItems = addSelectedGroup(
        record,
        filteredSelectedRows,
        filteredVehicleGroups
      );
      return selectedItems.map(row => row.key);
    }
    const selectedItems = removeSelectedGroup(record, filteredSelectedRows);
    return selectedItems.map(row => row.key);
  }
  if (selected) {
    const selectedItems = addSelectedVehicle(
      record as SimplifiedVehicleDef,
      filteredSelectedRows,
      filteredVehicleGroups
    );

    return selectedItems?.map(row => row.key);
  }
  const selectedItems = removeSelectedVehicle(
    record as SimplifiedVehicleDef,
    filteredSelectedRows
  );
  return selectedItems.map(row => row.key);
};

/**
 * Get selected rows of tree table after each select/deselect
 */
export const getSelectedRows = (
  record: SimplifiedVehicleDef | SimplifiedVehicleGroupDef,
  selected: boolean,
  selectedRows: SimplifiedVehicleDef[] | SimplifiedVehicleGroupDef[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[]
) => {
  const filteredSelectedRows: any =
    _without(selectedRows as any, undefined) || [];

  if ("isGroup" in record) {
    if (selected) {
      return addSelectedGroup(
        record,
        filteredSelectedRows,
        filteredVehicleGroups
      );
    }
    return removeSelectedGroup(record, filteredSelectedRows);
  }
  if (selected) {
    return addSelectedVehicle(
      record as SimplifiedVehicleDef,
      filteredSelectedRows,
      filteredVehicleGroups
    );
  }
  return removeSelectedVehicle(
    record as SimplifiedVehicleDef,
    filteredSelectedRows
  );
};

/**
 * Get selected vehicles as object to store
 */
export const getSelectedVehicles = (
  selectedKeys: string[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[],
  uniqueOnly = true
) => {
  const allVehicles = _compact(
    filteredVehicleGroups.flatMap(vehicleGroup => vehicleGroup.vehicles)
  );

  const selectedVehicles: SimplifiedVehicleDef[] = allVehicles.filter(
    vehicle => selectedKeys.includes(vehicle.key) && vehicle.parentId
  );

  if (uniqueOnly) {
    return _uniqBy(selectedVehicles, "id");
  }
  return selectedVehicles;
};

/**
 * Remove vehicle
 */
export const handleRemoveVehicle = (
  record: SimplifiedVehicleDef,
  selectedKeys: string[],
  filteredVehicleGroups: SimplifiedVehicleGroupDef[]
) => {
  const selectedRows = getSelectedVehicles(
    selectedKeys,
    filteredVehicleGroups,
    false
  );
  return removeSelectedVehicle(record, selectedRows).map(
    vehicle => vehicle.key
  );
};

/**
 * Get all vehicles from group
 * @param group
 */
export const getAllVehiclesFromGroup = (
  group: SimplifiedVehicleGroupDef[],
  uniqueOnly = true
) => {
  const vehicles = _compact(
    group.flatMap(vehicleGroup => vehicleGroup.vehicles)
  );

  if (uniqueOnly) {
    return _uniqBy(vehicles, "id");
  }

  return vehicles;
};

/**
 * Get geo location center from given set of locations
 * @param locations
 */
export const getGeoLocationCenter = (locations: GeoLocationDef[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { maps } = (window as any).google;
  let center = {
    lat: GoogleMapDefaults.MAP_CENTER_LAT,
    lng: GoogleMapDefaults.MAP_CENTER_LNG,
  };

  if (!maps) {
    return center;
  }

  const bound = new maps.LatLngBounds();
  locations.forEach(location => {
    bound.extend(new maps.LatLng(location.lat, location.lng));
  });

  center = {
    lat: bound.getCenter().lat(),
    lng: bound.getCenter().lng(),
  };
  return center;
};

/**
 * Get bounds from given set of locations
 * @param locations
 */
export const getLocationsFittedBounds = (locations: GeoLocationDef[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { maps } = (window as any).google;

  if (!maps) {
    return undefined;
  }

  const bounds = new maps.LatLngBounds();
  locations.forEach(location => {
    bounds.extend(new maps.LatLng(location.lat, location.lng));
  });

  return bounds;
};
