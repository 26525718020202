import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { fleetManagerApi } from "../api/fleet-manager.api";
import {
  MessageDef,
  SendMessageDef,
  SendGroupMessageDef,
  PredefinedMessageDef,
  MessageFilterDef,
} from "../types/messages.types";

type MessagesState = {
  messages: MessageDef[];
  unreadMessagesCount: number;
  predefinedMessages: {
    data: PredefinedMessageDef[];
    pagination: PaginationDef;
  };
  loadingMessages: boolean;
  loadingPredefinedMessages: boolean;
  isServerUnavailable: boolean;
};

const initialState: MessagesState = {
  messages: [],
  unreadMessagesCount: 0,
  predefinedMessages: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingMessages: false,
  loadingPredefinedMessages: false,
  isServerUnavailable: false,
};

export const getIncomingMessages = createAsyncThunk(
  "messages/getIncomingMessages",
  async (filter?: MessageFilterDef) => {
    try {
      const response = await fleetManagerApi.getIncomingMessages(filter);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);
export const getOutgoingMessages = createAsyncThunk(
  "messages/getOutgoingMessages",
  async (filter?: MessageFilterDef) => {
    try {
      const response = await fleetManagerApi.getIncomingMessages(filter);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const getUnreadMessagesCount = createAsyncThunk(
  "messages/getUnreadMessagesCount",
  async () => {
    try {
      const response = await fleetManagerApi.getUnreadMessageCount();
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const getPredefinedMessages = createAsyncThunk(
  "messages/getPredefinedMessages",
  async (filters: MessageFilterDef) => {
    try {
      const response = await fleetManagerApi.getPredefinedMessages(filters);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const markMessageAsRead = createAsyncThunk(
  "messages/markMessageAsRead",
  async (messageId: string) => {
    try {
      const response = await fleetManagerApi.markMessageRead(messageId);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const sendMessageToDriver = createAsyncThunk(
  "messages/sendMessageToDriver",
  async (fields: SendMessageDef, { rejectWithValue }) => {
    try {
      const response = await fleetManagerApi.sendMessageToDriver(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendGroupMessageToDriver = createAsyncThunk(
  "messages/sendGroupMessageToDriver",
  async (fields: SendGroupMessageDef, { rejectWithValue }) => {
    try {
      const response = await fleetManagerApi.sendMessageToMultipleDriver(
        fields
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setServerStatusUnavailable(state, action) {
      state.isServerUnavailable = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getIncomingMessages.pending, state => {
      state.loadingMessages = true;
    });
    builder.addCase(getIncomingMessages.fulfilled, (state, action) => {
      state.messages = action.payload.data || [];
      state.loadingMessages = false;
    });
    builder.addCase(getIncomingMessages.rejected, state => {
      state.loadingMessages = false;
    });

    builder.addCase(getPredefinedMessages.pending, state => {
      state.loadingPredefinedMessages = true;
    });
    builder.addCase(getPredefinedMessages.fulfilled, (state, action) => {
      state.loadingPredefinedMessages = false;
      state.predefinedMessages.data = action.payload.data || [];
      state.predefinedMessages.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getPredefinedMessages.rejected, state => {
      state.loadingPredefinedMessages = false;
    });
    builder.addCase(getUnreadMessagesCount.fulfilled, (state, action) => {
      state.unreadMessagesCount = action.payload.total || 0;
    });
  },
});

export const { setServerStatusUnavailable } = messagesSlice.actions;

export default messagesSlice.reducer;
