import { memo } from "react";

import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { Descriptions, Row, Col, Divider, Image } from "antd";
import { useTranslation } from "react-i18next";

import { getTokens } from "@app/features/auth/auth";

import { FALLBACK_IMAGE } from "../../../../constants/reporting.constants";
import { ChassisCheckDamagesDef } from "../../../../types/reporting.types";
import styles from "./Damages.module.scss";

const token = getTokens();
interface DamagesProps {
  damages: ChassisCheckDamagesDef[];
}

const Damages = memo(({ damages }: DamagesProps) => {
  const { t } = useTranslation();
  return (
    <>
      {(damages?.length && (
        <Row>
          {damages?.map((damage, damageIndex) => (
            <Col className={styles.damageItem} key={damage.category_name}>
              <div>{damageIndex + 1}.</div>
              <Descriptions
                column={2}
                labelStyle={{ color: "#8c8c8c" }}
                className={styles.reportDescription}
                key={damage.category_name}
              >
                <Descriptions.Item
                  label={t("reportingChassisCheck.labelDamageName")}
                >
                  {damage?.category_name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("reportingChassisCheck.labelDamageFixed")}
                >
                  {damage?.fixed ? (
                    <CheckSquareOutlined className={styles.fixedIcon} />
                  ) : (
                    <CloseSquareOutlined className={styles.notFixedIcon} />
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<CommentOutlined className={styles.commentIcon} />}
                  span={2}
                >
                  {damage?.comment}
                </Descriptions.Item>
                <Row>
                  {damage.attachments?.map((attachment, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Col span={8} key={`${attachment.file_uri}-${index}`}>
                      <Image
                        className={styles.attachment}
                        width={160}
                        src={`${attachment.file_uri}?access_token=${token}`}
                        fallback={FALLBACK_IMAGE}
                      />
                    </Col>
                  ))}
                </Row>
              </Descriptions>
              <Divider className={styles.divider} />
            </Col>
          ))}
        </Row>
      )) || <p>{t("reportingChassisCheck.noDamages")}</p>}
    </>
  );
});

export default Damages;
