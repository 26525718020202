import { memo, useEffect, useState } from "react";

import { Form, Input, Col, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";

import FormModal, { Item } from "@app/components/atoms/FormModal/FormModal";
import { ItemModalEnum } from "@app/constants/route.constants";
import useShowModal from "@app/hooks/useShowModal";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import {
  getChassisByNo,
  addNewChassis,
  updateChassis,
} from "../../../../redux/chassis.slice";
import { ChassisDef, ChassisRequestDef } from "../../../../types/chassis.types";

interface ChassisModalProps {
  onClose: (action: ModalCloseActionDef) => void;
}

interface FormValuesDef {
  chassisNo: string;
  numberPlate: string;
  chassisType: string;
}

const ChassisModal = memo(({ onClose }: ChassisModalProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showModal, action, actionId } = useShowModal();
  const [form] = Form.useForm();

  const editMode = action === ItemModalEnum.EDIT;

  const setFormValue = (chassis: ChassisDef) => {
    form.setFieldsValue({
      chassisNo: chassis.chassis_no,
      numberPlate: chassis.number_plate,
      chassisType: chassis.chassis_type,
    });
  };

  useEffect(() => {
    if (editMode && actionId) {
      const getChassis = async () => {
        setLoading(true);
        const response = await dispatch(getChassisByNo(actionId));
        if (getChassisByNo.fulfilled.match(response)) {
          setFormValue(response.payload);
          setLoading(false);
        }
        if (getChassisByNo.rejected.match(response)) {
          setLoading(false);
          AlertMessage.error(t("default.errorMessage"));
        }
      };
      getChassis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, editMode]);

  const showAlertMessage = (isSuccess: boolean, message: string) => {
    setLoading(false);
    if (isSuccess) {
      AlertMessage.success(message);
      form.resetFields();
      onClose({ triggerRefetch: true });
    } else {
      AlertMessage.error(message ?? t("default.errorMessage"));
    }
  };

  const addChassis = async (fields: ChassisRequestDef) => {
    const response = await dispatch(addNewChassis(fields));
    if (addNewChassis.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsChassis.addSuccessMessage"));
    }
    if (addNewChassis.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const editVehicle = async (
    chassisNo: ChassisDef["chassis_no"],
    fields: ChassisRequestDef
  ) => {
    const response = await dispatch(updateChassis({ chassisNo, fields }));
    if (updateChassis.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsChassis.editSuccessMessage"));
    }
    if (updateChassis.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const handleSubmit = (values: FormValuesDef) => {
    setLoading(true);
    const fields: ChassisRequestDef = {
      chassis_no: values.chassisNo.trim(),
      number_plate: values.numberPlate.trim(),
      chassis_type: values.chassisType,
    };
    if (editMode && actionId) editVehicle(actionId, fields);
    else addChassis(fields);
  };

  const handleClose = () => {
    form.resetFields();
    onClose({ triggerRefetch: false });
  };

  const validateMessages = {
    required: t("default.requiredMessage"),
    string: {
      max: t("default.maxLengthMessage", {
        max: 50,
      }),
    },
  };

  return (
    <FormModal
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      title={
        editMode
          ? t("settingsChassis.editChassisTitle")
          : t("settingsChassis.addNewChassisTitle")
      }
      visible={showModal}
      submitButtonText={
        editMode ? t("default.editTitle") : t("default.addTitle")
      }
      destroyOnClose
      loadingSubmit={loading}
      loadingContent={loading}
      onClose={handleClose}
      onFinish={handleSubmit}
    >
      <Col span={24}>
        <Item
          label={t("settingsChassis.labelChassisNo")}
          name="chassisNo"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input
            disabled={editMode}
            placeholder={t("settingsChassis.chassisNoPlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsChassis.labelNumberPlate")}
          name="numberPlate"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsChassis.numberPlatePlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsChassis.labelChassisType")}
          name="chassisType"
          rules={[
            {
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsChassis.chassisTypePlaceholder")} />
        </Item>
      </Col>
    </FormModal>
  );
});

export default ChassisModal;
