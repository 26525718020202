import { memo, useEffect, useState } from "react";

import { Table } from "antd";

import {
  SimplifiedVehicleGroupDef,
  SimplifiedVehicleDef,
} from "../../../../types/vehicle-group.types";
import styles from "./VehicleGroupList.module.scss";

interface VehicleGroupListProps {
  items: SimplifiedVehicleGroupDef[];
  loading: boolean;
  checkStrictSelection?: boolean;
  selectedRowKeys:
    | SimplifiedVehicleDef["id"][]
    | SimplifiedVehicleGroupDef["id"][];
  onRowSelection: (
    record: SimplifiedVehicleGroupDef | SimplifiedVehicleDef,
    selected: boolean,
    selectedRows: SimplifiedVehicleGroupDef[] | SimplifiedVehicleDef[]
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vehiclePositions: any;
}

const VehicleGroupList = memo(
  ({
    items,
    loading,
    checkStrictSelection,
    selectedRowKeys,
    onRowSelection,
    vehiclePositions,
  }: VehicleGroupListProps) => {
    const [filteredGroupWithStatus, setFilterGroupWithStatus] = useState<
      SimplifiedVehicleGroupDef[]
    >([]);

    const rowSelection = {
      selectedRowKeys,
      onSelect: (
        record: SimplifiedVehicleGroupDef | SimplifiedVehicleDef,
        selected: boolean,
        selectedRows: SimplifiedVehicleGroupDef[]
      ) => {
        onRowSelection(record, selected, selectedRows);
      },
    };

    // prepare group without status
    useEffect(() => {
      const data = items.map(grp => {
        return {
          ...grp,
          vehicles: grp.vehicles?.filter(vh => {
            return (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              vehiclePositions?.find((vkh: any) => vkh.no === vh.id)?.no ===
              vh.id
            );
          }),
        };
      });
      setFilterGroupWithStatus(data);
    }, [items, vehiclePositions]);

    return (
      <Table
        dataSource={filteredGroupWithStatus}
        loading={loading}
        pagination={false}
        showHeader={false}
        className={styles.vehicleGroupList}
        childrenColumnName="vehicles"
        rowSelection={{
          ...rowSelection,
          checkStrictly: checkStrictSelection,
        }}
      >
        <Table.Column
          key="id"
          dataIndex="name"
          render={(name, row: SimplifiedVehicleGroupDef) => (
            <span>
              {name}
              {!!row.vehicles?.length && row.vehicles[0] && (
                <span>({row.vehicles?.length})</span>
              )}
            </span>
          )}
        />
      </Table>
    );
  }
);

export default VehicleGroupList;
