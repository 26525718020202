export enum MessageTargetEnum {
  DRIVER = 1,
  DISPATCHER = 0,
  GROUP = "VehicleGroup",
}

export enum PredefinedMessagePagination {
  PAGE_SIZE = 1000,
}

export enum VehicleGroupsPagination {
  PAGE_SIZE = 1000,
}

export enum MessageTabEnum {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export enum MessageLimitDays {
  LIMIT_DAYS = 2,
}

export const MESSAGE_STORE_WAITING_TIME = 400;
export const AUTH_MIDDLEWARE_PATH = "/middleware/auth";
