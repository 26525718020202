import { memo, useCallback, useEffect, useRef } from "react";

import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import { useInterval } from "react-use";

// import { MessageFetchTime } from "@app/constants/message.constants";
import { RootState } from "@app/redux/root-reducer";

import {
  getFullConversation,
  setConversationsEmpty,
} from "../../../../redux/message-thread.slice";
import EmptyMessageThread from "../EmptyMessageThread/EmptyMessageThread";
import MessageHeader from "../MessageHeader/MessageHeader";
import MessageInput from "../MessageInput/MessageInput";
import MessageThread from "../MessageThread/MessageThread";
import styles from "./MessageContainer.module.scss";

const MessageContainer = memo(() => {
  const { conversations, loadingConversations } = useSelector(
    (state: RootState) => state.conversations
  );
  const unreadMessageCount = useSelector(
    (state: RootState) => state.messages.unreadMessagesCount
  );
  // Initially setting it true so that if there is no scrollbar auto refresh triggered
  const isScrollBottom = useRef(true);
  const dispatch = useDispatch();
  const params = useParams<{ vehicleNo: string; id: string }>();
  const showMessageThread = !!params.id; // Message thread will load based on vehicleNo

  const getConversations = useCallback(
    (page: number, pageSize = 15) => {
      if (params.vehicleNo) {
        dispatch(
          getFullConversation({ vehicleNo: params.vehicleNo, page, pageSize })
        );
      }
    },
    [dispatch, params.vehicleNo]
  );

  useEffect(() => {
    if (showMessageThread || isScrollBottom.current) {
      dispatch(setConversationsEmpty());
      getConversations(1);
    }
  }, [getConversations, dispatch, showMessageThread, unreadMessageCount]);

  const fetchMoreMessage = () => {
    const page = conversations.pagination.current_page + 1;
    getConversations(page);
  };

  const handleOnScroll = (event: MouseEvent) => {
    if ((event.target as HTMLElement).scrollTop === 0) {
      isScrollBottom.current = true;
    } else {
      isScrollBottom.current = false;
    }
  };

  return (
    <div className={styles.messageContainer}>
      {showMessageThread ? (
        <>
          <div className={styles.messageHeader}>
            <MessageHeader
              vehicleNo={conversations.data[0]?.vehicle_no}
              driver={
                conversations.data.find(con => con.direction_type === 1)
                  ?.sender_user?.user_name
              }
            />
          </div>
          <div className={styles.messageThread} id="messageThread">
            <InfiniteScroll
              dataLength={conversations.data?.length}
              className={styles.infiniteScroll}
              scrollableTarget="messageThread"
              inverse
              onScroll={handleOnScroll}
              hasMore={
                !loadingConversations &&
                conversations.data?.length < conversations.pagination?.total
              }
              loader={
                <div className={styles.loadingMoreMessage}>
                  <Spin />
                </div>
              }
              next={fetchMoreMessage}
            >
              <MessageThread
                conversations={conversations.data}
                loading={
                  loadingConversations && !conversations.pagination.total
                }
              />
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <EmptyMessageThread />
      )}
      <div className={styles.messageFooter}>
        <MessageInput
          disabled={!showMessageThread}
          vehicleNo={
            conversations.data[0]?.vehicle_no
              ? conversations.data[0]?.vehicle_no
              : params.vehicleNo
          }
          getConversations={getConversations}
        />
      </div>
    </div>
  );
});

export default MessageContainer;
