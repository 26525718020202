import { RouteItemDef } from "@app/types/route.types";

import { FleetManagerPathsEnum } from "../constants/fleet-manager.paths";
import MapScreen from "../screens/MapScreen/MapScreen";
import MessageScreen from "../screens/MessageScreen/MessageScreen";
import FleetManagerRoutes from "./FleetManagerRoutes";

const FLEET_MANAGER_SCREEN: RouteItemDef = {
  id: "fleet-manager",
  path: FleetManagerPathsEnum.FLEET_MANAGER,
  navigationTitle: "fleetManager.navigationTitle",
  component: FleetManagerRoutes,
  nestedRoutes: [
    {
      id: "fleet-manager-group",
      groupTitle: "fleetManager.groupFleetManager",
      nestedRoutes: [
        {
          id: "map",
          path: FleetManagerPathsEnum.MAP,
          navigationTitle: "fleetManagerMap.navigationTitle",
          component: MapScreen,
        },
        {
          id: "messages",
          path: [
            FleetManagerPathsEnum.MESSAGES,
            FleetManagerPathsEnum.MESSAGES_INCOMING,
            FleetManagerPathsEnum.MESSAGES_OUTGOING,
            FleetManagerPathsEnum.NEW_MESSAGE,
            FleetManagerPathsEnum.NEW_MESSAGE_COMPOSE,
            FleetManagerPathsEnum.MESSAGES_THREAD,
          ],
          navigationTitle: "fleetManagerMessages.navigationTitle",
          component: MessageScreen,
        },
      ],
    },
  ],
};

export const FLEET_MANAGER_ROUTES = [FLEET_MANAGER_SCREEN];
