export enum SettingsEndpointsEnum {
  VEHICLES = "/v2/vehicles",
  DEPARTMENTS = "/v2/departments",
  SUB_DEPARTMENTS = "/v2/subdepartments",
  VEHICLE_RESPONSIBLE = "v2/vehicle-responsibles",
  VEHICLE_GROUPS = "/v2/vehicle-groups",
  DISPATCHER_VEHICLE_GROUPS = "/v2/users/",
  PREDEFINED_MESSAGES = "/v2/predefined/messages",
  CHASSIS = "/v2/chassis",
  DRIVERS = "/v2/users",
  DRIVERS_EXPORT = "/drivers/export",
  USERS = "v2/users",
  USERS_EXPORT = "/users/export",
  USERS_ACTIVATION = "/v2/users/:id",
}
