import { memo, ReactNode } from "react";

import { Space, Typography } from "antd";
import cx from "classnames";

import styles from "./ScreenTitle.module.scss";

const { Title, Text } = Typography;

export type ScreenTitleProps = {
  title: string;
  subTitle?: string;
  filters?: ReactNode;
  actions?: ReactNode;
  className?: string;
};

const ScreenTitle = memo(
  ({ title, subTitle, filters, actions, className }: ScreenTitleProps) => {
    const showExtras = !!filters || !!actions;
    return (
      <div className={cx(styles.container, className)}>
        <div>
          <Title level={3} className={styles.title}>
            {title}
          </Title>
          {!!subTitle && <Text>{subTitle}</Text>}
        </div>
        {showExtras && (
          <div className={styles.extras}>
            <div className={styles.filters}>{filters}</div>
            <div>
              <Space size="middle">{actions}</Space>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ScreenTitle;
