import { memo } from "react";

import { Card, Checkbox, Col, Row, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { setMapSettings } from "../../../../redux/position-map-settings.slice";
import styles from "./PositionMapSettingsPanel.module.scss";

const { Text } = Typography;

const MapSettingsPanel = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { positionMapSettings } = useSelector(
    (state: RootState) => state.positionMapSettingsOptions
  );

  const options = {
    pointsOnMap: "pointsOnMap",
  };

  /**
   * Handle map settings change
   * @param e
   */
  const handleMapSettingsChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;

    switch (value) {
      case options.pointsOnMap:
        dispatch(
          setMapSettings({
            advancedSettings: { pointsOnMap: checked },
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <Card bordered={false} className={styles.mapSettingsPanelContainer}>
      <Row gutter={[0, 8]}>
        <Col md={24}>
          <Text type="secondary">{t("settingsMap.advancedSettings")}</Text>
        </Col>
        <Col md={24}>
          <Checkbox
            value={options.pointsOnMap}
            onChange={handleMapSettingsChange}
            className={styles.checkbox}
            checked={positionMapSettings.advancedSettings?.pointsOnMap}
          >
            {t("reportingPosition.pointsOnMap")}
          </Checkbox>
        </Col>
      </Row>
    </Card>
  );
});

export default MapSettingsPanel;
