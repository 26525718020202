/* eslint-disable camelcase */
import { memo, useEffect, useState } from "react";

import { Form, Input, Col, message as AlertMessage } from "antd";
import _capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";

import FormModal, { Item } from "@app/components/atoms/FormModal/FormModal";
import { Languages } from "@app/constants/language.constants";
import { ItemModalEnum } from "@app/constants/route.constants";
// import { UserRole } from "@app/constants/user.constants";
import useShowModal from "@app/hooks/useShowModal";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import {
  addPredefinedMessage,
  getPredefinedMessageById,
  updatePredefinedMessage,
} from "../../redux/predefined-messages.slice";
import {
  // LocalizationDef,
  PredefinedMessageDef,
  PredefinedMessageRequestDef,
} from "../../types/predefined-message.types";

interface PredefinedMessageModalProps {
  onClose: (action: ModalCloseActionDef) => void;
  direction_type: number;
}

// interface FormValuesDef {
//   message: any;
//   owner: string;
//   localizations: any;
// }

const PredefinedMessageModal = memo(
  ({ onClose, direction_type }: PredefinedMessageModalProps) => {
    const [loading, setLoading] = useState(false);
    const [positionIndice, setPositionIndice] = useState(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { showModal, action, actionId } = useShowModal();
    const [form] = Form.useForm();

    const editMode = action === ItemModalEnum.EDIT;

    const setFormValue = (predefinedMessage: PredefinedMessageDef) => {
      const { owner, localizations } = predefinedMessage;
      form.setFieldsValue({
        en: localizations?.filter(
          (lan: any) => lan.language_code === Languages.ENGLISH
        )[0]?.localized_text,
        owner,
        localizations,
        bg: localizations?.filter(
          (lan: any) => lan.language_code === Languages.BULGARIAN
        )[0]?.localized_text,
        ru: localizations?.filter(
          (lan: any) => lan.language_code === Languages.RUSSIAN
        )[0]?.localized_text,
        nl: localizations?.filter(
          (lan: any) => lan.language_code === Languages.DUTCH
        )[0]?.localized_text,
        sv: localizations?.filter(
          (lan: any) => lan.language_code === Languages.SWEDISH
        )[0]?.localized_text,
        da: localizations?.filter(
          (lan: any) => lan.language_code === Languages.DANISH
        )[0]?.localized_text,
        ro: localizations?.filter(
          (lan: any) => lan.language_code === Languages.ROMANIAN
        )[0]?.localized_text,
        pl: localizations?.filter(
          (lan: any) => lan.language_code === Languages.POLISH
        )[0]?.localized_text,
        de: localizations?.filter(
          (lan: any) => lan.language_code === Languages.GERMAN
        )[0]?.localized_text,
      });
    };

    useEffect(() => {
      if (editMode && actionId) {
        const getPredefinedMessage = async () => {
          setLoading(true);
          const response = await dispatch(
            getPredefinedMessageById({ actionId, direction_type })
          );
          if (getPredefinedMessageById.fulfilled.match(response)) {
            setPositionIndice(response.payload.position_indice);
            setFormValue(response.payload);
            setLoading(false);
          }
          if (getPredefinedMessageById.rejected.match(response)) {
            setLoading(false);
            AlertMessage.error(t("default.errorMessage"));
          }
        };
        getPredefinedMessage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionId, editMode]);

    const showAlertMessage = (isSuccess: boolean, message: string) => {
      setLoading(false);
      if (isSuccess) {
        AlertMessage.success(message);
        form.resetFields();
        onClose({ triggerRefetch: true });
      } else {
        AlertMessage.error(message ?? t("default.errorMessage"));
      }
    };

    const addNewPredefinedMessage = async (
      fields: PredefinedMessageRequestDef
    ) => {
      const response = await dispatch(addPredefinedMessage(fields));
      if (addPredefinedMessage.fulfilled.match(response)) {
        showAlertMessage(
          true,
          t("settingsPredefinedMessages.addSuccessMessage")
        );
      }
      if (addPredefinedMessage.rejected.match(response)) {
        showAlertMessage(false, response.payload as string);
      }
    };

    const editPredefinedMessage = async (
      id: PredefinedMessageDef["id"],
      fields: PredefinedMessageRequestDef
    ) => {
      const response = await dispatch(updatePredefinedMessage({ id, fields }));
      if (updatePredefinedMessage.fulfilled.match(response)) {
        showAlertMessage(
          true,
          t("settingsPredefinedMessages.editSuccessMessage")
        );
      }
      if (updatePredefinedMessage.rejected.match(response)) {
        showAlertMessage(false, response.payload as string);
      }
    };

    const handleSubmit = (values: any) => {
      setLoading(true);
      const localizations = Object.keys(values).map(val => {
        return { language_code: val, localized_text: values[val] };
      });
      const fields: any = {
        position_indice: positionIndice ?? 0,
        localizations,
        direction_type,
      };
      if (editMode && actionId) {
        editPredefinedMessage(parseInt(actionId, 10), fields);
      } else addNewPredefinedMessage(fields);
    };

    const handleClose = () => {
      form.resetFields();
      onClose({ triggerRefetch: false });
    };

    const validateMessages = {
      required: t("default.requiredMessage"),
      string: {
        max: t("default.maxLengthMessage", {
          max: 999,
        }),
      },
    };

    return (
      <FormModal
        width={760}
        form={form}
        validateMessages={validateMessages}
        title={
          editMode
            ? t("settingsPredefinedMessages.editMessageTitle")
            : t("settingsPredefinedMessages.addMessageTitle")
        }
        visible={showModal}
        submitButtonText={
          editMode ? t("default.editTitle") : t("default.addTitle")
        }
        destroyOnClose
        loadingSubmit={loading}
        loadingContent={loading}
        onClose={handleClose}
        onFinish={handleSubmit}
      >
        <Col span={24}>
          <Item
            label={t("settingsPredefinedMessages.labelMessage")}
            name="en"
            rules={[
              {
                required: true,
                whitespace: true,
                max: 999,
              },
            ]}
          >
            <Input.TextArea
              placeholder={t("settingsPredefinedMessages.messagePlaceholder")}
            />
          </Item>
        </Col>
        {Object.entries(Languages).map(
          ([key, value]) =>
            value !== Languages.ENGLISH && (
              <Col span={24} key={key}>
                <Item
                  label={_capitalize(key)}
                  name={value}
                  rules={[
                    {
                      whitespace: true,
                      max: 999,
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder={t(
                      "settingsPredefinedMessages.translatePlaceholder"
                    )}
                  />
                </Item>
              </Col>
            )
        )}
      </FormModal>
    );
  }
);

export default PredefinedMessageModal;
