import { memo, useEffect, useRef, useState } from "react";

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Row, Col, Checkbox, Typography, Empty } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import cx from "classnames";
import { FixedSizeList as VirtualizedList } from "react-window";

import Button from "@app/components/atoms/Button/Button";

import { VehicleGroupSelectionDef } from "../../../../types/vehicle-group.types";
import styles from "./GroupSelectionBox.module.scss";

const { Title, Text } = Typography;

interface SelectionBoxProps {
  items: VehicleGroupSelectionDef[];
  availableItems: VehicleGroupSelectionDef[];
  visibleItems: number[];
  availableTitle: string;
  visibleTitle: string;
  deselectAllTitle: string;
  emptySelectedMessage: string;
  className?: string;
  onChangeSelection: (selectedItems: number[]) => void;
}

type RenderItemDef = {
  data: VehicleGroupSelectionDef[];
  index: number;
  style: React.CSSProperties;
};

const GroupSelectionBox = memo(
  ({
    items,
    availableItems,
    visibleItems,
    availableTitle,
    visibleTitle,
    deselectAllTitle,
    emptySelectedMessage,
    className,
    onChangeSelection,
  }: SelectionBoxProps) => {
    const [itemsToAdd, setItemsToAdd] = useState<CheckboxValueType[]>([]);
    const [itemsToRemove, setItemsToRemove] = useState<CheckboxValueType[]>([]);

    const selectedItems = useRef<number[]>([]);

    useEffect(() => {
      selectedItems.current = [...visibleItems];
    }, [visibleItems]);

    const onChangeAvailable = (e: CheckboxChangeEvent) => {
      const { value, checked } = e.target;
      if (checked) {
        setItemsToAdd(prevItems => [...prevItems, value]);
      } else {
        setItemsToAdd(itemsToAdd.filter(item => item !== value));
      }
    };

    const onChangeSelected = (e: CheckboxChangeEvent) => {
      const { value, checked } = e.target;
      if (checked) {
        setItemsToRemove(prevItems => [...prevItems, value]);
      } else {
        setItemsToRemove(itemsToRemove.filter(item => item !== value));
      }
    };

    const handleAddition = () => {
      const newItems = itemsToAdd.filter(
        item => !selectedItems.current?.includes(item as number)
      );
      selectedItems.current = [
        ...selectedItems.current,
        ...(newItems as number[]),
      ];

      newItems.length && onChangeSelection(selectedItems.current);
      setItemsToAdd([]);
    };

    const handleRemoval = () => {
      const remainingItems = selectedItems.current.filter(
        item => !itemsToRemove.includes(item)
      );
      selectedItems.current = [...remainingItems];
      itemsToRemove.length && onChangeSelection(selectedItems.current);
      setItemsToRemove([]);
    };

    const handleDeselectAll = () => {
      setItemsToAdd([]);
    };

    const renderedItem = ({ data, index, style }: RenderItemDef) => {
      return (
        <div style={style} className={styles.item} key={data[index].id}>
          <Checkbox value={data[index].id} onChange={onChangeAvailable}>
            {data[index].name}
          </Checkbox>
        </div>
      );
    };

    const getItemName = (id: number) => {
      return items?.find(item => item.id === id)?.name;
    };

    return (
      <Row className={cx(styles.selectionBoxContainer, className)}>
        <Col span={10}>
          <Row justify="center" className={styles.availableItems}>
            <div className={styles.boxTitle}>
              <Title level={5}>{availableTitle}</Title>
              {itemsToAdd?.length > 0 && (
                <Button
                  type="text"
                  size="small"
                  className={styles.deselectBtn}
                  onClick={handleDeselectAll}
                >
                  <Text type="success">{deselectAllTitle}</Text>
                </Button>
              )}
            </div>
            <div className={styles.listItems}>
              <Checkbox.Group
                className={styles.checkBoxGroup}
                value={itemsToAdd}
              >
                {availableItems.length ? (
                  <VirtualizedList
                    height={518}
                    itemCount={availableItems.length}
                    itemSize={30}
                    itemData={availableItems}
                    width={295}
                  >
                    {renderedItem}
                  </VirtualizedList>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </Checkbox.Group>
            </div>
          </Row>
        </Col>
        <Col span={4}>
          <div className={styles.controlButtons}>
            <Button
              className={styles.button}
              shape="circle"
              onClick={handleAddition}
              icon={<ArrowRightOutlined />}
            />
            <Button
              className={styles.button}
              shape="circle"
              onClick={handleRemoval}
              icon={<ArrowLeftOutlined />}
            />
          </div>
        </Col>
        <Col span={10}>
          <Row justify="center" className={styles.selectedItems}>
            <div className={styles.boxTitle}>
              <Title ellipsis level={5}>
                {visibleTitle}
              </Title>
            </div>
            <div
              className={cx([styles.listItems, styles.scrollable], {
                [styles.emptyList]: visibleItems.length === 0,
              })}
            >
              {visibleItems?.length > 0 ? (
                <Checkbox.Group
                  className={styles.checkBoxGroup}
                  value={itemsToRemove}
                >
                  {visibleItems?.map(item => (
                    <Col span={24} className={styles.item} key={item}>
                      <Checkbox
                        value={item}
                        key={item}
                        onChange={onChangeSelected}
                      >
                        {getItemName(item)}
                      </Checkbox>
                    </Col>
                  ))}
                </Checkbox.Group>
              ) : (
                <Text type="secondary" className={styles.emptyMessage}>
                  {emptySelectedMessage}
                </Text>
              )}
            </div>
          </Row>
        </Col>
      </Row>
    );
  }
);

export default GroupSelectionBox;
