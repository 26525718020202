import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { reportingApi } from "../api/reporting.api";
import { AuditLogDef, AuditLogReportFilterDef } from "../types/reporting.types";

type AuditLogReportState = {
  report: {
    data: AuditLogDef[];
    pagination: PaginationDef;
  };
  loadingReport: boolean;
};

const initialState: AuditLogReportState = {
  report: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingReport: false,
};

export const getAuditLogReport = createAsyncThunk(
  "auditLogReport/getAuditLogReport",
  async (filter: AuditLogReportFilterDef, { rejectWithValue }) => {
    try {
      const response = await reportingApi.getAuditLogReport(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const auditLogReportSlice = createSlice({
  name: "auditLogReport",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAuditLogReport.pending, state => {
      state.loadingReport = true;
    });
    builder.addCase(getAuditLogReport.fulfilled, (state, action) => {
      state.loadingReport = false;
      state.report.data = action.payload.data || [];
      state.report.pagination = mapPagination(action.payload?.meta?.pagination);
    });
    builder.addCase(getAuditLogReport.rejected, state => {
      state.loadingReport = false;
    });
  },
});

export default auditLogReportSlice.reducer;
