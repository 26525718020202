import { memo } from "react";

import intervalToDuration from "date-fns/intervalToDuration";
import { useTranslation } from "react-i18next";

interface DurationProps {
  startDate: string;
  endDate: string;
}

const Duration = memo(({ startDate, endDate }: DurationProps) => {
  const { t } = useTranslation();
  const start = new Date(startDate);
  const end = new Date(endDate);

  const duration = intervalToDuration({ start, end });

  return startDate && endDate ? (
    <>
      {t("default.duration", {
        hours: duration.hours,
        minutes: duration.minutes,
        seconds: duration.seconds,
      })}
    </>
  ) : null;
});

export default Duration;
