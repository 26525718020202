import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { settingsApi } from "../api/settings.api";
import {
  DriverDef,
  DriverRequestDef,
  DriverFilterDef,
} from "../types/driver.types";

type DriversState = {
  drivers: {
    data: DriverDef[];
    pagination: PaginationDef;
  };
  loadingDrivers: boolean;
};

const initialState: DriversState = {
  drivers: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 10,
      total: 0,
      total_pages: 0,
    },
  },
  loadingDrivers: false,
};

export const getDrivers = createAsyncThunk(
  "drivers/getDrivers",
  async (filter: DriverFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getDrivers(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDriverByNo = createAsyncThunk(
  "drivers/getDriverByNo",
  async (driverNo: DriverDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.getDriverByNo(driverNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewDriver = createAsyncThunk(
  "drivers/addNewDriver",
  async (fields: DriverRequestDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.addNewDriver(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateDriver = createAsyncThunk(
  "drivers/updateDriver",
  async (
    data: { driverNo: DriverDef["id"]; fields: DriverRequestDef },
    { rejectWithValue }
  ) => {
    const { driverNo, fields } = data;
    try {
      const response = await settingsApi.updateDriver(driverNo, fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDriver = createAsyncThunk(
  "drivers/deleteDriver",
  async (driverNo: DriverDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deleteDriver(driverNo);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const driversSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    setDrivers: (state, action: PayloadAction<DriverDef[]>) => {
      state.drivers.data = action.payload ?? [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getDrivers.pending, state => {
      state.loadingDrivers = true;
    });
    builder.addCase(getDrivers.fulfilled, (state, action) => {
      state.loadingDrivers = false;
      state.drivers.data = action.payload.data || [];
      state.drivers.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getDrivers.rejected, state => {
      state.loadingDrivers = false;
    });
  },
});

export default driversSlice.reducer;
export const { setDrivers } = driversSlice.actions;
