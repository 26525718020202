import { memo } from "react";

import { ChassisCheckDamagesDef } from "../../../../types/reporting.types";

interface ChassisCheckDamagesProps {
  damages: ChassisCheckDamagesDef[];
  displayNumberOfDamages: number;
}

const ChassisCheckDamages = memo(
  ({ damages, displayNumberOfDamages }: ChassisCheckDamagesProps) => {
    const slicedDamages =
      damages.length > 0 ? damages.slice(0, displayNumberOfDamages) : [];
    return damages.length ? (
      <>
        {slicedDamages.map((damage: ChassisCheckDamagesDef, index: number) => (
          <span key={damage.category_id}>
            {damage.category_name}
            {slicedDamages.length - 1 === index ? "" : ", "}
          </span>
        ))}
        {damages.length > slicedDamages.length
          ? `(+${damages.length - slicedDamages.length})`
          : ""}
      </>
    ) : null;
  }
);

export default ChassisCheckDamages;
