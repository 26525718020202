import { memo } from "react";

import { MessageOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import styles from "./EmptyMessageThread.module.scss";

const { Paragraph } = Typography;

const EmptyMessageThread = memo(() => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyThreadContent}>
      <MessageOutlined className={styles.messageIcon} />
      <Paragraph className={styles.emptyText}>
        {t("fleetManagerMessages.emptyMessageThread")}
      </Paragraph>
    </div>
  );
});

export default EmptyMessageThread;
