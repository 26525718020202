import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { adminSettingsApi } from "../api/admin-settings.api";
import { VisibleGroupDef } from "../types/vehicle-group.types";

type VisibleGroupsState = {
  visibleGroups: VisibleGroupDef[];
  loadingVisibleGroups: boolean;
};

const initialState: VisibleGroupsState = {
  visibleGroups: [],
  loadingVisibleGroups: false,
};

export const getVisibleGroups = createAsyncThunk(
  "dispatcherConfig/getVisibleGroups",
  async (id: number | undefined) => {
    try {
      const response = await adminSettingsApi.getDispatchersVisibleGroups(id);
      return response.data;
    } catch (error) {
      return error.response.data as string;
    }
  }
);

export const updateVisibleGroups = createAsyncThunk(
  "dispatcherConfig/updateVisibleGroups",
  async (
    {
      fields,
      userId,
    }: { fields: VisibleGroupDef[]; userId: number | undefined },
    { rejectWithValue }
  ) => {
    try {
      const response = await adminSettingsApi.updateDispatchersVisibleGroups(
        fields,
        userId
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dispatcherConfigSlice = createSlice({
  name: "dispatcherConfig",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVisibleGroups.pending, state => {
      state.loadingVisibleGroups = true;
    });
    builder.addCase(getVisibleGroups.fulfilled, (state, action) => {
      state.loadingVisibleGroups = false;
      state.visibleGroups = action.payload || [];
    });
    builder.addCase(getVisibleGroups.rejected, state => {
      state.loadingVisibleGroups = false;
    });
  },
});

export default dispatcherConfigSlice.reducer;
