import { ElementType, memo } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import DefaultLayout from "@app/components/layouts/DefaultLayout/DefaultLayout";
import { Permission } from "@app/features/permissions/permissions";
import { RouteComponentDef, RouteItemDef } from "@app/types/route.types";

import NotFound from "./components/NotFound/NotFound";
import { PRIVATE_LIST, ROOT_ROUTE } from "./routes.config";

const routesWithComponents: RouteItemDef[] = PRIVATE_LIST.filter(
  route => route.component
);

const Routes = () => {
  const routeWrapper = ({
    id,
    path,
    layout,
    component,
    permissions,
  }: RouteItemDef) => {
    const Layout = (layout ?? DefaultLayout) as ElementType;
    return (
      <Route
        key={id}
        path={path}
        render={routeProps => {
          const Component = component as RouteComponentDef;
          const renderContent = (
            <Layout>
              <Component {...routeProps} />
            </Layout>
          );

          return (
            (permissions && (
              <Permission
                fallback={<Redirect to={ROOT_ROUTE} />}
                requiredPermissions={permissions}
              >
                {renderContent}
              </Permission>
            )) ||
            renderContent
          );
        }}
      />
    );
  };

  return (
    <Switch>
      <Redirect exact from="/" to={ROOT_ROUTE} />
      {routesWithComponents.map(route => routeWrapper(route))}
      <Route
        path="*"
        render={() => (
          <DefaultLayout>
            <NotFound />
          </DefaultLayout>
        )}
      />
    </Switch>
  );
};

export default memo(Routes);
