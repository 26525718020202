import { memo } from "react";

import {
  Card,
  Checkbox,
  Col,
  Divider,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/redux/root-reducer";

import { MapTypeEnum } from "../../../../constants/map-type.constants";
import { setMapSettings } from "../../../../redux/map-settings.slice";
import styles from "./MapSettingsPanel.module.scss";

const { Text } = Typography;

const MapSettingsPanel = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mapSettings } = useSelector(
    (state: RootState) => state.mapSettingsOptions
  );

  const dividerStyle = {
    margin: 0,
  };

  const options = {
    cluster: "cluster",
    traffic: "traffic",
    driverName: "driverName",
    vehicleNumber: "vehicleNumber",
    applicationStatus: "applicationStatus",
    dateTimeOfLastPosition: "dateTimeOfLastPosition",
  };

  /**
   * Handle map view change
   * @param e
   */
  const handleMapViewChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    dispatch(setMapSettings({ mapOptions: { mapTypeId: value } }));
  };

  /**
   * Handle map settings change
   * @param e
   */
  const handleMapSettingsChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;

    switch (value) {
      case options.cluster:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              advancedSettings: { displayClusters: checked },
            },
          })
        );
        break;
      case options.traffic:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              advancedSettings: { displayTraffic: checked },
            },
          })
        );
        break;
      case options.driverName:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              displayLabelSettings: { displayDriverName: checked },
            },
          })
        );
        break;
      case options.vehicleNumber:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              displayLabelSettings: { displayVehicleNumber: checked },
            },
          })
        );
        break;
      case options.applicationStatus:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              displayLabelSettings: { displayApplicationStatus: checked },
            },
          })
        );
        break;
      case options.dateTimeOfLastPosition:
        dispatch(
          setMapSettings({
            mapDisplaySettings: {
              displayLabelSettings: { displayDateTimeOfLastPosition: checked },
            },
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <Card bordered={false} className={styles.mapSettingsPanelContainer}>
      <Row gutter={[0, 8]}>
        <Col md={24}>
          <Text type="secondary">{t("settingsMap.settingsStyle")}</Text>
        </Col>
        <Col md={24}>
          <Radio.Group
            onChange={handleMapViewChange}
            value={mapSettings.mapOptions.mapTypeId}
          >
            <Radio value={MapTypeEnum.ROAD_MAP}>
              {t("settingsMap.settingsStyleRegularView")}
            </Radio>
            <Radio value={MapTypeEnum.SATELLITE}>
              {t("settingsMap.settingsStyleSatelliteView")}
            </Radio>
          </Radio.Group>
        </Col>
        <Divider style={{ margin: 0 }} />
        <Col md={24}>
          <Text type="secondary">{t("settingsMap.advancedSettings")}</Text>
        </Col>
        <Col md={24}>
          <Checkbox
            value={options.cluster}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.advancedSettings?.displayClusters
            }
          >
            {t("settingsMap.advancedSettingsClusters")}
          </Checkbox>
          <Checkbox
            value={options.traffic}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.advancedSettings?.displayTraffic
            }
          >
            {t("settingsMap.advancedSettingsTrafficInformation")}
          </Checkbox>
        </Col>

        <Divider style={dividerStyle} />
        <Col md={24}>
          <Text type="secondary">{t("settingsMap.settingsLabels")}</Text>
        </Col>
        <Col md={24}>
          <Checkbox
            value={options.driverName}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.displayLabelSettings
                ?.displayDriverName
            }
          >
            {t("settingsMap.settingsLabelsDriverName")}
          </Checkbox>
          <Checkbox
            value={options.vehicleNumber}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.displayLabelSettings
                ?.displayVehicleNumber
            }
          >
            {t("settingsMap.settingsLabelsVehicleNumber")}
          </Checkbox>
          <Checkbox
            value={options.applicationStatus}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.displayLabelSettings
                ?.displayApplicationStatus
            }
          >
            {t("settingsMap.settingsLabelsApplicationStatus")}
          </Checkbox>
          <Checkbox
            value={options.dateTimeOfLastPosition}
            onChange={handleMapSettingsChange}
            checked={
              mapSettings.mapDisplaySettings?.displayLabelSettings
                ?.displayDateTimeOfLastPosition
            }
          >
            {t("settingsMap.settingsLabelsDateTimeOfLatestPosition")}
          </Checkbox>
        </Col>
      </Row>
    </Card>
  );
});

export default MapSettingsPanel;
