import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { mapPagination } from "@app/helpers/util.helpers";
import { PaginationDef } from "@app/types/pagination.types";

import { settingsApi } from "../api/settings.api";
import {
  VehicleGroupDef,
  VehicleGroupRequestDef,
  VehicleGroupsFilterDef,
} from "../types/vehicle-group.types";
import { VehiclesFilterDef } from "../types/vehicle.types";

type VehicleGroupsState = {
  vehicleGroups: {
    data: VehicleGroupDef[];
    pagination: PaginationDef;
  };
  loadingVehicleGroups: boolean;
};

const initialState: VehicleGroupsState = {
  vehicleGroups: {
    data: [],
    pagination: {
      current_page: 1,
      per_page: 30,
      total: 0,
      total_pages: 0,
    },
  },
  loadingVehicleGroups: false,
};

export const getVehicleGroups = createAsyncThunk(
  "vehicleGroupsList/getVehicleGroups",
  async (filter: VehicleGroupsFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicleGroups(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehicleGroupById = createAsyncThunk(
  "vehicleGroupsList/getVehicleGroupById",
  async (id: VehicleGroupDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicleGroupById(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getVehicles = createAsyncThunk(
  "vehicleGroupsList/getVehicles",
  async (filter: VehiclesFilterDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.getVehicles(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewVehicleGroup = createAsyncThunk(
  "vehicleGroupsList/addNewVehicleGroup",
  async (fields: VehicleGroupRequestDef, { rejectWithValue }) => {
    try {
      const response = await settingsApi.addNewVehicleGroup(fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVehicleGroup = createAsyncThunk(
  "vehicleGroupsList/updateVehicleGroup",
  async (
    data: { id: VehicleGroupDef["id"]; fields: VehicleGroupRequestDef },
    { rejectWithValue }
  ) => {
    const { id, fields } = data;
    try {
      const response = await settingsApi.updateVehicleGroup(id, fields);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVehicleGroup = createAsyncThunk(
  "vehicleGroupsList/deleteVehicleGroup",
  async (id: VehicleGroupDef["id"], { rejectWithValue }) => {
    try {
      const response = await settingsApi.deleteVehicleGroup(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const vehicleGroupsListSlice = createSlice({
  name: "vehicleGroupsList",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVehicleGroups.pending, state => {
      state.loadingVehicleGroups = true;
    });
    builder.addCase(getVehicleGroups.fulfilled, (state, action) => {
      state.loadingVehicleGroups = false;
      state.vehicleGroups.data = action.payload.data || [];
      state.vehicleGroups.pagination = mapPagination(
        action.payload?.meta?.pagination
      );
    });
    builder.addCase(getVehicleGroups.rejected, state => {
      state.loadingVehicleGroups = false;
    });
  },
});

export default vehicleGroupsListSlice.reducer;
