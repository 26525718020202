import { memo } from "react";

import { Card, Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { DateTimeFormats } from "@app/constants/date.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";

import { VehiclePositionDef } from "../../../../types/vehicle-position.types";
import VehicleRouteAddress from "../VehicleRouteAddress/VehicleRouteAddress";
import VehicleStatus from "../VehicleStatus/VehicleStatus";
import VehicleStopType from "../VehicleStopType/VehicleStopType";
import styles from "./VehicleCard.module.scss";

const { Text, Title } = Typography;

interface VehiclePositionProps {
  vehicle: VehiclePositionDef;
}

const VehicleCard = memo(({ vehicle }: VehiclePositionProps) => {
  const { t } = useTranslation();
  return (
    <Card
      title={
        <Row gutter={24}>
          <Col md={12}>
            <Text type="secondary">{t("vehicleCard.vehicleNo")}</Text>
            <Title className={styles.cardTitle} level={4}>
              {vehicle.no}
            </Title>
          </Col>
          <Col md={12}>
            <Text type="secondary">{t("vehicleCard.currentDriver")}</Text>
            <Title className={styles.cardTitle} level={4}>
              {vehicle?.driver?.name || "-"}
            </Title>
          </Col>
        </Row>
      }
      bordered={false}
      className={styles.vehicleCardContainer}
    >
      <Row gutter={[0, 16]}>
        <Col md={12}>
          <Text className={styles.currentStatusLabel} type="secondary">
            {t("vehicleCard.currentStatus")}
          </Text>
          <VehicleStatus status={vehicle.status} />
        </Col>
        <Col md={12}>
          <Text className={styles.dateOfLastPositionLabel} type="secondary">
            {t("vehicleCard.dateTimeOfLastPosition")}
          </Text>
          <span className={styles.lastPositionDate}>
            {formattedDateTime(
              vehicle.created,
              DateTimeFormats.TIME_DAY_MONTH_YEAR
            )}
          </span>
        </Col>
        <Col md={24}>
          <Text type="secondary">
            {t("vehicleCard.lastRegisteredLocation")}
            {vehicle?.route?.current?.type && (
              <span className={styles.stopType}>
                <VehicleStopType type={vehicle.route.current.type} />
              </span>
            )}
          </Text>
          <Title className={styles.cardTitle} level={5}>
            {vehicle?.route?.current?.address ? (
              <VehicleRouteAddress address={vehicle.route.current.address} />
            ) : (
              "-"
            )}
          </Title>
        </Col>
        <Col md={24}>
          <Text type="secondary">
            {t("vehicleCard.nextLocationOnTrip")}
            {vehicle?.route?.next?.type && (
              <span className={styles.stopType}>
                <VehicleStopType type={vehicle.route.next.type} />
              </span>
            )}
          </Text>
          <Title className={styles.cardTitle} level={5}>
            {vehicle?.route?.next?.address ? (
              <VehicleRouteAddress address={vehicle.route.next.address} />
            ) : (
              "-"
            )}
          </Title>
        </Col>
      </Row>
    </Card>
  );
});

export default VehicleCard;
