import { memo } from "react";

import cx from "classnames";

import { getTokens } from "@app/features/auth/auth";

import { MessageTargetEnum } from "../../../../constants/fleet-manager.constants";
import styles from "./PreviewAttachment.module.scss";

const token = getTokens();

interface PreviewAttachmentProps {
  target: number;
  fileUri: string;
  group: boolean;
}

const PreviewAttachment = memo(
  ({ target, fileUri, group }: PreviewAttachmentProps) => {
    return (
      <div
        className={cx({
          [styles.previewFromDriver]: MessageTargetEnum.DISPATCHER === target,
          [styles.previewFromDispatcher]: target === MessageTargetEnum.DRIVER,
          [styles.groupPreviewFromDispatcher]: group,
        })}
      >
        <a
          className={styles.previewLink}
          href={`${fileUri}?access_token=${token}`}
          target="blank"
        >
          {fileUri}
        </a>
      </div>
    );
  }
);

export default PreviewAttachment;
