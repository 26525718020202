import { memo, useEffect, useState } from "react";

import { Table } from "antd";
import { useHistory, generatePath, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { MessageTabEnum } from "../../../../constants/fleet-manager.constants";
import { FleetManagerPathsEnum } from "../../../../constants/fleet-manager.paths";
import {
  SimplifiedVehicleDef,
  SimplifiedVehicleGroupDef,
} from "../../../../types/vehicle-group.types";
import styles from "./VehicleList.module.scss";

interface VehicleListProps {
  vehicleGroups: SimplifiedVehicleGroupDef[];
  isLoading: boolean;
}

const VehicleList = memo(({ vehicleGroups, isLoading }: VehicleListProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const history = useHistory();
  const params = useParams<{ vehicleNo: string }>();

  useEffect(() => {
    if (params.vehicleNo) {
      setSelectedRowKeys([params.vehicleNo]);
    } else {
      setSelectedRowKeys([]);
    }
  }, [params]);

  const selectRow = (
    record: SimplifiedVehicleGroupDef | SimplifiedVehicleDef
  ) => {
    if ("isGroup" in record) {
      return;
    }
    history.push(
      generatePath(FleetManagerPathsEnum.MESSAGES_THREAD, {
        type: MessageTabEnum.OUTGOING,
        vehicleNo: record.id,
        id: uuidv4(), // Fake message id just for url
      })
    );
  };

  return (
    <Table
      dataSource={vehicleGroups}
      loading={isLoading}
      pagination={false}
      showHeader={false}
      className={styles.vehicleGroupTable}
      rowClassName={styles.vehicleRow}
      childrenColumnName="vehicles"
      scroll={{ y: "calc(100vh - 310px)" }}
      rowKey={record => record.id}
      rowSelection={{
        type: "radio",
        selectedRowKeys,
        columnWidth: 0,
      }}
      onRow={record => ({
        onClick: () => {
          selectRow(record);
        },
      })}
    >
      <Table.Column
        key="name"
        dataIndex="name"
        render={(name, row: SimplifiedVehicleGroupDef) => (
          <span>
            {name}
            {row.vehicles?.length && <span>({row.vehicles?.length})</span>}
          </span>
        )}
      />
    </Table>
  );
});

export default VehicleList;
