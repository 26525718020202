import { ChassisRepairStatus } from "@app/constants/chassis.constants";

import {
  AuditLogTypeEnum,
  AuditlogEntityEnum,
} from "../constants/audlit-log-filter.constants";
import { VehiclePositionDef } from "../types/reporting-position.types";

export const getChassisRepairStatusOptions = () => {
  return Object.values(ChassisRepairStatus).map(value => {
    return {
      id: value,
      value,
    };
  });
};

/**
 * Get bounds from given set of positions
 * @param positions
 */
export const getPositionFittedBounds = (positions: VehiclePositionDef[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { maps } = (window as any).google;

  if (!maps) {
    return undefined;
  }

  const bounds = new maps.LatLngBounds();
  positions.forEach(position => {
    bounds.extend(
      new maps.LatLng(position.geo_decimal?.lat, position.geo_decimal?.lng)
    );
  });

  return bounds;
};

export const getAuditLogTypeFilterOptions = () => {
  return Object.values(AuditLogTypeEnum).map(value => {
    return {
      id: value,
      value,
    };
  });
};

export const getAuditLogEntityFilterOptions = () => {
  return Object.values(AuditlogEntityEnum).map(value => {
    return {
      id: value,
      value,
    };
  });
};

export const convertMinsToHrsMins = (minutes: number) => {
  let h = Math.floor(minutes / 60) || "";
  let m = minutes % 60 || "";
  h = h < 10 ? `0${h}` : h;
  m = m < 10 ? `0${m}` : m;
  return `${h}:${m}`;
};
