import { memo } from "react";

import { Tag } from "antd";

import Button from "@app/components/atoms/Button/Button";

import { SimplifiedVehicleDef } from "../../../../types/vehicle-group.types";
import styles from "./SelectedVehicles.module.scss";

interface SelectedVehiclesProps {
  selectedVehicles: SimplifiedVehicleDef[];
  isRemovable?: boolean;
  resetBtnText: string;
  backBtnText: string;
  onRemove?: (record: SimplifiedVehicleDef) => void;
  onReset?: () => void;
  onBack?: () => void;
}

const SelectedVehicles = memo(
  ({
    selectedVehicles,
    isRemovable = true,
    resetBtnText,
    backBtnText,
    onRemove,
    onReset,
    onBack,
  }: SelectedVehiclesProps) => {
    return (
      <div className={styles.selectedItemsContainer}>
        {selectedVehicles.map(item => (
          <Tag
            key={item.id}
            closable={isRemovable}
            visible
            onClose={() => onRemove?.(item)}
            className={styles.tag}
          >
            {item.name}
          </Tag>
        ))}
        {selectedVehicles.length > 0 && isRemovable ? (
          <Button size="small" type="text" onClick={onReset}>
            <span className={styles.buttonLabel}>{resetBtnText}</span>
          </Button>
        ) : (
          !isRemovable && (
            <Button size="small" type="text" onClick={onBack}>
              <span className={styles.buttonLabel}>{backBtnText}</span>
            </Button>
          )
        )}
      </div>
    );
  }
);

export default SelectedVehicles;
