import { useEffect } from "react";

import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import ScreenTitle from "@app/components/molecules/ScreenTitle/ScreenTitle";
import TableView from "@app/components/molecules/TableView/TableView";
import { DateTimeFormats } from "@app/constants/date.constants";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  MAX_PAGE_SIZE,
} from "@app/constants/pagination.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";
import useSearchParams from "@app/hooks/useSearchParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import { UserRoleForPredefinedMessage } from "../../../../constants/user.constants";
import { getUsers } from "../../../settings/redux/users.slice";
import { getMessageReport } from "../../redux/message-report.slice";
import { MessageReportFilterDef } from "../../types/reporting.types";
import MessageFilters from "./components/MessageFilters/MessageFilters";
import styles from "./components/MessageFilters/MessageFilters.module.scss";

const MessageReportScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { search }: { search: MessageReportFilterDef } = useSearchParams();
  const reports = useSelector((state: RootState) => state.messageReport.report);
  const loadingReport = useSelector(
    (state: RootState) => state.messageReport.loadingReport
  );

  useEffect(() => {
    dispatch(getUsers({ role: "Driver", pageSize: MAX_PAGE_SIZE }));

    dispatch(
      getMessageReport({
        page: search?.page ?? DEFAULT_PAGE,
        pageSize: search?.pageSize ?? DEFAULT_PAGE_SIZE,
        vehicleNo: search?.vehicleNo ?? undefined,
        userId: search?.driver ?? undefined,
        toDate: search?.to ?? undefined,
        fromDate: search?.from ?? undefined,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(search)]);

  return (
    <ContentLayout>
      <ScreenTitle
        title={t("reporting.messageReportingTitle")}
        subTitle={t("reporting.filterHelperText")}
        filters={<MessageFilters />}
      />

      <TableView
        displayActionColumn={false}
        rowKey="id"
        dataSource={reports.data}
        loading={loadingReport}
        pagination={{ ...reports.pagination }}
      >
        <Table.Column
          dataIndex="vehicle_no"
          title={t("reporting.columnVehicleNo")}
        />
        <Table.Column
          dataIndex="sender_user"
          title={t("reporting.columnSent")}
          render={(sentData: any) => (
            <>
              {sentData &&
                formattedDateTime(
                  sentData?.time,
                  DateTimeFormats.TIME_DAY_MONTH_YEAR
                )}
            </>
          )}
        />
        <Table.Column
          dataIndex="sender_user"
          title={t("reporting.columnSentBy")}
          render={(sendData: any) => <>{sendData ? sendData?.user_name : ""}</>}
        />
        {/* <Table.Column
          width={450}
          ellipsis
          dataIndex="text_body"
          title={t("reporting.columnMessage")}
        /> */}
        <Table.Column
          title={t("reporting.columnMessage")}
          render={(value, record: any) => (
            <div
              className={
                record.direction_type ===
                UserRoleForPredefinedMessage.DISPATCHER
                  ? styles.dispatcher
                  : styles.driver
              }
            >
              {record.attachments.length > 0 && record.attachments[0].file_uri}
              {record.text_body && record.text_body}
              {record.predefined_message_localizations.length > 0 &&
                record.predefined_message_localizations[0].localized_text}
            </div>
          )}
        />
        <Table.Column
          dataIndex="read_user"
          title={t("reporting.columnRead")}
          render={(readData: any) => (
            <>
              {readData &&
                formattedDateTime(
                  readData?.time,
                  DateTimeFormats.TIME_DAY_MONTH_YEAR
                )}
            </>
          )}
        />
        <Table.Column
          dataIndex="read_user"
          title={t("reporting.columnReadBy")}
          render={(readData: any) => <>{readData ? readData?.user_name : ""}</>}
        />
      </TableView>
    </ContentLayout>
  );
};

export default MessageReportScreen;
