import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { REPORTING_ROUTES } from "./reporting.routes";

const ReportingRoutes = () => {
  const routesWithComponents = flatten(REPORTING_ROUTES);

  return <NestedRouteWrapper routesWithComponents={routesWithComponents} />;
};

export default ReportingRoutes;
