import { generatePath } from "react-router-dom";

import { api } from "@app/api/api";
import { UserDef } from "@app/types/user.types";

import { SettingsEndpointsEnum } from "../constants/settings.endpoints";
import {
  ChassisDef,
  ChassisRequestDef,
  ChassisFilterDef,
} from "../types/chassis.types";
import {
  DriverDef,
  DriverFilterDef,
  DriverRequestDef,
} from "../types/driver.types";
import { UserFilterDef, UserRequestDef } from "../types/user.types";
import {
  VehicleGroupDef,
  VehicleGroupRequestDef,
} from "../types/vehicle-group.types";
import {
  VehiclesFilterDef,
  FilterDef,
  VehicleRequestDef,
  VehicleDef,
} from "../types/vehicle.types";

const getVehicles = (filter: VehiclesFilterDef) => {
  return api.get(SettingsEndpointsEnum.VEHICLES, {
    params: filter,
  });
};

const getVehicleByNo = (vehicleNo: VehicleDef["vehicle_no"]) => {
  return api.get(`${SettingsEndpointsEnum.VEHICLES}/${vehicleNo}`);
};

const getDepartments = (filter: FilterDef) => {
  return api.get(SettingsEndpointsEnum.DEPARTMENTS, {
    params: filter,
  });
};

const getPlanSubDepartments = (filter: FilterDef) => {
  return api.get(SettingsEndpointsEnum.SUB_DEPARTMENTS, {
    params: filter,
  });
};

const getVehicleResponsibles = (filter: FilterDef) => {
  return api.get(SettingsEndpointsEnum.VEHICLE_RESPONSIBLE, {
    params: filter,
  });
};

const addNewVehicle = (fields: VehicleRequestDef) => {
  return api.post(SettingsEndpointsEnum.VEHICLES, fields);
};

const updateVehicle = (
  vehicleNo: VehicleDef["vehicle_no"],
  fields: VehicleRequestDef
) => {
  return api.put(`${SettingsEndpointsEnum.VEHICLES}/${vehicleNo}`, fields);
};

const deleteVehicle = (vehicleNo: VehicleDef["vehicle_no"]) => {
  return api.delete(`${SettingsEndpointsEnum.VEHICLES}/${vehicleNo}`);
};

const getVehicleGroups = (filter: VehiclesFilterDef) => {
  return api.get(SettingsEndpointsEnum.VEHICLE_GROUPS, {
    params: filter,
  });
};

const getVehicleGroupById = (id: VehicleGroupDef["id"]) => {
  return api.get(`${SettingsEndpointsEnum.VEHICLE_GROUPS}/${id}`);
};

const addNewVehicleGroup = (fields: VehicleGroupRequestDef) => {
  return api.post(SettingsEndpointsEnum.VEHICLE_GROUPS, fields);
};

const updateVehicleGroup = (
  id: VehicleGroupDef["id"],
  fields: VehicleGroupRequestDef
) => {
  return api.put(`${SettingsEndpointsEnum.VEHICLE_GROUPS}/${id}`, fields);
};

const deleteVehicleGroup = (id: VehicleGroupDef["id"]) => {
  return api.delete(`${SettingsEndpointsEnum.VEHICLE_GROUPS}/${id}`);
};

const getChassisList = (filter: ChassisFilterDef) => {
  return api.get(SettingsEndpointsEnum.CHASSIS, {
    params: filter,
  });
};

const getChassisByNo = (chassisNo: ChassisDef["chassis_no"]) => {
  return api.get(`${SettingsEndpointsEnum.CHASSIS}/${chassisNo}`);
};

const addNewChassis = (fields: ChassisRequestDef) => {
  return api.post(SettingsEndpointsEnum.CHASSIS, fields);
};

const updateChassis = (
  chassisNo: ChassisDef["chassis_no"],
  fields: ChassisRequestDef
) => {
  return api.put(`${SettingsEndpointsEnum.CHASSIS}/${chassisNo}`, fields);
};

const deleteChassis = (chassisNo: ChassisDef["chassis_no"]) => {
  return api.delete(`${SettingsEndpointsEnum.CHASSIS}/${chassisNo}`);
};

const getDrivers = (filter: DriverFilterDef) => {
  return api.get(SettingsEndpointsEnum.DRIVERS, {
    params: filter,
  });
};

const getDriverByNo = (driverNo: DriverDef["id"]) => {
  return api.get(`${SettingsEndpointsEnum.DRIVERS}/${driverNo}`);
};

const addNewDriver = (fields: DriverRequestDef) => {
  return api.post(SettingsEndpointsEnum.DRIVERS, fields);
};

const updateDriver = (driverNo: DriverDef["id"], fields: DriverRequestDef) => {
  return api.patch(`${SettingsEndpointsEnum.DRIVERS}/${driverNo}`, fields);
};

const deleteDriver = (driverNo: DriverDef["id"]) => {
  return api.delete(`${SettingsEndpointsEnum.DRIVERS}/${driverNo}`);
};

const getDriverExportData = () => {
  return api
    .get(SettingsEndpointsEnum.DRIVERS_EXPORT, {
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

const getUsers = (filter: UserFilterDef) => {
  return api.get(SettingsEndpointsEnum.USERS, {
    params: filter,
  });
};

const getUser = (id: UserDef["id"]) => {
  return api.get(`${SettingsEndpointsEnum.USERS}/${id}`);
};

const addNewUser = (fields: UserRequestDef) => {
  return api.post(SettingsEndpointsEnum.USERS, fields);
};

const updateUser = (id: UserDef["id"], fields: UserRequestDef) => {
  return api.put(`${SettingsEndpointsEnum.USERS}/${id}`, fields);
};

const deleteUser = (id: UserDef["id"]) => {
  return api.delete(`${SettingsEndpointsEnum.USERS}/${id}`);
};

const deactivateUser = (id: UserDef["id"]) => {
  const url = generatePath(SettingsEndpointsEnum.USERS_ACTIVATION, {
    id: id.toString(),
  });
  return api.delete(url);
};

const activateUser = (id: number, payload: any) => {
  const url = generatePath(SettingsEndpointsEnum.USERS_ACTIVATION, {
    id: id.toString(),
  });
  return api.put(url, payload);
};

const getUsersExportData = () => {
  return api
    .get(SettingsEndpointsEnum.USERS_EXPORT, {
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

export const settingsApi = {
  getVehicles,
  getVehicleByNo,
  getDepartments,
  getPlanSubDepartments,
  getVehicleResponsibles,
  addNewVehicle,
  updateVehicle,
  deleteVehicle,
  getVehicleGroups,
  getVehicleGroupById,
  addNewVehicleGroup,
  updateVehicleGroup,
  deleteVehicleGroup,
  getChassisList,
  getChassisByNo,
  addNewChassis,
  updateChassis,
  deleteChassis,
  getDrivers,
  getDriverByNo,
  addNewDriver,
  updateDriver,
  deleteDriver,
  getDriverExportData,
  getUsers,
  getUser,
  addNewUser,
  updateUser,
  deleteUser,
  activateUser,
  deactivateUser,
  getUsersExportData,
};
