/* eslint-disable camelcase */
import { memo, useEffect, useState } from "react";

import { Form, Input, Col, Select, message as AlertMessage } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FormModal, { Item } from "@app/components/atoms/FormModal/FormModal";
import { ItemModalEnum } from "@app/constants/route.constants";
import useShowModal from "@app/hooks/useShowModal";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ModalCloseActionDef } from "@app/types/modal.types";

import { getDriverByNo } from "../../../../redux/drivers.slice";
import {
  addNewUser,
  getUserById,
  updateUser,
} from "../../../../redux/users.slice";
import { DriverDef } from "../../../../types/driver.types";
import { UserRequestDef } from "../../../../types/user.types";

interface DriverModalProps {
  onClose: (action: ModalCloseActionDef) => void;
}

interface FormValuesDef {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  password: string;
  company: string;
  department: {
    values: {
      value: string;
      label: string;
    };
  };
}

const DriverModal = memo(({ onClose }: DriverModalProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [drivers, setDrivers] = useState<DriverDef | null>(null);
  const { showModal, action, actionId } = useShowModal();
  const [form] = Form.useForm();

  const departments = useSelector(
    (state: RootState) => state.vehicles.departments
  );

  const editMode = action === ItemModalEnum.EDIT;

  const setFormValue = (driver: DriverDef) => {
    form.setFieldsValue({
      first_name: driver.first_name,
      last_name: driver.last_name,
      phone: driver.phone,
      email: driver.email,
      company: driver.company,
      username: driver.username,
      ...((driver.department?.code && {
        department: {
          code: driver.department.code,
          name: driver.department.name,
        },
      }) || { department: null }),
    });
  };

  useEffect(() => {
    if (editMode && actionId) {
      const getVehicle = async () => {
        setLoading(true);
        const response = await dispatch(getUserById(parseInt(actionId, 10)));
        if (getUserById.fulfilled.match(response)) {
          setFormValue(response.payload);
          setDrivers(response.payload);
          setLoading(false);
        }
        if (getDriverByNo.rejected.match(response)) {
          setLoading(false);
          AlertMessage.error(t("default.errorMessage"));
        }
      };
      getVehicle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, editMode]);

  const showAlertMessage = (isSuccess: boolean, message: string) => {
    setLoading(false);
    if (isSuccess) {
      AlertMessage.success(message);
      form.resetFields();
      onClose({ triggerRefetch: true });
    }
    if (!isSuccess) {
      AlertMessage.error(message);
    }
  };

  const addVehicle = async (fields: UserRequestDef) => {
    const response = await dispatch(addNewUser(fields));
    if (addNewUser.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsDrivers.addSuccessMessage"));
    }
    if (addNewUser.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const editVehicle = async (id: DriverDef["id"], fields: UserRequestDef) => {
    const response = await dispatch(updateUser({ id, fields }));
    if (updateUser.fulfilled.match(response)) {
      showAlertMessage(true, t("settingsDrivers.editSuccessMessage"));
    }
    if (updateUser.rejected.match(response)) {
      showAlertMessage(false, response.payload as string);
    }
  };

  const handleSubmit = (values: FormValuesDef) => {
    setLoading(true);
    const fields: UserRequestDef = {
      ...values,
      username: values.username.toString(),
      role: "Driver",
      ...((values.department.values && {
        department: {
          code: values.department.values.value,
          name: values.department.values.label,
        },
      }) || { department: null }),
      is_active: drivers?.is_active,
    };

    if (fields.password === undefined || fields.password === "") {
      delete fields.password;
    }

    if (editMode && actionId) editVehicle(parseInt(actionId, 10), fields);
    else addVehicle(fields);
  };

  const handleClose = () => {
    form.resetFields();
    onClose({ triggerRefetch: false });
  };

  const validateMessages = {
    required: t("default.requiredMessage"),
    string: {
      max: t("default.maxLengthMessage", {
        max: 50,
      }),
    },
    types: {
      email: t("default.invalidEmailMessage", {
        label: t("settingsDrivers.labelEmail"),
      }),
    },
    pattern: {
      mismatch: t("default.invalidNumberMessage", {
        label: t("settingsDrivers.labelPhoneNumber"),
      }),
    },
  };

  return (
    <FormModal
      form={form}
      validateMessages={validateMessages}
      title={
        editMode
          ? t("settingsDrivers.editDriverTitle")
          : t("settingsDrivers.addNewDriverTitle")
      }
      visible={showModal}
      submitButtonText={
        editMode ? t("default.editTitle") : t("default.addTitle")
      }
      destroyOnClose
      loadingSubmit={loading}
      loadingContent={loading}
      onClose={handleClose}
      onFinish={handleSubmit}
    >
      <Col span={24}>
        <Item label={t("settingsDrivers.labelDriverNo")} name="username">
          <Input
            disabled={editMode}
            placeholder={t("settingsDrivers.driverNoPlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelFirstName")}
          name="first_name"
          rules={[
            {
              required: true,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsDrivers.firstNamePlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelLastName")}
          name="last_name"
          rules={[
            {
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsDrivers.lastNamePlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelPhoneNumber")}
          name="phone"
          rules={[
            {
              required: true,
              whitespace: true,
              pattern: /^(\+|00)[1-9][0-9]{7,15}$/,
            },
          ]}
        >
          <Input placeholder={t("settingsDrivers.phoneNumberPlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelPassword")}
          name="password"
          rules={[
            {
              required: !editMode,
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input.Password
            placeholder={t("settingsDrivers.passwordPlaceholder")}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelEmail")}
          name="email"
          rules={[
            {
              type: "email",
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsDrivers.emailPlaceholder")} />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsDrivers.labelDepartment")}
          name={["department", "values"]}
        >
          <Select
            labelInValue
            placeholder={t("settingsDrivers.departmentPlaceholder")}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {departments?.map(department => (
              <Select.Option key={department.code} value={department.code}>
                {department.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={t("settingsUsers.labelCompany")}
          name="company"
          rules={[
            {
              whitespace: true,
              max: 50,
            },
          ]}
        >
          <Input placeholder={t("settingsDrivers.haulierPlaceholder")} />
        </Item>
      </Col>
    </FormModal>
  );
});

export default DriverModal;
