import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _merge from "lodash/merge";

import { PositionMapSettingsOptionsDef } from "../types/position-map-settings.types";

type PositionMapSettingsState = {
  positionMapSettings: PositionMapSettingsOptionsDef;
};

const initialState: PositionMapSettingsState = {
  positionMapSettings: {
    mapOptions: {
      zoomControl: false,
      fullscreenControl: true,
    },
    advancedSettings: {
      pointsOnMap: true,
    },
  },
};

const positionMapSettingsOptionsSlice = createSlice({
  name: "positionMapSettingsOptions",
  initialState,
  reducers: {
    setMapSettings: (
      state,
      action: PayloadAction<Partial<PositionMapSettingsOptionsDef>>
    ) => {
      state.positionMapSettings = _merge(
        state.positionMapSettings,
        action.payload
      );
    },
    resetMapSettings: state => {
      state.positionMapSettings = initialState.positionMapSettings;
    },
  },
});

export const {
  setMapSettings,
  resetMapSettings,
} = positionMapSettingsOptionsSlice.actions;

export default positionMapSettingsOptionsSlice.reducer;
