/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo, useEffect, useState } from "react";

import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { DateTimeFormats } from "@app/constants/date.constants";
import { GoogleMapDefaults } from "@app/constants/map.constants";
import { formattedDateTime } from "@app/helpers/util.helpers";
import { RootState } from "@app/redux/root-reducer";

import {
  setActiveVehicleDetailId,
  setMapProjection,
} from "../../../../redux/vehicles-positions.slice";
import {
  MapProjectionDef,
  VehiclePositionDef,
} from "../../../../types/vehicle-position.types";
import VehicleCard from "../VehicleCard/VehicleCard";
import VehicleStatus from "../VehicleStatus/VehicleStatus";
import styles from "./Marker.module.scss";

interface VehiclePositionProps {
  vehicle: VehiclePositionDef;
}

const Marker = memo(({ vehicle }: VehiclePositionProps) => {
  const dispatch = useDispatch();
  const [displayDetails, setDisplayDetails] = useState<boolean>(false);
  const { mapSettings } = useSelector(
    (state: RootState) => state.mapSettingsOptions
  );
  const { activeVehicleDetailId } = useSelector(
    (state: RootState) => state.vehiclesPositions
  );

  /**
   * Effects on active vehicle details
   * Hide inactive vehicle card
   */
  useEffect(() => {
    if (activeVehicleDetailId !== vehicle.no) {
      setDisplayDetails(false);
    }
  }, [activeVehicleDetailId, vehicle.no]);

  /**
   * Handle marker click
   */
  const handleClickMarker = () => {
    setDisplayDetails(prevDisplayDetails => !prevDisplayDetails);

    const projection: MapProjectionDef = {
      zoom: GoogleMapDefaults.PROJECTION_ZOOM_IN,
      center: {
        lat: vehicle.geo_decimal.lat,
        lng: vehicle.geo_decimal.lng,
      },
    };

    dispatch(setActiveVehicleDetailId(vehicle.no));
    dispatch(setMapProjection(projection));
  };

  return (
    <>
      <div
        className={cx(styles.marker, {
          [styles.markerSelected]: displayDetails,
        })}
        onClick={handleClickMarker}
      >
        <div
          style={{ transform: `rotate(${vehicle.direction}deg)` }}
          className={styles.markerContainer}
        >
          <div className={styles.triangleUp} />
          <div className={styles.markerGreenCircle} />
        </div>
        {mapSettings.mapDisplaySettings.displayLabelSettings
          ?.displayVehicleNumber && (
          <div className={styles.vehicleNo}>
            <legend>{vehicle.no}</legend>
          </div>
        )}
        {mapSettings.mapDisplaySettings.displayLabelSettings
          ?.displayApplicationStatus && (
          <VehicleStatus
            className={styles.statusContainer}
            status={vehicle.status}
          />
        )}
        {mapSettings.mapDisplaySettings.displayLabelSettings
          ?.displayDriverName && (
          <div className={styles.driverName}>
            <legend>{vehicle.driver?.name || "-"}</legend>
          </div>
        )}
        {mapSettings.mapDisplaySettings.displayLabelSettings
          ?.displayDateTimeOfLastPosition && (
          <div className={styles.dateTime}>
            <legend>
              {formattedDateTime(
                vehicle.created,
                DateTimeFormats.TIME_DAY_MONTH_YEAR
              )}
            </legend>
          </div>
        )}
      </div>

      {displayDetails && <VehicleCard vehicle={vehicle} />}
    </>
  );
});

export default Marker;
