import { memo } from "react";

import _camelCase from "lodash/camelCase";
import { useTranslation } from "react-i18next";

import { VehicleStopTypeEnum } from "../../../../constants/vehicle-stop-type.constants";

interface VehicleStopTypeProps {
  type?: VehicleStopTypeEnum;
}

const VehicleStopType = memo(({ type }: VehicleStopTypeProps) => {
  const { t } = useTranslation();
  return type ? <>{t(`vehicleStopTypeStatus.${_camelCase(type)}`)}</> : null;
});

export default VehicleStopType;
